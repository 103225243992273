import { useEffect } from "react";
import { useFileStore } from "../../../../store/useFileStore";

const SearchBarScss = () => {
    const [_, setStore] = useFileStore((store) => store.searchBarScss);

    useEffect(() => {
        let value = `@import "./variable.scss";
.search_wrapper {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #263238;
    z-index: 999;
    .search_container {
        width: 75%;
        border-bottom: 1px solid #757575 !important;
        input {
            background-color: #263238 !important;
            border-radius: 0;
            box-shadow: none;
            color: $white !important;
            font-size: 16px;
            height: inherit;
            margin-right: 0 !important;
            padding: 8px 36px 8px 10px;
            width: 80%;
            outline: none;
        }
        button {
            background-color: #263238;
            i {
                color: #fff;
                font-size: 16px;
            }
        }
    }
    &[dark-mode="true"] {
        background-color: var(--bg-content);
        border-bottom: 1px solid var(--border-color);
        input,
        button {
            background-color: var(--bg-content) !important;
        }
    }
}
`;
        setStore({ searchBarScss: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return null;
};

export default SearchBarScss;
