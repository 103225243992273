import { useEffect } from "react";
import { useFileStore } from "../../store/useFileStore";

const IndexScss = () => {
    const [_, setStore] = useFileStore((store) => store.indexScss);
    useEffect(() => {
        let value = `@import "./assets/scss/variable.scss";

body {
    font-size: $body_font_size;
    font-family: $body_font_family;
    background-color: var(--body-bg);

    ul {
        margin: 0px;
        padding: 0px;
        li {
            list-style: none;
        }
    }
    a {
        text-decoration: none;
    }
    p {
        margin: 0px;
    }
    button {
        border: 0;
        outline: none;
        background-color: transparent;
    }
    label {
        color: var(--hedging-text-color) !important;
    }
    small {
        color: var(--content-text-color) !important;
    }
    input,
    textarea,
    select {
        color: var(--hedging-text-color) !important;
        border-color: var(--border-color) !important;
        background-color: var(--bg-content) !important;
        &::placeholder {
            color: var(--content-text-color) !important;
        }
    }

    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-track {
        // box-shadow: inset 0 0 3px rgb(177, 177, 177);
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #a8a9ac;
        border-radius: 20px;
    }
}
`;
        setStore({ indexScss: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default IndexScss;
