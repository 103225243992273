import { useDynamicDashboardData } from "../../../context/dashboardDataContext";
import Accordion from "../../../../../../../components/Accordion/Accordion";
import TextControl from "../../../../../../../components/TextControl/TextControl";

const General = () => {
    const { projectInfo, setProjectInfo } = useDynamicDashboardData();

    return (
        <Accordion title="General">
            <div className={`p-5`}>
                <div className="grid grid-cols-2 gap-8">
                    <TextControl
                        label="Project Name"
                        id="project_name"
                        placeholder="Project Name"
                        value={projectInfo?.projectName}
                        onChange={(e) =>
                            setProjectInfo({
                                ...projectInfo,
                                projectName: e.target.value,
                            })
                        }
                    />
                    <TextControl
                        label="Version"
                        id="version"
                        placeholder="Version"
                        value={projectInfo?.version}
                        onChange={(e) =>
                            setProjectInfo({
                                ...projectInfo,
                                version: e.target.value,
                            })
                        }
                    />
                </div>

                <div className="mt-3">
                    <TextControl
                        label="Description"
                        id="desc"
                        placeholder="Description..."
                        value={projectInfo?.description}
                        onChange={(e) =>
                            setProjectInfo({
                                ...projectInfo,
                                description: e.target.value,
                            })
                        }
                    />
                </div>

                <div className="grid grid-cols-2 gap-8 mt-3">
                    <TextControl
                        label="Author"
                        id="author"
                        placeholder="Author"
                        value={projectInfo?.author}
                        onChange={(e) =>
                            setProjectInfo({
                                ...projectInfo,
                                author: e.target.value,
                            })
                        }
                    />
                    <TextControl
                        label="Keywords"
                        id="keywords"
                        placeholder="Keywords with comma (,) seperated"
                        value={projectInfo?.keywords}
                        onChange={(e) =>
                            setProjectInfo({
                                ...projectInfo,
                                keywords: e.target.value,
                            })
                        }
                    />
                </div>
            </div>
        </Accordion>
    );
};

export default General;
