import { useEffect } from "react";
import { useFileStore } from "../../../../../store/useFileStore";

const DocProgressBarSCSS = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store);

    useEffect(() => {
        let value = `.progressbar_container {
    .progress_bar {
        animation: progress_bar 1.5s ease-in-out forwards;
    }

    @keyframes progress_bar {
        from {
            width: 0%;
        }
        to {
            width: 100% !important;
        }
    }
}
`;

        setStore({ docProgressBarSCSS: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default DocProgressBarSCSS;
