import { useEffect } from "react";
import { useFileStore } from "../../../store/useFileStore";
import { useDynamicDashboardData } from "../../../context/dashboardDataContext";
import { getSlug } from "../../../../../../../utils/getSlug";

const DocInstallationMDX = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store);

    const { projectInfo } = useDynamicDashboardData();

    useEffect(() => {
        let value = `---
sidebar_position: 2
sidebar_label: Installation
---

# Installation

To use ${
            projectInfo?.projectName
        }, you need to have \`React 18+\`, \`Node.js >=18.0\`, npm, yarn and pnpm installed on your machine. Follow these steps to set up the template:

import Tabs from "@theme/Tabs";
import TabItem from "@theme/TabItem";

##### Clone or download the repository from GitHub

\`\`\`
https://github.com/reactadmins/bootstrap-${getSlug(
            projectInfo?.projectName
        )}.git
\`\`\`

<br/>
##### Install Dependencies

<Tabs>
    <TabItem value="npm" label="npm">
        \`\`\`
        npm install
        \`\`\`
    </TabItem>
    <TabItem value="yarn" label="yarn">
        \`\`\` 
        yarn install 
        \`\`\`
    </TabItem>
    <TabItem value="pnpm" label="pnpm">
        \`\`\` 
        pnpm install 
        \`\`\`
    </TabItem>

</Tabs>

<br/>
##### Start Development Server

<Tabs>
    <TabItem value="npm" label="npm">
        \`\`\`
        npm run dev 
        \`\`\`
    </TabItem>
    <TabItem value="yarn" label="yarn">
        \`\`\`
        yarn run dev
        \`\`\`
    </TabItem>
    <TabItem value="pnpm" label="pnpm">
        \`\`\`
        pnpm run dev
        \`\`\`
    </TabItem>
</Tabs>

<br/>
##### Build Project

<Tabs>
    <TabItem value="npm" label="npm">
        \`\`\`
        npm run build
        \`\`\`
    </TabItem>
    <TabItem value="yarn" label="yarn">
        \`\`\`
        yarn run build
        \`\`\`
    </TabItem>
    <TabItem value="pnpm" label="pnpm">
        \`\`\`
        pnpm run build
        \`\`\`
    </TabItem>
</Tabs>

<br/>
##### Preview Project

<Tabs>
    <TabItem value="npm" label="npm">
        \`\`\`
        npm run preview 
        \`\`\`
    </TabItem>
    <TabItem value="yarn" label="yarn">
        \`\`\`
        yarn run preview
        \`\`\`
    </TabItem>
    <TabItem value="pnpm" label="pnpm">
        \`\`\`
        pnpm run preview
        \`\`\`
    </TabItem>
</Tabs>

Open your browser and go to http://localhost:5173/
You should see the ${
            projectInfo?.projectName
        } Template on your screen. You can explore the different pages and components, and customize them according to your needs. To build the production version of the template, run \`npm run build\`, \`yarn run build\` or \`pnpm run build\`. This will create a \`build\` folder with the optimized files. To serve the production version, run \`npm run preview\`, \`yarn run preview\` or \`pnpm run preview\` and go to http://localhost:4173/ again.

<br />
##### File Structure

Within the download you'll find the following directories and files:

\`\`\`
.
├── public
├── src
│   ├── assets
│   │   ├── fonts
│   │   │   └── Nunito-Bold.otf
│   │   ├── image
│   │   ├── scss
│   │   │   ├── navbars
│   │   │   │   ├── Combo.module.scss
│   │   │   │   ├── DualTopNav.module.scss
│   │   │   │   ├── HorizontalNav.module.scss
│   │   │   │   └── VerticalNav.module.scss
│   │   │   ├── AnalyticsStats.module.scss
│   │   │   ├── Authentication.module.scss
│   │   │   ├── Card.module.scss
│   │   │   ├── CardContent.module.scss
│   │   │   ├── DarkModeSwitch.module.scss
│   │   │   ├── Grids.module.scsss
│   │   │   ├── IconStyle.module.scsss
│   │   │   ├── Layouts.module.scsss
│   │   │   ├── Message.module.scsss
│   │   │   ├── MonthlyStats.module.scsss
│   │   │   ├── NavBarBgWrapper.module.scsss
│   │   │   ├── Notification.module.scsss
│   │   │   ├── NotificationStatsCart.module.scsss
│   │   │   ├── OverlayCard.module.scsss
│   │   │   ├── ProfileCard.module.scsss
│   │   │   ├── SearchBar.module.scsss
│   │   │   ├── Sidebar.module.scsss
│   │   │   ├── SidebarBgWrapper.module.scsss
│   │   │   ├── SocialButton.module.scsss
│   │   │   ├── SocialCounter.module.scss.scsss
│   │   │   ├── SocialStats.module.scsss
│   │   │   ├── Stats.module.scsss
│   │   │   ├── StatsCard.module.scsss${
            projectInfo.dashboardType === "free"
                ? ""
                : `│   │   │   ├── StepCircleWizard.module.scsss
│   │   │   ├── StepSquarWizard.module.scsss
│   │   │   ├── StepTabWizard.module.scsss`
        }
│   │   │   ├── Tables.module.scsss${
            projectInfo.dashboardType === "free"
                ? ""
                : "\n│   │   │   ├── Tabs.module.scsss"
        }
│   │   │   ├── Timeline.module.scsss
│   │   │   ├── Traffic.module.scsss
│   │   │   ├── Typography.module.scsss
│   │   │   ├── UesrProfile.module.scsss
│   │   │   ├── variable.scsss
│   │   │   ├── WeatherStats.module.scsss
│   │   │   └── WidgetsCard.module.scsss
│   ├── components
│   │   ├── AnalyticsStats
│   │   │   └── AnalyticsStats.jsx${
            projectInfo.dashboardType === "free"
                ? ""
                : `\n│   │   ├── ApexCharts
│   │   │   ├── AreaChart.jsx
│   │   │   ├── BarChart.jsx
│   │   │   ├── BubbleChart.jsx
│   │   │   ├── CandlestickChart.jsx
│   │   │   ├── ColumnChart.jsx
│   │   │   └── PieChart.jsx`
        }
│   │   ├── Card
│   │   │   └── Card.jsx
│   │   ├── Chartjs
│   │   │   ├── BarChart.jsx
│   │   │   ├── DoughnutChart.jsx
│   │   │   ├── LineChart.jsx
│   │   │   ├── PieChart.jsx
│   │   │   ├── PolarAreaChart.jsx
│   │   │   └── RadarChart.jsx
│   │   ├── DarkModeSwitch
│   │   │   └── DarkModeSwitch.jsx
│   │   ├── DeviceVisitorStats
│   │   │   └── DeviceVisitorStats.jsx
│   │   ├── Download
│   │   │   └── Download.jsx
│   │   ├── DropdownMenu
│   │   │   └── DropdownMenu.jsx
│   │   ├── Earning
│   │   │   └── Earning.jsx
│   │   ├── Icon
│   │   │   ├── Brandico.jsx
│   │   │   ├── CopyButton.jsx
│   │   │   ├── Entypo.jsx
│   │   │   ├── FontAwesome.jsx
│   │   │   ├── Fontelico.jsx
│   │   │   └── Iconviewer.jsx
│   │   ├── LeafletMaps
│   │   │   ├── DarkMatter.jsx
│   │   │   ├── StreetMap.jsx
│   │   │   ├── Voyager.jsx
│   │   │   └── WorldImagery.jsx
│   │   ├── MonthlyStats
│   │   │   └── MonthlyStats.jsx
│   │   ├── Navbars
│   │   │   ├── Message
│   │   │   │   └── Message.jsx
│   │   │   ├── NavbarType
│   │   │   │   ├── Combo.jsx
│   │   │   │   ├── DualTopNav.jsx
│   │   │   │   ├── HorizontalNav.jsx
│   │   │   │   └── VerticalNav.jsx
│   │   │   ├── Notification
│   │   │   │   └── Notification.jsx
│   │   │   ├── UserProfile
│   │   │   │   └── UserProfile.jsx
│   │   │   ├── NavBarBgWrapper.jsx
│   │   │   └── Navbars.jsx
│   │   ├── NotificationStatsCart
│   │   │   └── NotificationStatsCart.jsx
│   │   ├── OverlayCard
│   │   │   └── OverlayCard.jsx
│   │   ├── ProfileCard
│   │   │   └── ProfileCard.jsx
│   │   ├── RealTime
│   │   │   └── RealTime.jsx
│   │   ├── Recharts
│   │   │   ├── AreaRechart.jsx
│   │   │   ├── BarRechart.jsx
│   │   │   ├── LineRechart.jsx
│   │   │   ├── PieRechart.jsx
│   │   │   ├── RechartRadialBar.jsx
│   │   │   └── RechartScatter.jsx
│   │   ├── Revenue
│   │   │   └── Revenue.jsx
│   │   ├── SearchBar
│   │   │   └── SearchBar.jsx
│   │   ├── Sidebar
│   │   │   ├── Sidebar.jsx
│   │   │   ├── SidebarBgWrapper.jsx
│   │   │   └── SidebarMenu.jsx
│   │   ├── SocialButton
│   │   │   └── SocialButton.jsx
│   │   ├── SocialCounter
│   │   │   └── SocialCounter.jsx
│   │   ├── SocialStats
│   │   │   └── SocialStats.jsx
│   │   ├── Stats
│   │   │   ├── Day.jsx
│   │   │   ├── Month.jsx
│   │   │   ├── Stats.jsx
│   │   │   └── Year.jsx
│   │   ├── StatsCard
│   │   │   └── StatsCard.jsx${
            projectInfo.dashboardType === "free"
                ? ""
                : `\n│   │   ├── StepWizards
│   │   │   ├── Page
│   │   │   │   ├── AdditionalInfo.jsx
│   │   │   │   ├── LoggedIn.jsx
│   │   │   │   └── PersonalDetails.jsx
│   │   │   ├── StepCircleWizard.jsx
│   │   │   ├── StepSquarWizard.jsx
│   │   │   └── StepTabWizard.jsx`
        }
│   │   ├── Tab
│   │   │   └── Tab.jsx
│   │   ├── Timeline
│   │   │   └── Timeline.jsx
│   │   ├── Traffic
│   │   │   └── Traffic.jsx
│   │   ├── UserStats
│   │   │   └── UserStats.jsx
│   │   ├── WeatherStats
│   │   │   └── WeatherStats.jsx
│   ├── context
│   ├── layouts
│   │   └── Layouts.jsx
│   ├── views
│   │   ├── AdvancedForm.jsx
│   │   ├── Alerts.jsx
│   │   ├── ApexCharts.jsx
│   │   ├── Badges.jsx
│   │   ├── BasicForm.jsx
│   │   ├── Buttons.jsx
│   │   ├── Cards.jsx
│   │   ├── Chartjs.jsx
│   │   ├── Dashboard.jsx
│   │   ├── Error404.jsx
│   │   ├── Error500.jsx
│   │   ├── GoogleMaps.jsx
│   │   ├── Grids.jsx
│   │   ├── Icons.jsx
│   │   ├── LeafletMaps.jsx
│   │   ├── Login.jsx
│   │   ├── Modals.jsx
│   │   ├── progressBars.jsx
│   │   ├── Recharts.jsx
│   │   ├── Register.jsx
│   │   ├── SocialButtons.jsx
│   │   ├── Tables.jsx
│   │   ├── Tabs.jsx
│   │   ├── Typography.jsx
│   │   ├── Widgets.jsx
│   │   └── WizardForm.jsx
│   ├── App.jsx
│   ├── index.scss
│   ├── main.jsx
│   ├── nav.jsx
│   └── routes.jsx
├── vite.config.js
├── README.md
├── package.json
├── index.html
└── .gitignore

\`\`\`
`;

        setStore({ docInstallationMDX: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectInfo?.projectName]);

    return null;
};

export default DocInstallationMDX;
