import { useState } from "react";

function Accordion({ title = "General", children }) {
    const [isOpen, setIsOpen] = useState(true);

    return (
        <div className="border-[1px] border-[#D8D8D8] rounded-[2px]">
            <div
                className={`py-3 px-5 bg-[#F7F7F7] border-[#D8D8D8]`}
                onClick={() => setIsOpen(!isOpen)}>
                {title}
            </div>
            {isOpen ? children : null}
        </div>
    );
}

export default Accordion;
