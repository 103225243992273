import React from "react";
import BootstrapAdminTempatesBase from "../../project/boostrap/admin-templates/base/Base";

function Show({ showComp }) {
    switch (showComp) {
        case "bootstrap_admin_templates_base":
            return <BootstrapAdminTempatesBase />;
        default:
            return (
                <div className="text-center mt-8 text-3xl font-semibold">
                    Sorry, This Generation is Not avilable!
                </div>
            );
    }
}

export default Show;
