import React, { Fragment, useEffect, useRef } from "react";
import { useModalContext } from "./context/dashboardModalContext";
import FormInputs from "./components/FormInputs/FormInputs";
import ProjectFiles from "./codebase";
import Modal from "../../../../components/Modal/Modal";
import GeneratedFiles from "./GeneratedFiles/GeneratedFiles";
import "./Layout.scss";
import { useDynamicDashboardData } from "./context/dashboardDataContext";

const Layout = () => {
    const {
        isOpenModal,
        setIsOpenModal,
        fileInfo,
        fileName,
        setFileInfo,
        setFileName,
    } = useModalContext();

    const { projectInfo, setProjectInfo } = useDynamicDashboardData();

    let dropRef = useRef();

    useEffect(() => {
        document.addEventListener("mousedown", (e) => {
            if (dropRef.current && !dropRef.current.contains(e.target)) {
                setIsOpenModal(false);
            }
        });
    }, [setIsOpenModal]);

    return (
        <Fragment>
            <ProjectFiles />

            <div
                className={`relative ${
                    isOpenModal ? "h-screen overflow-hidden" : "h-full"
                }`}
            >
                <div className="mt-12">
                    <div className="mb-12">
                        <h2 className="text-black-500 text-xl font-bold text-center">
                            Bootstrap Base
                        </h2>
                    </div>
                    {/* Free Pro Button */}
                    <div className="group-btn">
                        <div className={`btn`}>
                            <input
                                type="radio"
                                name="dashboard_feature"
                                checked={
                                    projectInfo?.dashboardType === "free"
                                        ? true
                                        : false
                                }
                                value="free"
                                id="free"
                                onChange={(e) =>
                                    setProjectInfo({
                                        ...projectInfo,
                                        dashboardType: e.target.value,
                                    })
                                }
                            />
                            <label
                                className="leading-[48px] cursor-pointer"
                                htmlFor="free"
                            >
                                Free
                            </label>
                        </div>
                        <div className={`btn`}>
                            <input
                                type="radio"
                                name="dashboard_feature"
                                checked={
                                    projectInfo?.dashboardType === "pro"
                                        ? true
                                        : false
                                }
                                value="pro"
                                id="pro"
                                onChange={(e) =>
                                    setProjectInfo({
                                        ...projectInfo,
                                        dashboardType: e.target.value,
                                    })
                                }
                            />
                            <label
                                className="leading-[48px] cursor-pointer"
                                htmlFor="pro"
                            >
                                Pro
                            </label>
                        </div>
                    </div>
                    {/* <ChooseProduct /> */}
                    <FormInputs />
                </div>
            </div>

            {isOpenModal ? (
                <div className="fixed z-[999999] top-0 left-0 right-0 bottom-0 w-full h-full bg-[#00000080]">
                    <div
                        ref={dropRef}
                        className="relative m-auto bg-[#fff] w-[1348px] h-[680px] z-[9999] rounded-[.3rem] border-[1px] border-[#666666] ease-in-out duration-[10s] top-[36px]"
                    >
                        <Modal
                            fileInfo={fileInfo}
                            fileName={fileName}
                            setIsOpenModal={setIsOpenModal}
                            GeneratedFiles={
                                <GeneratedFiles
                                    modalStates={{
                                        setIsOpenModal,
                                        setFileInfo,
                                        setFileName,
                                    }}
                                />
                            }
                        />
                    </div>
                </div>
            ) : null}
        </Fragment>
    );
};

export default Layout;
