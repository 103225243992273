import { useEffect } from "react";
import { useFileStore } from "../../../../store/useFileStore";

const DocTypographyMDX = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store);

    useEffect(() => {
        let value = `---
sidebar_position: 9
sidebar_label: Typography
---

# Typography

The Typography component is a wrapper for displaying text with specific typographic styles and properties. The Typography component can have different levels, such as headings, titles, Pargraph or body, and different sizes, colors, or fonts. The Typography component can also have different variants, such as bold, italic, or underline.

You can find the Typography component in the \`src/views/Typography.jsx\` file.

import CodePreview from "@site/src/components/CodePreview/CodePreview";
import Tabs from '@theme/Tabs';
import TabItem from '@theme/TabItem';

## Examples
### Heading
Bootstrap provides different heading typography options for consistent text formatting in web application. Typography [Preview](https://demo.reactadmin.com/bootstrap/basix-admin/components/typography)

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <h1 className="fw-bolder">
                Very Important Information H1
            </h1>
            <h2 className="fw-bolder">
                Sections & Modal Names H2
            </h2>
            <h3 className="fw-bolder">
                Articles & Block Headings H3
            </h3>
            <h4 className="fw-bolder">
                Random Tiny Heading H4
            </h4>
            <h5 className="fw-bolder">
                Random Tiny Heading H5
            </h5>
            <h6 className="fw-bolder">
                Random Tiny Heading H6
            </h6>
        </CodePreview>
    </TabItem>
    
    <TabItem value="code" label="Code">

        \`\`\`jsx title="Typography"
        import React from "react";

        const MyComponent = () => {
            return (
                <>
                    <h1 className="fw-bolder">
                        Very Important Information H1
                    </h1>
                    <h2 className="fw-bolder">
                        Sections & Modal Names H2
                    </h2>
                    <h3 className="fw-bolder">
                        Articles & Block Headings H3
                    </h3>
                    <h4 className="fw-bolder">
                        Random Tiny Heading H4
                    </h4>
                    <h5 className="fw-bolder">
                        Random Tiny Heading H5
                    </h5>
                    <h6 className="fw-bolder">
                        Random Tiny Heading H6
                    </h6>
                </>   
            )
        }

        export default MyComponent;
        \`\`\`
    </TabItem>
</Tabs>

<br/>
### Pragraph
Bootstrap simplifies paragraph styling with predefined classes for consistent and aesthetically pleasing text formatting in web application. Typography [Preview](https://demo.reactadmin.com/bootstrap/basix-admin/components/typography)

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <p className="lh-2 text-start">
                The unique stripes of zebras make them one of
                the animals most familiar to people. They occur
                in a variety of habitats, such as grasslands,
                savannas,
                <mark className="bg-primary text-light mx-1">woodlands</mark>
                , thorny scrublands, mountains , and coastal
                hills. However, various anthropogenic factors
                have had a severe impact on zebra populations,
                in particular hunting for skins and habitat
                destruction. {"Grévy'"}s zebra and the mountain
                <mark className="mx-1">highlighted text</mark>
                zebra are endangered.
            </p>
        </CodePreview>
    </TabItem>
    
    <TabItem value="code" label="Code">

        \`\`\`jsx title="Typography"
        import React from "react";

        const MyComponent = () => {
            return (
                <p className="lh-2 text-start">
                    The unique stripes of zebras make them one of
                    the animals most familiar to people. They occur
                    in a variety of habitats, such as grasslands,
                    savannas,
                    <mark className="bg-primary text-light mx-1">woodlands</mark>
                    , thorny scrublands, mountains , and coastal
                    hills. However, various anthropogenic factors
                    have had a severe impact on zebra populations,
                    in particular hunting for skins and habitat
                    destruction. {"Grévy'"}s zebra and the mountain
                    <mark className="mx-1">highlighted text</mark>
                    zebra are endangered.
                </p>
            )
        }

        export default MyComponent;
        \`\`\`
    </TabItem>
</Tabs>
`;

        setStore({ docTypographyMDX: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default DocTypographyMDX;
