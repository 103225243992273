import { useEffect } from "react";
import { useFileStore } from "../../../../store/useFileStore";

const WeatherStatsScss = () => {
    const [_, setStore] = useFileStore((store) => store.weatherStatsScss);

    useEffect(() => {
        let value = `.weather_wrapper {
    .weather_stats {
        background-color: #ffa726;
        .weather_icon {
            font-size: 3.75em;
        }
        .media_body {
            h3 {
                font-size: 1.125em;
                font-weight: 400;
            }
            span {
                font-size: 12px;
                margin-top: 5px;
            }
        }
    }
    .media_content {
        border-bottom: 0 !important;
        background-color: var(--bg-content);
        h5 {
            color: var(--hedging-text-color);
        }
        span {
            font-size: 14px;
            color: var(--content-text-color);
            margin-top: 4px;
        }
        .weather_counte {
            padding-right: 15px;
            h2 {
                font-size: 2rem;
                color: var(--hedging-text-color);
            }
            span {
                font-size: 14px;
                color: var(--content-text-color);
            }
        }
    }

    .stats {
        width: 33%;
        .stats_title {
            font-size: 14px;
            color: var(--hedging-text-color);
        }
        .stats_counter {
            font-size: 13px;
            color: var(--content-text-color);
        }
    }
    hr {
        width: 2px;
        height: 32px;
        background-color: #868e96;
    }

    .card_footer {
        background-color: var(--bg-content);
        border-color: var(--border-color) !important;
    }
}

body[data-theme="dark"] {
    .weather_wrapper {
        .weather_stats {
            background-color: var(--bg-content);
        }
    }
}
`;
        setStore({ weatherStatsScss: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return null;
};

export default WeatherStatsScss;
