import { useCallback, useRef } from "react";

const useGlobalDataStore = () => {
    const defaultData = {
        libName: "bootstrap",
    };

    const store = useRef(defaultData);
    const get = useCallback(() => store.current, []);

    const subscribers = useRef(new Set());
    const set = useCallback((value) => {
        store.current = { ...store.current, ...value };
        return subscribers.current.forEach((callback) => callback());
    }, []);

    const subscribe = useCallback((callback) => {
        subscribers.current.add(callback);
        return () => subscribers.current.delete(callback);
    }, []);

    return { get, set, subscribe };
};

export default useGlobalDataStore;
