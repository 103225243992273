import React, { useState, Fragment, useEffect } from "react";
import { useDynamicDashboardData } from "../context/dashboardDataContext";
import ViewTree from "../../../../../components/ViewTree/ViewTree";
import { FileRenderer } from "../../../../../components/FileRenderer/FileRenderer";
import { useDashboardNavManu } from "../context/dashboardNavMenuProvider";
import { getDownload } from "./download";
import { useFileStore } from "../store/useFileStore";
import admin from "../images/admin.jpg";
import loglo from "../images/logo.png";
import mini_loglo from "../images/mini-logo.png";
import black_logo from "../images/black-logo.png";
import black_logo_mini from "../images/black-mini-logo.png";
import placeholder from "../images/placeholder.png";
import avatar from "../images/avatar/avatar.png";
import user1 from "../images/avatar/user-1.jpg";
import user2 from "../images/avatar/user-2.jpg";
import user3 from "../images/avatar/user-3.jpg";
import user4 from "../images/avatar/user-4.jpg";
import user5 from "../images/avatar/user-5.jpg";
import user6 from "../images/avatar/user-6.jpg";

// switcher img
import nav_type1 from "../images/nav-type/combo-nav.png";
import nav_type2 from "../images/nav-type/dual-nav.png";
import nav_type3 from "../images/nav-type/horizontal-nav.png";
import nav_type4 from "../images/nav-type/vertical-nav.png";
import sidebarBg1 from "../images/sidebar-bg/sidebar-bg-1.jpg";
import sidebarBg2 from "../images/sidebar-bg/sidebar-bg-2.jpg";
import sidebarBg3 from "../images/sidebar-bg/sidebar-bg-3.jpg";
import sidebarBg4 from "../images/sidebar-bg/sidebar-bg-4.jpg";
import sidebarBg5 from "../images/sidebar-bg/sidebar-bg-5.jpg";

// docs Images
import docFavicon from "../codebase/docs/static/img/favicon.ico";
import docBlackLogo from "../codebase/docs/static/img/black-logo.png";
import docWhiteLogo from "../codebase/docs/static/img/white-logo.png";
import docMarkerIcon from "../codebase/docs/static/img/marker-icon.png";
import docAdmin from "../codebase/docs/src/assets/images/admin.jpg";
import docPlaceholder from "../codebase/docs/src/assets/images/placeholder.png";
import { getSlug } from "../../../../../utils/getSlug";

const GeneratedFiles = ({ modalStates }) => {
    const [files] = useFileStore((store) => store);

    const { projectInfo } = useDynamicDashboardData();
    const { projectName, dashboardType } = projectInfo;

    const rootFolderName = getSlug(projectName);

    //file structure state
    const [fileStructures, setFileStructures] = useState([]);

    // Toggole States
    const [pluginNameToggole, setPluginNameToggole] = useState(false);

    //dynamicPluginData context

    const {
        // navMenu,
        isPublicOpen,
        src,
        components,
        alert,
        card,
        chart,
        apexCharts,
        chartjs,
        recharts,
        dashboard,
        stats,
        dataSwitch,
        dropdownMenu,
        form,
        stepWizards,
        page,
        icon,
        modalSwitch,
        navbarsSwitch,
        messageSwitch,
        notificationSwitch,
        userProfileSwitch,
        progressBarSwitch,
        sidebarSwitch,
        tabSwitch,
        tabPageSwitch,
        tableSwitch,
        userInfoSwitch,
        layoutsSwitch,
        viewsSwitch,
        assetsSwitch,
        imgSwitch,
        avatarSwitch,
        scssSwitch,
        promoBarSwitch,
        contextSwitch,
        colorControlSwitch,
        switchControl,
        searchBarSwitch,
    } = useDashboardNavManu();

    useEffect(() => {
        let docFiles = {
            type: "folder",
            folderName: "docs",
            path: "docs",
            isExpand: true,
            folders: [
                {
                    type: "folder",
                    folderName: "data",
                    path: "docs/data",
                    isExpand: true,
                    folders: [],
                    files: [
                        {
                            type: "file",
                            name: "data.js",
                            data: files.docData,
                            icon: "fa-brands fa-js",
                        },
                        {
                            type: "file",
                            name: "tableData.js",
                            data: files.docTableData,
                            icon: "fa-brands fa-js",
                        },
                    ],
                },
                {
                    type: "folder",
                    folderName: "docs",
                    path: "docs/docs",
                    isExpand: true,
                    folders: [
                        {
                            type: "folder",
                            folderName: "charts",
                            path: "docs/docs/charts",
                            isExpand: true,
                            folders: [],
                            files: [
                                {
                                    type: "file",
                                    name: "_category_.json",
                                    data: files.docChartsCategoryJson,
                                    icon: "fa-regular fa-file",
                                },
                                {
                                    type: "file",
                                    name: "apex-charts.mdx",
                                    data: files.docApexChartsMDX,
                                    icon: "fa-brands fa-markdown",
                                    isDownload:
                                        dashboardType === "pro" ? "yes" : "no",
                                },
                                {
                                    type: "file",
                                    name: "chartjs.mdx",
                                    data: files.docChartjsMDX,
                                    icon: "fa-brands fa-markdown",
                                },
                                {
                                    type: "file",
                                    name: "recharts.mdx",
                                    data: files.docRechartsMDX,
                                    icon: "fa-brands fa-markdown",
                                },
                            ],
                        },
                        {
                            type: "folder",
                            folderName: "components",
                            path: "docs/docs/components",
                            isExpand: true,
                            folders: [],
                            files: [
                                {
                                    type: "file",
                                    name: "_category_.json",
                                    data: files.docComponentsCategoryJson,
                                    icon: "fa-regular fa-file",
                                },
                                {
                                    type: "file",
                                    name: "alert.mdx",
                                    data: files.docAlertMDX,
                                    icon: "fa-brands fa-markdown",
                                },
                                {
                                    type: "file",
                                    name: "badge.mdx",
                                    data: files.docBadgeMDX,
                                    icon: "fa-brands fa-markdown",
                                },
                                {
                                    type: "file",
                                    name: "button.mdx",
                                    data: files.docButtonMDX,
                                    icon: "fa-brands fa-markdown",
                                },
                                {
                                    type: "file",
                                    name: "card.mdx",
                                    data: files.docCardMDX,
                                    icon: "fa-brands fa-markdown",
                                },
                                {
                                    type: "file",
                                    name: "modal.mdx",
                                    data: files.docModalMDX,
                                    icon: "fa-brands fa-markdown",
                                },
                                {
                                    type: "file",
                                    name: "progress.mdx",
                                    data: files.docProgressMDX,
                                    icon: "fa-brands fa-markdown",
                                },
                                {
                                    type: "file",
                                    name: "socials.mdx",
                                    data: files.docSocialsMDX,
                                    icon: "fa-brands fa-markdown",
                                },
                                {
                                    type: "file",
                                    name: "table.mdx",
                                    data: files.docTableMDX,
                                    icon: "fa-brands fa-markdown",
                                },
                                {
                                    type: "file",
                                    name: "tabs.mdx",
                                    data: files.docTabsMDX,
                                    icon: "fa-brands fa-markdown",
                                    isDownload:
                                        dashboardType === "pro" ? "yes" : "no",
                                },
                                {
                                    type: "file",
                                    name: "typography.mdx",
                                    data: files.docTypographyMDX,
                                    icon: "fa-brands fa-markdown",
                                },
                                {
                                    type: "file",
                                    name: "widgets.mdx",
                                    data: files.docWidgetsMDX,
                                    icon: "fa-brands fa-markdown",
                                },
                            ],
                        },
                        {
                            type: "folder",
                            folderName: "forms",
                            path: "docs/docs/forms",
                            isExpand: true,
                            folders: [],
                            files: [
                                {
                                    type: "file",
                                    name: "_category_.json",
                                    data: files.docFormsCategoryJson,
                                    icon: "fa-regular fa-file",
                                },
                                {
                                    type: "file",
                                    name: "advanced-form.mdx",
                                    data: files.docAdvancedFormMDX,
                                    icon: "fa-brands fa-markdown",
                                },
                                {
                                    type: "file",
                                    name: "basic-form.mdx",
                                    data: files.docBasicFormMDX,
                                    icon: "fa-brands fa-markdown",
                                },
                                {
                                    type: "file",
                                    name: "form-wizard.mdx",
                                    data: files.docFormWizardMDX,
                                    icon: "fa-brands fa-markdown",
                                    isDownload:
                                        dashboardType === "pro" ? "yes" : "no",
                                },
                            ],
                        },
                        {
                            type: "folder",
                            folderName: "maps",
                            path: "docs/docs/maps",
                            isExpand: true,
                            folders: [],
                            files: [
                                {
                                    type: "file",
                                    name: "_category_.json",
                                    data: files.docMapsCategoryJson,
                                    icon: "fa-regular fa-file",
                                },
                                {
                                    type: "file",
                                    name: "google-map.mdx",
                                    data: files.docGoogleMapMDX,
                                    icon: "fa-brands fa-markdown",
                                },
                                {
                                    type: "file",
                                    name: "leaflet-maps.mdx",
                                    data: files.docLeafletMapsMDX,
                                    icon: "fa-brands fa-markdown",
                                },
                            ],
                        },
                    ],
                    files: [
                        {
                            type: "file",
                            name: "index.md",
                            data: files.docIndexMd,
                            icon: "fa-brands fa-markdown",
                        },
                        {
                            type: "file",
                            name: "installation.mdx",
                            data: files.docInstallationMDX,
                            icon: "fa-brands fa-markdown",
                        },
                        {
                            type: "file",
                            name: "configuration.mdx",
                            data: files.docConfigurationMDX,
                            icon: "fa-brands fa-markdown",
                        },
                    ],
                },
                {
                    type: "folder",
                    folderName: "src",
                    path: "docs/src",
                    isExpand: true,
                    folders: [
                        {
                            type: "folder",
                            folderName: "assets",
                            path: "docs/src/assets",
                            isExpand: true,
                            folders: [
                                {
                                    type: "folder",
                                    folderName: "images",
                                    path: "docs/src/assets/images",
                                    isExpand: true,
                                    folders: [],
                                    files: [
                                        {
                                            type: "file",
                                            img: true,
                                            name: "admin.jpg",
                                            data: docAdmin,
                                            icon: "fa-regular fa-image",
                                        },
                                        {
                                            type: "file",
                                            img: true,
                                            name: "placeholder.png",
                                            data: docPlaceholder,
                                            icon: "fa-regular fa-image",
                                        },
                                    ],
                                },
                            ],
                            files: [],
                        },
                        {
                            type: "folder",
                            folderName: "components",
                            path: "docs/src/components",
                            isExpand: true,
                            folders: [
                                {
                                    type: "folder",
                                    folderName: "Card",
                                    path: "docs/src/components/Card",
                                    isExpand: true,
                                    folders: [],
                                    files: [
                                        {
                                            type: "file",
                                            name: "Card.jsx",
                                            data: files.docCardJSX,
                                            icon: "fa-brands fa-react",
                                        },
                                        {
                                            type: "file",
                                            name: "Card.module.scss",
                                            data: files.docCardModuleSCSS,
                                            icon: "fa-brands fa-sass",
                                        },
                                    ],
                                },
                                {
                                    type: "folder",
                                    folderName: "Charts",
                                    path: "docs/src/components/Charts",
                                    isExpand: true,
                                    folders: [
                                        {
                                            type: "folder",
                                            folderName: "ApexPaiChart",
                                            path: "docs/src/components/Charts/ApexPaiChart",
                                            isDownload:
                                                dashboardType === "pro"
                                                    ? "yes"
                                                    : "no",
                                            isExpand: true,
                                            folders: [],
                                            files: [
                                                {
                                                    type: "file",
                                                    name: "ApexPaiChart.jsx",
                                                    data: files.docApexPaiChartJSX,
                                                    icon: "fa-brands fa-react",
                                                },
                                            ],
                                        },
                                        {
                                            type: "folder",
                                            folderName: "AreaChart",
                                            path: "docs/src/components/Charts/AreaChart",
                                            isDownload:
                                                dashboardType === "pro"
                                                    ? "yes"
                                                    : "no",
                                            isExpand: true,
                                            folders: [],
                                            files: [
                                                {
                                                    type: "file",
                                                    name: "AreaChart.jsx",
                                                    data: files.docAreaChartJSX,
                                                    icon: "fa-brands fa-react",
                                                },
                                            ],
                                        },
                                        {
                                            type: "folder",
                                            folderName: "AreaRechart",
                                            path: "docs/src/components/Charts/AreaRechart",
                                            isExpand: true,
                                            folders: [],
                                            files: [
                                                {
                                                    type: "file",
                                                    name: "AreaRechart.jsx",
                                                    data: files.docAreaRechartJSX,
                                                    icon: "fa-brands fa-react",
                                                },
                                            ],
                                        },
                                        {
                                            type: "folder",
                                            folderName: "BarChart",
                                            path: "docs/src/components/Charts/BarChart",
                                            isExpand: true,
                                            folders: [],
                                            files: [
                                                {
                                                    type: "file",
                                                    name: "BarChart.jsx",
                                                    data: files.docBarChartJSX,
                                                    icon: "fa-brands fa-react",
                                                },
                                            ],
                                        },
                                        {
                                            type: "folder",
                                            folderName: "BarRechart",
                                            path: "docs/src/components/Charts/BarRechart",
                                            isExpand: true,
                                            folders: [],
                                            files: [
                                                {
                                                    type: "file",
                                                    name: "BarRechart.jsx",
                                                    data: files.docBarRechartJSX,
                                                    icon: "fa-brands fa-react",
                                                },
                                            ],
                                        },
                                        {
                                            type: "folder",
                                            folderName: "CandlestickChart",
                                            path: "docs/src/components/Charts/CandlestickChart",
                                            isDownload:
                                                dashboardType === "pro"
                                                    ? "yes"
                                                    : "no",
                                            isExpand: true,
                                            folders: [],
                                            files: [
                                                {
                                                    type: "file",
                                                    name: "CandlestickChart.jsx",
                                                    data: files.docCandlestickChartJSX,
                                                    icon: "fa-brands fa-react",
                                                },
                                            ],
                                        },
                                        {
                                            type: "folder",
                                            folderName: "ColumnChart",
                                            path: "docs/src/components/Charts/ColumnChart",
                                            isDownload:
                                                dashboardType === "pro"
                                                    ? "yes"
                                                    : "no",
                                            isExpand: true,
                                            folders: [],
                                            files: [
                                                {
                                                    type: "file",
                                                    name: "ColumnChart.jsx",
                                                    data: files.docColumnChartJSX,
                                                    icon: "fa-brands fa-react",
                                                },
                                            ],
                                        },
                                        {
                                            type: "folder",
                                            folderName: "LineChart",
                                            path: "docs/src/components/Charts/LineChart",
                                            isExpand: true,
                                            folders: [],
                                            files: [
                                                {
                                                    type: "file",
                                                    name: "LineChart.jsx",
                                                    data: files.docLineChartJSX,
                                                    icon: "fa-brands fa-react",
                                                },
                                            ],
                                        },
                                        {
                                            type: "folder",
                                            folderName: "LineRechart",
                                            path: "docs/src/components/Charts/LineRechart",
                                            isExpand: true,
                                            folders: [],
                                            files: [
                                                {
                                                    type: "file",
                                                    name: "LineRechart.jsx",
                                                    data: files.docLineRechartJSX,
                                                    icon: "fa-brands fa-react",
                                                },
                                            ],
                                        },
                                        {
                                            type: "folder",
                                            folderName: "PaiChart",
                                            path: "docs/src/components/Charts/PaiChart",
                                            isExpand: true,
                                            folders: [],
                                            files: [
                                                {
                                                    type: "file",
                                                    name: "PaiChart.jsx",
                                                    data: files.docPaiChartJSX,
                                                    icon: "fa-brands fa-react",
                                                },
                                            ],
                                        },
                                        {
                                            type: "folder",
                                            folderName: "ScatterRechart",
                                            path: "docs/src/components/Charts/ScatterRechart",
                                            isExpand: true,
                                            folders: [],
                                            files: [
                                                {
                                                    type: "file",
                                                    name: "ScatterRechart.jsx",
                                                    data: files.docScatterRechartJSX,
                                                    icon: "fa-brands fa-react",
                                                },
                                            ],
                                        },
                                    ],
                                    files: [],
                                },
                                {
                                    type: "folder",
                                    folderName: "CodePreview",
                                    path: "docs/src/components/CodePreview",
                                    isExpand: true,
                                    folders: [],
                                    files: [
                                        {
                                            type: "file",
                                            name: "CodePreview.jsx",
                                            data: files.docCodePreviewJSX,
                                            icon: "fa-brands fa-react",
                                        },
                                    ],
                                },
                                {
                                    type: "folder",
                                    folderName: "FeatureTable",
                                    path: "docs/src/components/FeatureTable",
                                    isExpand: true,
                                    folders: [],
                                    files: [
                                        {
                                            type: "file",
                                            name: "FeatureTable.jsx",
                                            data: files.docFeatureTableJSX,
                                            icon: "fa-brands fa-react",
                                        },
                                    ],
                                },
                                {
                                    type: "folder",
                                    folderName: "Maps",
                                    path: "docs/src/components/Maps",
                                    isExpand: true,
                                    folders: [
                                        {
                                            type: "folder",
                                            folderName: "Google",
                                            path: "docs/src/components/Maps/Google",
                                            isExpand: true,
                                            folders: [],
                                            files: [
                                                {
                                                    type: "file",
                                                    name: "Google.jsx",
                                                    data: files.docGoogleJSX,
                                                    icon: "fa-brands fa-react",
                                                },
                                            ],
                                        },
                                        {
                                            type: "folder",
                                            folderName: "Leaflet",
                                            path: "docs/src/components/Maps/Leaflet",
                                            isExpand: true,
                                            folders: [],
                                            files: [
                                                {
                                                    type: "file",
                                                    name: "Leaflet.jsx",
                                                    data: files.docLeafletJSX,
                                                    icon: "fa-brands fa-react",
                                                },
                                            ],
                                        },
                                    ],
                                    files: [],
                                },
                                {
                                    type: "folder",
                                    folderName: "Modal",
                                    path: "docs/src/components/Modal",
                                    isExpand: true,
                                    folders: [],
                                    files: [
                                        {
                                            type: "file",
                                            name: "LargeModal.jsx",
                                            data: files.docLargeModalJSX,
                                            icon: "fa-brands fa-react",
                                        },
                                        {
                                            type: "file",
                                            name: "MediumModal.jsx",
                                            data: files.docMediumModalJSX,
                                            icon: "fa-brands fa-react",
                                        },
                                        {
                                            type: "file",
                                            name: "SmallModal.jsx",
                                            data: files.docSmallModalJSX,
                                            icon: "fa-brands fa-react",
                                        },
                                        {
                                            type: "file",
                                            name: "WithoutAnimatedModal.jsx",
                                            data: files.docWithoutAnimatedModalJSX,
                                            icon: "fa-brands fa-react",
                                        },
                                    ],
                                },
                                {
                                    type: "folder",
                                    folderName: "Notice",
                                    path: "docs/src/components/Notice",
                                    isExpand: true,
                                    folders: [
                                        {
                                            type: "folder",
                                            folderName: "FlipClock",
                                            path: "docs/src/components/Notice/FlipClock",
                                            isExpand: true,
                                            folders: [],
                                            files: [
                                                {
                                                    type: "file",
                                                    name: "FlipClock.jsx",
                                                    data: files.docFlipClockJSX,
                                                    icon: "fa-brands fa-react",
                                                },
                                                {
                                                    type: "file",
                                                    name: "FlipClock.css",
                                                    data: files.docFlipClockCSS,
                                                    icon: "fa-brands fa-react",
                                                },
                                            ],
                                        },
                                        {
                                            type: "folder",
                                            folderName: "hooks",
                                            path: "docs/src/components/Notice/hooks",
                                            isExpand: true,
                                            folders: [],
                                            files: [
                                                {
                                                    type: "file",
                                                    name: "useReactCounterDown.js",
                                                    data: files.useReactCounterDownJS,
                                                    icon: "fa-brands fa-js",
                                                },
                                            ],
                                        },
                                        {
                                            type: "folder",
                                            folderName: "OfferNotice",
                                            path: "docs/src/components/Notice/OfferNotice",
                                            isExpand: true,
                                            folders: [],
                                            files: [
                                                {
                                                    type: "file",
                                                    name: "OfferNotice.jsx",
                                                    data: files.docOfferNoticeJSX,
                                                    icon: "fa-brands fa-react",
                                                },
                                                {
                                                    type: "file",
                                                    name: "OfferNotice.css",
                                                    data: files.docOfferNoticeCSS,
                                                    icon: "fa-brands fa-css3",
                                                },
                                            ],
                                        },
                                    ],
                                    files: [
                                        {
                                            type: "file",
                                            name: "App.jsx",
                                            data: files.docAppJSX,
                                            icon: "fa-brands fa-react",
                                        },
                                    ],
                                },
                                {
                                    type: "folder",
                                    folderName: "ProgressBar",
                                    path: "docs/src/components/ProgressBar",
                                    isExpand: true,
                                    folders: [],
                                    files: [
                                        {
                                            type: "file",
                                            name: "ProgressBar.jsx",
                                            data: files.docProgressBarJSX,
                                            icon: "fa-brands fa-react",
                                        },
                                        {
                                            type: "file",
                                            name: "ProgressBar.scss",
                                            data: files.docProgressBarSCSS,
                                            icon: "fa-brands fa-sass",
                                        },
                                    ],
                                },
                                {
                                    type: "folder",
                                    folderName: "SocialButton",
                                    path: "docs/src/components/SocialButton",
                                    isExpand: true,
                                    folders: [],
                                    files: [
                                        {
                                            type: "file",
                                            name: "SocialButton.jsx",
                                            data: files.docSocialButtonJSX,
                                            icon: "fa-brands fa-react",
                                        },
                                        {
                                            type: "file",
                                            name: "SocialButton.module.scss",
                                            data: files.docSocialButtonSCSS,
                                            icon: "fa-brands fa-sass",
                                        },
                                    ],
                                },
                                {
                                    type: "folder",
                                    folderName: "Tab",
                                    path: "docs/src/components/Tab",
                                    isDownload:
                                        dashboardType === "pro" ? "yes" : "no",
                                    isExpand: true,
                                    folders: [],
                                    files: [
                                        {
                                            type: "file",
                                            name: "Tab.jsx",
                                            data: files.docTabJSX,
                                            icon: "fa-brands fa-react",
                                        },
                                        {
                                            type: "file",
                                            name: "Tab.module.scss",
                                            data: files.docTabSCSS,
                                            icon: "fa-brands fa-sass",
                                        },
                                    ],
                                },
                                {
                                    type: "folder",
                                    folderName: "Table",
                                    path: "docs/src/components/Table",
                                    isExpand: true,
                                    folders: [],
                                    files: [
                                        {
                                            type: "file",
                                            name: "BasicTable.jsx",
                                            data: files.docBasicTableJSX,
                                            icon: "fa-brands fa-react",
                                        },
                                        {
                                            type: "file",
                                            name: "SearchTable.jsx",
                                            data: files.docSearchTableJSX,
                                            icon: "fa-brands fa-react",
                                        },
                                        {
                                            type: "file",
                                            name: "VariantsTable.jsx",
                                            data: files.docVariantsTableJSX,
                                            icon: "fa-brands fa-react",
                                        },
                                        {
                                            type: "file",
                                            name: "Table.module.scss",
                                            data: files.docTableSCSS,
                                            icon: "fa-brands fa-sass",
                                        },
                                    ],
                                },
                                {
                                    type: "folder",
                                    folderName: "Widgets",
                                    path: "docs/src/components/Widgets",
                                    isExpand: true,
                                    folders: [
                                        {
                                            type: "folder",
                                            folderName: "SocialCounter",
                                            path: "docs/src/components/Widgets/SocialCounter",
                                            isExpand: true,
                                            folders: [],
                                            files: [
                                                {
                                                    type: "file",
                                                    name: "SocialCounter.jsx",
                                                    data: files.docSocialCounterJSX,
                                                    icon: "fa-brands fa-react",
                                                },
                                                {
                                                    type: "file",
                                                    name: "SocialCounter.module.scss",
                                                    data: files.docSocialCounterSCSS,
                                                    icon: "fa-brands fa-sass",
                                                },
                                            ],
                                        },
                                        {
                                            type: "folder",
                                            folderName: "StatsCard",
                                            path: "docs/src/components/Widgets/StatsCard",
                                            isExpand: true,
                                            folders: [],
                                            files: [
                                                {
                                                    type: "file",
                                                    name: "StatsCard.jsx",
                                                    data: files.docStatsCardJSX,
                                                    icon: "fa-brands fa-react",
                                                },
                                                {
                                                    type: "file",
                                                    name: "StatsCard.module.scss",
                                                    data: files.docStatsCardSCSS,
                                                    icon: "fa-brands fa-sass",
                                                },
                                            ],
                                        },
                                        {
                                            type: "folder",
                                            folderName: "UserStats",
                                            path: "docs/src/components/Widgets/UserStats",
                                            isExpand: true,
                                            folders: [],
                                            files: [
                                                {
                                                    type: "file",
                                                    name: "UserStats.jsx",
                                                    data: files.docUserStatsJSX,
                                                    icon: "fa-brands fa-react",
                                                },
                                            ],
                                        },
                                    ],
                                    files: [],
                                },
                                {
                                    type: "folder",
                                    folderName: "WizardForm",
                                    path: "docs/src/components/WizardForm",
                                    isDownload:
                                        dashboardType === "pro" ? "yes" : "no",
                                    isExpand: true,
                                    folders: [
                                        {
                                            type: "folder",
                                            folderName: "Page",
                                            path: "docs/src/components/WizardForm/Page",
                                            isExpand: true,
                                            folders: [],
                                            files: [
                                                {
                                                    type: "file",
                                                    name: "AdditionalInfo.jsx",
                                                    data: files.docAdditionalInfoJSX,
                                                    icon: "fa-brands fa-react",
                                                },
                                                {
                                                    type: "file",
                                                    name: "LoggedIn.jsx",
                                                    data: files.docLoggedInJSX,
                                                    icon: "fa-brands fa-react",
                                                },
                                                {
                                                    type: "file",
                                                    name: "PersonalDetails.jsx",
                                                    data: files.docPersonalDetails,
                                                    icon: "fa-brands fa-react",
                                                },
                                            ],
                                        },
                                    ],
                                    files: [
                                        {
                                            type: "file",
                                            name: "StepCircleWizard.jsx",
                                            data: files.docStepCircleWizardJSX,
                                            icon: "fa-brands fa-react",
                                        },
                                        {
                                            type: "file",
                                            name: "StepCircleWizard.module.scss",
                                            data: files.docStepCircleWizardSCSS,
                                            icon: "fa-brands fa-sass",
                                        },
                                        {
                                            type: "file",
                                            name: "StepSquarWizard.jsx",
                                            data: files.docStepSquarWizardJSX,
                                            icon: "fa-brands fa-react",
                                        },
                                        {
                                            type: "file",
                                            name: "StepSquarWizard.module.scss",
                                            data: files.docStepSquarWizardSCSS,
                                            icon: "fa-brands fa-sass",
                                        },
                                        {
                                            type: "file",
                                            name: "StepTabWizard.jsx",
                                            data: files.docStepTabWizardJSX,
                                            icon: "fa-brands fa-react",
                                        },
                                        {
                                            type: "file",
                                            name: "StepTabWizard.module.scss",
                                            data: files.docStepTabWizardSCSS,
                                            icon: "fa-brands fa-sass",
                                        },
                                    ],
                                },
                            ],
                            files: [],
                        },
                        {
                            type: "folder",
                            folderName: "css",
                            path: "docs/src/css",
                            isExpand: true,
                            folders: [],
                            files: [
                                {
                                    type: "file",
                                    name: "custom.css",
                                    data: files.docCustomCSS,
                                    icon: "fa-brands fa-css3",
                                },
                                {
                                    type: "file",
                                    name: "variable.scss",
                                    data: files.docVariableSCSS,
                                    icon: "fa-brands fa-sass",
                                },
                            ],
                        },
                    ],
                    files: [],
                },
                {
                    type: "folder",
                    folderName: "static",
                    path: "docs/static",
                    isExpand: true,
                    folders: [
                        {
                            type: "folder",
                            folderName: "img",
                            path: "docs/static/img",
                            isExpand: true,
                            folders: [],
                            files: [
                                {
                                    type: "file",
                                    img: true,
                                    name: "favicon.ico",
                                    data: docFavicon,
                                    icon: "fa-regular fa-image",
                                },
                                {
                                    type: "file",
                                    img: true,
                                    name: "black-logo.png",
                                    data: docBlackLogo,
                                    icon: "fa-regular fa-image",
                                },
                                {
                                    type: "file",
                                    img: true,
                                    name: "white-logo.png",
                                    data: docWhiteLogo,
                                    icon: "fa-regular fa-image",
                                },
                                {
                                    type: "file",
                                    img: true,
                                    name: "marker-icon.png",
                                    data: docMarkerIcon,
                                    icon: "fa-regular fa-image",
                                },
                            ],
                        },
                    ],
                    files: [
                        {
                            type: "file",
                            name: ".nojekyll",
                            data: files.docNojekyll,
                            icon: "fa-regular fa-file",
                        },
                        {
                            type: "file",
                            name: "notice.js",
                            data: files.docNotice,
                            icon: "fa-brands fa-js",
                        },
                    ],
                },
                {
                    type: "folder",
                    folderName: "utils",
                    path: "docs/utils",
                    isExpand: true,
                    folders: [],
                    files: [
                        {
                            type: "file",
                            name: "waitForElm.js",
                            data: files.docWaitForElm,
                            icon: "fa-brands fa-js",
                        },
                    ],
                },
            ],
            files: [
                {
                    type: "file",
                    name: ".gitignore",
                    data: files.docGitignore,
                    icon: "fa-regular fa-file",
                },
                {
                    type: "file",
                    name: "babel.config.js",
                    data: files.docBabelConfig,
                    icon: "fa-regular fa-file",
                },
                {
                    type: "file",
                    name: "docusaurus.config.js",
                    data: files.docDocusaurusConfig,
                    icon: "fa-regular fa-file",
                },
                {
                    type: "file",
                    name: "package.json",
                    data: files.docPackageJson,
                    icon: "fa-regular fa-file",
                },
                {
                    type: "file",
                    name: "README.md",
                    data: files.docReadmeMd,
                    icon: "fa-regular fa-file",
                },
                {
                    type: "file",
                    name: "sidebars.js",
                    data: files.docSidebars,
                    icon: "fa-brands fa-js",
                },
            ],
        };

        let github = {
            type: "folder",
            folderName: "github",
            path: "github",
            isExpand: true,
            folders: [
                {
                    type: "folder",
                    folderName: "workflows",
                    path: "github/workflows",
                    isExpand: true,
                    folders: [],
                    files: [
                        {
                            type: "file",
                            name: `bootstrap-${rootFolderName}.yml`,
                            data: files.bootstrapProductAdminDemoYML,
                            icon: "fa-solid fa-file-code",
                        },
                    ],
                },
            ],
            files: [
                {
                    type: "file",
                    name: `FUNDING.yml`,
                    data: files.funding,
                    icon: "fa-solid fa-file-code",
                },
            ],
        };

        let mainData = [
            { ...github },
            { ...docFiles },
            {
                type: "folder",
                folderName: "public",
                path: "public",
                isExpand: true,
                folders: [],
                files: [],
            },
            {
                type: "folder",
                folderName: "src",
                path: "src",
                isExpand: true,
                folders: [
                    {
                        type: "folder",
                        folderName: "assets",
                        path: "src/assets",
                        isExpand: true,
                        folders: [
                            {
                                type: "folder",
                                folderName: "image",
                                path: "src/assets/image",
                                isExpand: true,
                                files: [
                                    {
                                        type: "file",
                                        name: "admin.jpg",
                                        img: true,
                                        data: admin,
                                        icon: "fa-regular fa-image",
                                    },
                                    {
                                        type: "file",
                                        name: "logo.png",
                                        img: true,
                                        data: loglo,
                                        icon: "fa-regular fa-image",
                                    },
                                    {
                                        type: "file",
                                        name: "mini-logo.png",
                                        img: true,
                                        data: mini_loglo,
                                        icon: "fa-regular fa-image",
                                    },
                                    {
                                        type: "file",
                                        name: "black-logo.png",
                                        img: true,
                                        data: black_logo,
                                        icon: "fa-regular fa-image",
                                    },
                                    {
                                        type: "file",
                                        name: "black-mini-logo.png",
                                        img: true,
                                        data: black_logo_mini,
                                        icon: "fa-regular fa-image",
                                    },
                                    {
                                        type: "file",
                                        name: "placeholder.png",
                                        img: true,
                                        data: placeholder,
                                        icon: "fa-regular fa-image",
                                    },
                                ],
                                folders: [
                                    {
                                        type: "folder",
                                        folderName: "avatar",
                                        path: "src/assets/image/avatar",
                                        isExpand: true,
                                        folders: [],
                                        files: [
                                            {
                                                type: "file",
                                                name: "avatar.png",
                                                img: true,
                                                data: avatar,
                                                icon: "fa-regular fa-image",
                                            },
                                            {
                                                type: "file",
                                                name: "user-1.jpg",
                                                img: true,
                                                data: user1,
                                                icon: "fa-regular fa-image",
                                            },
                                            {
                                                type: "file",
                                                name: "user-2.jpg",
                                                img: true,
                                                data: user2,
                                                icon: "fa-regular fa-image",
                                            },
                                            {
                                                type: "file",
                                                name: "user-3.jpg",
                                                img: true,
                                                data: user3,
                                                icon: "fa-regular fa-image",
                                            },
                                            {
                                                type: "file",
                                                name: "user-4.jpg",
                                                img: true,
                                                data: user4,
                                                icon: "fa-regular fa-image",
                                            },
                                            {
                                                type: "file",
                                                name: "user-5.jpg",
                                                img: true,
                                                data: user5,
                                                icon: "fa-regular fa-image",
                                            },
                                            {
                                                type: "file",
                                                name: "user-6.jpg",
                                                img: true,
                                                data: user6,
                                                icon: "fa-regular fa-image",
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                type: "folder",
                                folderName: "scss",
                                path: "src/assets/scss",
                                isExpand: true,
                                folders: [
                                    {
                                        type: "folder",
                                        folderName: "navbars",
                                        path: "src/assets/scss/navbars",
                                        isExpand: true,
                                        files: [
                                            {
                                                type: "file",
                                                name: "Combo.module.scss",
                                                data: files.comboScss,
                                                icon: "fa-brands fa-sass",
                                            },
                                            {
                                                type: "file",
                                                name: "DualTopNav.module.scss",
                                                data: files.dualNavScss,
                                                icon: "fa-brands fa-sass",
                                            },
                                            {
                                                type: "file",
                                                name: "HorizontalNav.module.scss",
                                                data: files.horizontalScss,
                                                icon: "fa-brands fa-sass",
                                            },
                                            {
                                                type: "file",
                                                name: "VerticalNav.module.scss",
                                                data: files.verticalScss,
                                                icon: "fa-brands fa-sass",
                                            },
                                        ],
                                        folders: [],
                                    },
                                ],
                                files: [
                                    {
                                        type: "file",
                                        name: "AnalyticsStats.module.scss",
                                        data: files.analyticsStatsScss,
                                        icon: "fa-brands fa-sass",
                                    },
                                    {
                                        type: "file",
                                        name: "Authentication.module.scss",
                                        data: files.authentication,
                                        icon: "fa-brands fa-sass",
                                    },
                                    {
                                        type: "file",
                                        name: "Card.module.scss",
                                        data: files.cardScss,
                                        icon: "fa-brands fa-sass",
                                    },
                                    {
                                        type: "file",
                                        name: "DarkModeSwitch.module.scss",
                                        data: files.darkModeSwitchScss,
                                        icon: "fa-brands fa-sass",
                                    },
                                    {
                                        type: "file",
                                        name: "DeviceVisitorStats.module.scss",
                                        data: files.deviceVisitorStatsScss,
                                        icon: "fa-brands fa-sass",
                                    },
                                    {
                                        type: "file",
                                        name: "Earning.module.scss",
                                        data: files.earningScss,
                                        icon: "fa-brands fa-sass",
                                    },
                                    {
                                        type: "file",
                                        name: "Grids.module.scss",
                                        data: files.gridsScss,
                                        icon: "fa-brands fa-sass",
                                    },
                                    {
                                        type: "file",
                                        name: "IconStyle.module.scss",
                                        data: files.iconStyleScss,
                                        icon: "fa-brands fa-sass",
                                    },
                                    {
                                        type: "file",
                                        name: "Layouts.module.scss",
                                        data: files.layoutsScss,
                                        icon: "fa-brands fa-sass",
                                    },
                                    {
                                        type: "file",
                                        name: "Message.module.scss",
                                        data: files.messageScss,
                                        icon: "fa-brands fa-sass",
                                    },
                                    {
                                        type: "file",
                                        name: "MonthlyStats.module.scss",
                                        data: files.monthlyStatsScss,
                                        icon: "fa-brands fa-sass",
                                    },
                                    {
                                        type: "file",
                                        name: "NavBarBgWrapper.module.scss",
                                        data: files.navbarBgWrapperScss,
                                        icon: "fa-brands fa-sass",
                                    },
                                    {
                                        type: "file",
                                        name: "Notification.module.scss",
                                        data: files.notificationScss,
                                        icon: "fa-brands fa-sass",
                                    },
                                    {
                                        type: "file",
                                        name: "NotificationStatsCart.module.scss",
                                        data: files.notificationStatsCartScss,
                                        icon: "fa-brands fa-sass",
                                    },
                                    {
                                        type: "file",
                                        name: "OverlayCard.module.scss",
                                        data: files.overlayCardScss,
                                        icon: "fa-brands fa-sass",
                                    },
                                    {
                                        type: "file",
                                        name: "ProfileCard.module.scss",
                                        data: files.profileCardScss,
                                        icon: "fa-brands fa-sass",
                                    },
                                    {
                                        type: "file",
                                        name: "SearchBar.module.scss",
                                        data: files.searchBarScss,
                                        icon: "fa-brands fa-sass",
                                    },
                                    {
                                        type: "file",
                                        name: "Sidebar.module.scss",
                                        data: files.sidebarScss,
                                        icon: "fa-brands fa-sass",
                                    },
                                    {
                                        type: "file",
                                        name: "SidebarBgWrapper.module.scss",
                                        data: files.sidebarBgWrapperScss,
                                        icon: "fa-brands fa-sass",
                                    },
                                    {
                                        type: "file",
                                        name: "SocialButton.module.scss",
                                        data: files.socialButtonScss,
                                        icon: "fa-brands fa-sass",
                                    },
                                    {
                                        type: "file",
                                        name: "SocialCounter.module.scss",
                                        data: files.socialCounterScss,
                                        icon: "fa-brands fa-sass",
                                    },
                                    {
                                        type: "file",
                                        name: "SocialStats.module.scss",
                                        data: files.socialStatsScss,
                                        icon: "fa-brands fa-sass",
                                    },
                                    {
                                        type: "file",
                                        name: "Stats.module.scss",
                                        data: files.statsScss,
                                        icon: "fa-brands fa-sass",
                                    },
                                    {
                                        type: "file",
                                        name: "StatsCard.module.scss",
                                        data: files.statsCardScss,
                                        icon: "fa-brands fa-sass",
                                    },
                                    {
                                        type: "file",
                                        name: "StepCircleWizard.module.scss",
                                        data: files.stepCircleWizardScss,
                                        icon: "fa-brands fa-sass",
                                    },
                                    {
                                        type: "file",
                                        name: "StepSquarWizard.module.scss",
                                        data: files.stepSquarWizardScss,
                                        icon: "fa-brands fa-sass",
                                    },
                                    {
                                        type: "file",
                                        name: "StepTabWizard.module.scss",
                                        data: files.stepTabWizardScss,
                                        icon: "fa-brands fa-sass",
                                    },
                                    {
                                        type: "file",
                                        name: "Tables.module.scss",
                                        data: files.tablesScss,
                                        icon: "fa-brands fa-sass",
                                    },
                                    {
                                        type: "file",
                                        name: "Tabs.module.scss",
                                        data: files.tabsScss,
                                        icon: "fa-brands fa-sass",
                                    },
                                    {
                                        type: "file",
                                        name: "Timeline.module.scss",
                                        data: files.timelineScss,
                                        icon: "fa-brands fa-sass",
                                    },
                                    {
                                        type: "file",
                                        name: "Traffic.module.scss",
                                        data: files.trafficScss,
                                        icon: "fa-brands fa-sass",
                                    },
                                    {
                                        type: "file",
                                        name: "Typography.module.scss",
                                        data: files.typographyScss,
                                        icon: "fa-brands fa-sass",
                                    },
                                    {
                                        type: "file",
                                        name: "UesrProfile.module.scss",
                                        data: files.uesrProfileScss,
                                        icon: "fa-brands fa-sass",
                                    },
                                    {
                                        type: "file",
                                        name: "variable.scss",
                                        data: files.variableScss,
                                        icon: "fa-brands fa-sass",
                                    },
                                    {
                                        type: "file",
                                        name: "WeatherStats.module.scss",
                                        data: files.weatherStatsScss,
                                        icon: "fa-brands fa-sass",
                                    },
                                ],
                            },
                        ],
                        files: [],
                    },
                    {
                        type: "folder",
                        folderName: "components",
                        path: "src/components",
                        isExpand: true,
                        folders: [
                            {
                                type: "folder",
                                folderName: "AnalyticsStats",
                                path: "src/components/AnalyticsStats",
                                isExpand: true,
                                folders: [],
                                files: [
                                    {
                                        type: "file",
                                        name: "AnalyticsStats.jsx",
                                        data: files.analyticsStats,
                                        icon: "fa-brands fa-js",
                                    },
                                ],
                            },
                            {
                                type: "folder",
                                folderName: "ApexCharts",
                                path: "src/components/ApexCharts",
                                isDownload:
                                    dashboardType === "pro" ? "yes" : "no",
                                isExpand: true,
                                folders: [],
                                files: [
                                    {
                                        type: "file",
                                        name: "AreaChart.jsx",
                                        data: files.areaChart,
                                        icon: "fa-brands fa-js",
                                    },
                                    {
                                        type: "file",
                                        name: "BarChart.jsx",
                                        data: files.barChartApex,
                                        icon: "fa-brands fa-js",
                                    },
                                    {
                                        type: "file",
                                        name: "BubbleChart.jsx",
                                        data: files.bubbleChart,
                                        icon: "fa-brands fa-js",
                                    },
                                    {
                                        type: "file",
                                        name: "CandlestickChart.jsx",
                                        data: files.candlestickChart,
                                        icon: "fa-brands fa-js",
                                    },
                                    {
                                        type: "file",
                                        name: "ColumnChart.jsx",
                                        data: files.columnChart,
                                        icon: "fa-brands fa-js",
                                    },
                                    {
                                        type: "file",
                                        name: "PieChart.jsx",
                                        data: files.pieChart,
                                        icon: "fa-brands fa-js",
                                    },
                                ],
                            },
                            {
                                type: "folder",
                                folderName: "Card",
                                path: "src/components/Card",
                                isExpand: true,
                                folders: [],
                                files: [
                                    {
                                        type: "file",
                                        name: "Card.jsx",
                                        data: files.card,
                                        icon: "fa-brands fa-js",
                                    },
                                ],
                            },
                            {
                                type: "folder",
                                folderName: "Chartjs",
                                path: "src/components/Chartjs",
                                isExpand: true,
                                folders: [],
                                files: [
                                    {
                                        type: "file",
                                        name: "BarChart.jsx",
                                        data: files.barChart,
                                        icon: "fa-brands fa-js",
                                    },
                                    {
                                        type: "file",
                                        name: "DoughnutChart.jsx",
                                        data: files.doughnutChart,
                                        icon: "fa-brands fa-js",
                                    },
                                    {
                                        type: "file",
                                        name: "LineChart.jsx",
                                        data: files.lineChartCJs,
                                        icon: "fa-brands fa-js",
                                    },
                                    {
                                        type: "file",
                                        name: "PieChart.jsx",
                                        data: files.pieChartCJs,
                                        icon: "fa-brands fa-js",
                                    },
                                    {
                                        type: "file",
                                        name: "PolarAreaChart.jsx",
                                        data: files.polarAreaChart,
                                        icon: "fa-brands fa-js",
                                    },
                                    {
                                        type: "file",
                                        name: "RadarChart.jsx",
                                        data: files.radarChart,
                                        icon: "fa-brands fa-js",
                                    },
                                ],
                            },
                            {
                                type: "folder",
                                folderName: "DarkModeSwitch",
                                path: "src/components/DarkModeSwitch",
                                isExpand: true,
                                folders: [],
                                files: [
                                    {
                                        type: "file",
                                        name: "DarkModeSwitch.jsx",
                                        data: files.darkModeSwitch,
                                        icon: "fa-brands fa-js",
                                    },
                                ],
                            },
                            {
                                type: "folder",
                                folderName: "DeviceVisitorStats",
                                path: "src/components/DeviceVisitorStats",
                                isExpand: true,
                                folders: [],
                                files: [
                                    {
                                        type: "file",
                                        name: "DeviceVisitorStats.jsx",
                                        data: files.deviceVisitorStats,
                                        icon: "fa-brands fa-js",
                                    },
                                ],
                            },
                            {
                                type: "folder",
                                folderName: "Download",
                                path: "src/components/Download",
                                isExpand: true,
                                folders: [],
                                files: [
                                    {
                                        type: "file",
                                        name: "Download.jsx",
                                        data: files.download,
                                        icon: "fa-brands fa-js",
                                    },
                                ],
                            },
                            {
                                type: "folder",
                                folderName: "DropdownMenu",
                                path: "src/components/DropdownMenu",
                                isExpand: true,
                                folders: [],
                                files: [
                                    {
                                        type: "file",
                                        name: "DropdownMenu.jsx",
                                        data: files.dropdownMenuCode,
                                        icon: "fa-brands fa-js",
                                    },
                                ],
                            },
                            {
                                type: "folder",
                                folderName: "Earning",
                                path: "src/components/Earning",
                                isExpand: true,
                                folders: [],
                                files: [
                                    {
                                        type: "file",
                                        name: "Earning.jsx",
                                        data: files.earning,
                                        icon: "fa-brands fa-js",
                                    },
                                ],
                            },
                            {
                                type: "folder",
                                folderName: "Icon",
                                path: "src/components/Icon",
                                isExpand: true,
                                folders: [],
                                files: [
                                    {
                                        type: "file",
                                        name: "Brandico.jsx",
                                        data: files.brandicoCode,
                                        icon: "fa-brands fa-js",
                                    },
                                    {
                                        type: "file",
                                        name: "CopyButton.jsx",
                                        data: files.copyButton,
                                        icon: "fa-brands fa-js",
                                    },
                                    {
                                        type: "file",
                                        name: "Entypo.jsx",
                                        data: files.entypoCode,
                                        icon: "fa-brands fa-js",
                                    },
                                    {
                                        type: "file",
                                        name: "FontAwesome.jsx",
                                        data: files.fontAwesomeCode,
                                        icon: "fa-brands fa-js",
                                    },
                                    {
                                        type: "file",
                                        name: "Fontelico.jsx",
                                        data: files.fontelicoCode,
                                        icon: "fa-brands fa-js",
                                    },
                                    {
                                        type: "file",
                                        name: "Iconviewer.jsx",
                                        data: files.iconviewer,
                                        icon: "fa-brands fa-js",
                                    },
                                ],
                            },
                            {
                                type: "folder",
                                folderName: "LeafletMaps",
                                path: "src/components/LeafletMaps",
                                isExpand: true,
                                folders: [],
                                files: [
                                    {
                                        type: "file",
                                        name: "DarkMatter.jsx",
                                        data: files.darkMatter,
                                        icon: "fa-brands fa-js",
                                    },
                                    {
                                        type: "file",
                                        name: "StreetMap.jsx",
                                        data: files.streetMap,
                                        icon: "fa-brands fa-js",
                                    },
                                    {
                                        type: "file",
                                        name: "Voyager.jsx",
                                        data: files.voyager,
                                        icon: "fa-brands fa-js",
                                    },
                                    {
                                        type: "file",
                                        name: "WorldImagery.jsx",
                                        data: files.worldImagery,
                                        icon: "fa-brands fa-js",
                                    },
                                ],
                            },
                            {
                                type: "folder",
                                folderName: "MonthlyStats",
                                path: "src/components/MonthlyStats",
                                isExpand: true,
                                folders: [],
                                files: [
                                    {
                                        type: "file",
                                        name: "MonthlyStats.jsx",
                                        data: files.monthlyStats,
                                        icon: "fa-brands fa-js",
                                    },
                                ],
                            },
                            {
                                type: "folder",
                                folderName: "Navbars",
                                path: "src/components/Navbars",
                                isExpand: true,
                                files: [
                                    {
                                        type: "file",
                                        name: "NavBarBgWrapper.jsx",
                                        data: files.navBarBgWrapper,
                                        icon: "fa-brands fa-js",
                                    },
                                    {
                                        type: "file",
                                        name: "Navbars.jsx",
                                        data: files.Navbars,
                                        icon: "fa-brands fa-js",
                                    },
                                ],
                                folders: [
                                    {
                                        type: "folder",
                                        folderName: "Message",
                                        path: "src/components/Navbars/Message",
                                        isExpand: true,
                                        folders: [],
                                        files: [
                                            {
                                                type: "file",
                                                name: "Message.jsx",
                                                data: files.messageCode,
                                                icon: "fa-brands fa-js",
                                            },
                                        ],
                                    },
                                    {
                                        type: "folder",
                                        folderName: "NavbarType",
                                        path: "src/components/Navbars/NavbarType",
                                        isExpand: true,
                                        folders: [],
                                        files: [
                                            {
                                                type: "file",
                                                name: "Combo.jsx",
                                                data: files.combo,
                                                icon: "fa-brands fa-js",
                                            },
                                            {
                                                type: "file",
                                                name: "DualTopNav.jsx",
                                                data: files.dualTopNav,
                                                icon: "fa-brands fa-js",
                                            },
                                            {
                                                type: "file",
                                                name: "HorizontalNav.jsx",
                                                data: files.horizontalNav,
                                                icon: "fa-brands fa-js",
                                            },
                                            {
                                                type: "file",
                                                name: "VerticalNav.jsx",
                                                data: files.verticalNav,
                                                icon: "fa-brands fa-js",
                                            },
                                        ],
                                    },
                                    {
                                        type: "folder",
                                        folderName: "Notification",
                                        path: "src/components/Navbars/Notification",
                                        isExpand: true,
                                        folders: [],
                                        files: [
                                            {
                                                type: "file",
                                                name: "Notification.jsx",
                                                data: files.notificationCode,
                                                icon: "fa-brands fa-js",
                                            },
                                        ],
                                    },
                                    {
                                        type: "folder",
                                        folderName: "UserProfile",
                                        path: "src/components/Navbars/UserProfile",
                                        isExpand: true,
                                        folders: [],
                                        files: [
                                            {
                                                type: "file",
                                                name: "UserProfile.jsx",
                                                data: files.userProfile,
                                                icon: "fa-brands fa-js",
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                type: "folder",
                                folderName: "NotificationStatsCart",
                                path: "src/components/NotificationStatsCart",
                                isExpand: true,
                                folders: [],
                                files: [
                                    {
                                        type: "file",
                                        name: "NotificationStatsCart.jsx",
                                        data: files.notificationStatsCart,
                                        icon: "fa-brands fa-js",
                                    },
                                ],
                            },
                            {
                                type: "folder",
                                folderName: "OverlayCard",
                                path: "src/components/OverlayCard",
                                isExpand: true,
                                folders: [],
                                files: [
                                    {
                                        type: "file",
                                        name: "OverlayCard.jsx",
                                        data: files.overlayCard,
                                        icon: "fa-brands fa-js",
                                    },
                                ],
                            },
                            {
                                type: "folder",
                                folderName: "ProfileCard",
                                path: "src/components/ProfileCard",
                                isExpand: true,
                                folders: [],
                                files: [
                                    {
                                        type: "file",
                                        name: "ProfileCard.jsx",
                                        data: files.profileCard,
                                        icon: "fa-brands fa-js",
                                    },
                                ],
                            },
                            {
                                type: "folder",
                                folderName: "RealTime",
                                path: "src/components/RealTime",
                                isExpand: true,
                                folders: [],
                                files: [
                                    {
                                        type: "file",
                                        name: "RealTime.jsx",
                                        data: files.realTime,
                                        icon: "fa-brands fa-js",
                                    },
                                ],
                            },
                            {
                                type: "folder",
                                folderName: "Recharts",
                                path: "src/components/Recharts",
                                isExpand: true,
                                folders: [],
                                files: [
                                    {
                                        type: "file",
                                        name: "AreaRechart.jsx",
                                        data: files.areaRechart,
                                        icon: "fa-brands fa-js",
                                    },
                                    {
                                        type: "file",
                                        name: "BarRechart.jsx",
                                        data: files.barRechart,
                                        icon: "fa-brands fa-js",
                                    },
                                    {
                                        type: "file",
                                        name: "LineRechart.jsx",
                                        data: files.lineRechart,
                                        icon: "fa-brands fa-js",
                                    },
                                    {
                                        type: "file",
                                        name: "PieRechart.jsx",
                                        data: files.pieRechart,
                                        icon: "fa-brands fa-js",
                                    },
                                    {
                                        type: "file",
                                        name: "RechartRadialBar.jsx",
                                        data: files.rechartRadialBar,
                                        icon: "fa-brands fa-js",
                                    },
                                    {
                                        type: "file",
                                        name: "RechartScatter.jsx",
                                        data: files.rechartScatter,
                                        icon: "fa-brands fa-js",
                                    },
                                ],
                            },
                            {
                                type: "folder",
                                folderName: "Revenue",
                                path: "src/components/Revenue",
                                isExpand: true,
                                folders: [],
                                files: [
                                    {
                                        type: "file",
                                        name: "Revenue.jsx",
                                        data: files.revenue,
                                        icon: "fa-brands fa-js",
                                    },
                                ],
                            },
                            {
                                type: "folder",
                                folderName: "SearchBar",
                                path: "src/components/SearchBar",
                                isExpand: true,
                                folders: [],
                                files: [
                                    {
                                        type: "file",
                                        name: "SearchBar.jsx",
                                        data: files.searchBar,
                                        icon: "fa-brands fa-js",
                                    },
                                ],
                            },
                            {
                                type: "folder",
                                folderName: "Sidebar",
                                path: "src/components/Sidebar",
                                isExpand: true,
                                folders: [],
                                files: [
                                    {
                                        type: "file",
                                        name: "Sidebar.jsx",
                                        data: files.sidebar,
                                        icon: "fa-brands fa-js",
                                    },
                                    {
                                        type: "file",
                                        name: "SidebarBgWrapper.jsx",
                                        data: files.sidebarBgWrapper,
                                        icon: "fa-brands fa-js",
                                    },
                                    {
                                        type: "file",
                                        name: "SidebarMenu.jsx",
                                        data: files.sidebarMenu,
                                        icon: "fa-brands fa-js",
                                    },
                                ],
                            },
                            {
                                type: "folder",
                                folderName: "SocialButton",
                                path: "src/components/SocialButton",
                                isExpand: true,
                                folders: [],
                                files: [
                                    {
                                        type: "file",
                                        name: "SocialButton.jsx",
                                        data: files.socialButton,
                                        icon: "fa-brands fa-js",
                                    },
                                ],
                            },
                            {
                                type: "folder",
                                folderName: "SocialCounter",
                                path: "src/components/SocialCounter",
                                isExpand: true,
                                folders: [],
                                files: [
                                    {
                                        type: "file",
                                        name: "SocialCounter.jsx",
                                        data: files.socialCounter,
                                        icon: "fa-brands fa-js",
                                    },
                                ],
                            },
                            {
                                type: "folder",
                                folderName: "SocialStats",
                                path: "src/components/SocialStats",
                                isExpand: true,
                                folders: [],
                                files: [
                                    {
                                        type: "file",
                                        name: "SocialStats.jsx",
                                        data: files.socialStats,
                                        icon: "fa-brands fa-js",
                                    },
                                ],
                            },
                            {
                                type: "folder",
                                folderName: "Stats",
                                path: "src/components/Stats",
                                isExpand: true,
                                folders: [],
                                files: [
                                    {
                                        type: "file",
                                        name: "Day.jsx",
                                        data: files.day,
                                        icon: "fa-brands fa-js",
                                    },
                                    {
                                        type: "file",
                                        name: "Month.jsx",
                                        data: files.month,
                                        icon: "fa-brands fa-js",
                                    },
                                    {
                                        type: "file",
                                        name: "Stats.jsx",
                                        data: files.statsCodeBase,
                                        icon: "fa-brands fa-js",
                                    },
                                    {
                                        type: "file",
                                        name: "Year.jsx",
                                        data: files.year,
                                        icon: "fa-brands fa-js",
                                    },
                                ],
                            },
                            {
                                type: "folder",
                                folderName: "StatsCard",
                                path: "src/components/StatsCard",
                                isExpand: true,
                                folders: [],
                                files: [
                                    {
                                        type: "file",
                                        name: "StatsCard.jsx",
                                        data: files.statsCard,
                                        icon: "fa-brands fa-js",
                                    },
                                ],
                            },
                            {
                                type: "folder",
                                folderName: "StepWizards",
                                path: "src/components/StepWizards",
                                isDownload:
                                    dashboardType === "pro" ? "yes" : "no",
                                isExpand: true,
                                folders: [
                                    {
                                        type: "folder",
                                        folderName: "Page",
                                        path: "src/components/StepWizards/Page",
                                        isExpand: true,
                                        folders: [],
                                        files: [
                                            {
                                                type: "file",
                                                name: "AdditionalInfo.jsx",
                                                data: files.additionalInfo,
                                                icon: "fa-brands fa-js",
                                            },
                                            {
                                                type: "file",
                                                name: "LoggedIn.jsx",
                                                data: files.loggedIn,
                                                icon: "fa-brands fa-js",
                                            },
                                            {
                                                type: "file",
                                                name: "PersonalDetails.jsx",
                                                data: files.personalDetails,
                                                icon: "fa-brands fa-js",
                                            },
                                        ],
                                    },
                                ],
                                files: [
                                    {
                                        type: "file",
                                        name: "StepCircleWizard.jsx",
                                        data: files.stepCircleWizard,
                                        icon: "fa-brands fa-js",
                                    },
                                    {
                                        type: "file",
                                        name: "StepSquarWizard.jsx",
                                        data: files.stepSquarWizard,
                                        icon: "fa-brands fa-js",
                                    },
                                    {
                                        type: "file",
                                        name: "StepTabWizard.jsx",
                                        data: files.stepTabWizard,
                                        icon: "fa-brands fa-js",
                                    },
                                ],
                            },
                            {
                                type: "folder",
                                folderName: "Switcher",
                                path: "src/components/Switcher",
                                isExpand: true,
                                folders: [
                                    {
                                        type: "folder",
                                        folderName: "ColorControl",
                                        path: "src/components/Switcher/ColorControl",
                                        isExpand: true,
                                        folders: [],
                                        files: [
                                            {
                                                type: "file",
                                                name: "ColorControl.jsx",
                                                data: files.colorControl,
                                                icon: "fa-brands fa-js",
                                            },
                                            {
                                                type: "file",
                                                name: "ColorControl.module.scss",
                                                data: files.colorControlScss,
                                                icon: "fa-brands fa-sass",
                                            },
                                        ],
                                    },
                                    {
                                        type: "folder",
                                        folderName: "FlipClock",
                                        path: "src/components/Switcher/FlipClock",
                                        isExpand: true,
                                        folders: [],
                                        files: [
                                            {
                                                type: "file",
                                                name: "FlipClock.jsx",
                                                data: files.flipClock,
                                                icon: "fa-brands fa-js",
                                            },
                                            {
                                                type: "file",
                                                name: "FlipClock.module.scss",
                                                data: files.flipClockScss,
                                                icon: "fa-brands fa-sass",
                                            },
                                        ],
                                    },
                                    {
                                        type: "folder",
                                        folderName: "hooks",
                                        path: "src/components/Switcher/hooks",
                                        isExpand: true,
                                        folders: [],
                                        files: [
                                            {
                                                type: "file",
                                                name: "useReactCounterDown.js",
                                                data: files.useReactCounterDown,
                                                icon: "fa-brands fa-js",
                                            },
                                        ],
                                    },
                                    {
                                        type: "folder",
                                        folderName: "image",
                                        path: "src/components/Switcher/image",
                                        isExpand: true,
                                        folders: [
                                            {
                                                type: "folder",
                                                folderName: "nav-type",
                                                path: "src/components/Switcher/image/nav-type",
                                                isExpand: true,
                                                folders: [],
                                                files: [
                                                    {
                                                        type: "file",
                                                        name: "combo-nav.png",
                                                        img: true,
                                                        data: nav_type1,
                                                        icon: "fa-regular fa-image",
                                                    },
                                                    {
                                                        type: "file",
                                                        name: "dual-nav.png",
                                                        img: true,
                                                        data: nav_type2,
                                                        icon: "fa-regular fa-image",
                                                    },
                                                    {
                                                        type: "file",
                                                        name: "horizontal-nav.png",
                                                        img: true,
                                                        data: nav_type3,
                                                        icon: "fa-regular fa-image",
                                                    },
                                                    {
                                                        type: "file",
                                                        name: "vertical-nav.png",
                                                        img: true,
                                                        data: nav_type4,
                                                        icon: "fa-regular fa-image",
                                                    },
                                                ],
                                            },
                                            {
                                                type: "folder",
                                                folderName: "sidebar-bg",
                                                path: "src/components/Switcher/image/sidebar-bg",
                                                isExpand: true,
                                                folders: [],
                                                files: [
                                                    {
                                                        type: "file",
                                                        name: "sidebar-bg-1.jpg",
                                                        img: true,
                                                        data: sidebarBg1,
                                                        icon: "fa-regular fa-image",
                                                    },
                                                    {
                                                        type: "file",
                                                        name: "sidebar-bg-2.jpg",
                                                        img: true,
                                                        data: sidebarBg2,
                                                        icon: "fa-regular fa-image",
                                                    },
                                                    {
                                                        type: "file",
                                                        name: "sidebar-bg-3.jpg",
                                                        img: true,
                                                        data: sidebarBg3,
                                                        icon: "fa-regular fa-image",
                                                    },
                                                    {
                                                        type: "file",
                                                        name: "sidebar-bg-4.jpg",
                                                        img: true,
                                                        data: sidebarBg4,
                                                        icon: "fa-regular fa-image",
                                                    },
                                                    {
                                                        type: "file",
                                                        name: "sidebar-bg-5.jpg",
                                                        img: true,
                                                        data: sidebarBg5,
                                                        icon: "fa-regular fa-image",
                                                    },
                                                ],
                                            },
                                        ],
                                        files: [],
                                    },
                                    {
                                        type: "folder",
                                        folderName: "NavigationType",
                                        path: "src/components/Switcher/NavigationType",
                                        isExpand: true,
                                        folders: [],
                                        files: [
                                            {
                                                type: "file",
                                                name: "NavigationType.jsx",
                                                data: files.navigationType,
                                                icon: "fa-brands fa-js",
                                            },
                                            {
                                                type: "file",
                                                name: "NavigationType.module.scss",
                                                data: files.navigationTypeScss,
                                                icon: "fa-brands fa-sass",
                                            },
                                        ],
                                    },
                                    {
                                        type: "folder",
                                        folderName: "OfferNotice",
                                        path: "src/components/Switcher/OfferNotice",
                                        isExpand: true,
                                        folders: [],
                                        files: [
                                            {
                                                type: "file",
                                                name: "OfferNotice.jsx",
                                                data: files.offerNotice,
                                                icon: "fa-brands fa-js",
                                            },
                                            {
                                                type: "file",
                                                name: "OfferNotice.module.scss",
                                                data: files.offerNoticeScss,
                                                icon: "fa-brands fa-sass",
                                            },
                                        ],
                                    },
                                    {
                                        type: "folder",
                                        folderName: "SidebarBgControl",
                                        path: "src/components/Switcher/SidebarBgControl",
                                        isExpand: true,
                                        folders: [],
                                        files: [
                                            {
                                                type: "file",
                                                name: "SidebarBgControl.jsx",
                                                data: files.sidebarBgControl,
                                                icon: "fa-brands fa-js",
                                            },
                                            {
                                                type: "file",
                                                name: "SidebarBgControl.module.scss",
                                                data: files.sidebarBgControlScss,
                                                icon: "fa-brands fa-sass",
                                            },
                                        ],
                                    },
                                    {
                                        type: "folder",
                                        folderName: "SwitchControl",
                                        path: "src/components/Switcher/SwitchControl",
                                        isExpand: true,
                                        folders: [],
                                        files: [
                                            {
                                                type: "file",
                                                name: "SwitchControl.jsx",
                                                data: files.switchControl,
                                                icon: "fa-brands fa-js",
                                            },
                                        ],
                                    },
                                ],
                                files: [
                                    {
                                        type: "file",
                                        name: "Switcher.jsx",
                                        data: files.switcher,
                                        icon: "fa-brands fa-js",
                                    },
                                    {
                                        type: "file",
                                        name: "Switcher.module.scss",
                                        data: files.switcherScss,
                                        icon: "fa-brands fa-sass",
                                    },
                                ],
                            },
                            {
                                type: "folder",
                                folderName: "Tab",
                                path: "src/components/Tab",
                                isDownload:
                                    dashboardType === "pro" ? "yes" : "no",
                                isExpand: true,
                                files: [
                                    {
                                        type: "file",
                                        name: "Tab.jsx",
                                        data: files.tab,
                                        icon: "fa-brands fa-js",
                                    },
                                ],
                                folders: [],
                            },
                            {
                                type: "folder",
                                folderName: "Timeline",
                                path: "src/components/Timeline",
                                isExpand: true,
                                files: [
                                    {
                                        type: "file",
                                        name: "Timeline.jsx",
                                        data: files.timeline,
                                        icon: "fa-brands fa-js",
                                    },
                                ],
                                folders: [],
                            },
                            {
                                type: "folder",
                                folderName: "Traffic",
                                path: "src/components/Traffic",
                                isExpand: true,
                                files: [
                                    {
                                        type: "file",
                                        name: "Traffic.jsx",
                                        data: files.traffic,
                                        icon: "fa-brands fa-js",
                                    },
                                ],
                                folders: [],
                            },
                            {
                                type: "folder",
                                folderName: "UserStats",
                                path: "src/components/UserStats",
                                isExpand: true,
                                files: [
                                    {
                                        type: "file",
                                        name: "UserStats.jsx",
                                        data: files.userStats,
                                        icon: "fa-brands fa-js",
                                    },
                                ],
                                folders: [],
                            },
                            {
                                type: "folder",
                                folderName: "WeatherStats",
                                path: "src/components/WeatherStats",
                                isExpand: true,
                                files: [
                                    {
                                        type: "file",
                                        name: "WeatherStats.jsx",
                                        data: files.weatherStats,
                                        icon: "fa-brands fa-js",
                                    },
                                ],
                                folders: [],
                            },
                        ],
                        files: [],
                    },
                    {
                        type: "folder",
                        folderName: "data",
                        path: "src/data",
                        isExpand: true,
                        folders: [],
                        files: [
                            {
                                type: "file",
                                name: "brandico.json",
                                data: files.brandico,
                                icon: "fa-solid fa-code",
                            },
                            {
                                type: "file",
                                name: "entypo.json",
                                data: files.entypo,
                                icon: "fa-solid fa-code",
                            },
                            {
                                type: "file",
                                name: "font-awesome.json",
                                data: files.fontAwesome,
                                icon: "fa-solid fa-code",
                            },
                            {
                                type: "file",
                                name: "fontelico.json",
                                data: files.fontelico,
                                icon: "fa-solid fa-code",
                            },
                            {
                                type: "file",
                                name: "tableData.js",
                                data: files.tableData,
                                icon: "fa-brands fa-js",
                            },
                        ],
                    },
                    {
                        type: "folder",
                        folderName: "context",
                        path: "src/context",
                        isExpand: true,
                        folders: [],
                        files: [
                            {
                                type: "file",
                                name: "dashboardDataContext.jsx",
                                data: files.dashboardDataContext,
                                icon: "fa-brands fa-js",
                            },
                        ],
                    },
                    {
                        type: "folder",
                        folderName: "layouts",
                        path: "src/layouts",
                        isExpand: true,
                        folders: [],
                        files: [
                            {
                                type: "file",
                                name: "Layouts.jsx",
                                data: files.layouts,
                                icon: "fa-brands fa-js",
                            },
                        ],
                    },
                    {
                        type: "folder",
                        folderName: "views",
                        path: "src/views",
                        isExpand: true,
                        folders: [],
                        files: [
                            {
                                type: "file",
                                name: "AdvancedForm.jsx",
                                data: files.advancedForm,
                                icon: "fa-brands fa-js",
                            },
                            {
                                type: "file",
                                name: "Alerts.jsx",
                                data: files.alerts,
                                icon: "fa-brands fa-js",
                            },
                            {
                                type: "file",
                                name: "ApexCharts.jsx",
                                data: files.apexChartsCode,
                                icon: "fa-brands fa-js",
                                isDownload:
                                    dashboardType === "pro" ? "yes" : "no",
                            },
                            {
                                type: "file",
                                name: "Badges.jsx",
                                data: files.badges,
                                icon: "fa-brands fa-js",
                            },
                            {
                                type: "file",
                                name: "BasicForm.jsx",
                                data: files.basicForm,
                                icon: "fa-brands fa-js",
                            },
                            {
                                type: "file",
                                name: "Buttons.jsx",
                                data: files.buttons,
                                icon: "fa-brands fa-js",
                            },
                            {
                                type: "file",
                                name: "Cards.jsx",
                                data: files.cards,
                                icon: "fa-brands fa-js",
                            },
                            {
                                type: "file",
                                name: "Chartjs.jsx",
                                data: files.chartjsCode,
                                icon: "fa-brands fa-js",
                            },
                            {
                                type: "file",
                                name: "Dashboard.jsx",
                                data: files.dashboardCode,
                                icon: "fa-brands fa-js",
                            },
                            {
                                type: "file",
                                name: "GoogleMaps.jsx",
                                data: files.googleMaps,
                                icon: "fa-brands fa-js",
                            },
                            {
                                type: "file",
                                name: "Grids.jsx",
                                data: files.grids,
                                icon: "fa-brands fa-js",
                            },
                            {
                                type: "file",
                                name: "Icons.jsx",
                                data: files.iconsCode,
                                icon: "fa-brands fa-js",
                            },
                            {
                                type: "file",
                                name: "LeafletMaps.jsx",
                                data: files.leafletMaps,
                                icon: "fa-brands fa-js",
                            },
                            {
                                type: "file",
                                name: "Login.jsx",
                                data: files.login,
                                icon: "fa-brands fa-js",
                            },
                            {
                                type: "file",
                                name: "Modals.jsx",
                                data: files.modalsCode,
                                icon: "fa-brands fa-js",
                            },
                            {
                                type: "file",
                                name: "Error404.jsx",
                                data: files.page404,
                                icon: "fa-brands fa-js",
                            },
                            {
                                type: "file",
                                name: "Error500.jsx",
                                data: files.page500,
                                icon: "fa-brands fa-js",
                            },
                            {
                                type: "file",
                                name: "ProgressBars.jsx",
                                data: files.progressBars,
                                icon: "fa-brands fa-js",
                            },
                            {
                                type: "file",
                                name: "Recharts.jsx",
                                data: files.rechartsCode,
                                icon: "fa-brands fa-js",
                            },
                            {
                                type: "file",
                                name: "Register.jsx",
                                data: files.register,
                                icon: "fa-brands fa-js",
                            },
                            {
                                type: "file",
                                name: "SocialButtons.jsx",
                                data: files.socialButtons,
                                icon: "fa-brands fa-js",
                            },
                            {
                                type: "file",
                                name: "Tables.jsx",
                                data: files.tables,
                                icon: "fa-brands fa-js",
                            },
                            {
                                type: "file",
                                name: "Tabs.jsx",
                                data: files.tabs,
                                icon: "fa-brands fa-js",
                                isDownload:
                                    dashboardType === "pro" ? "yes" : "no",
                            },
                            {
                                type: "file",
                                name: "Typography.jsx",
                                data: files.typography,
                                icon: "fa-brands fa-js",
                            },
                            {
                                type: "file",
                                name: "Widgets.jsx",
                                data: files.widgets,
                                icon: "fa-brands fa-js",
                            },
                            {
                                type: "file",
                                name: "WizardForm.jsx",
                                data: files.wizardForm,
                                icon: "fa-brands fa-js",
                                isDownload:
                                    dashboardType === "pro" ? "yes" : "no",
                            },
                        ],
                    },
                ],
                files: [
                    {
                        type: "file",
                        name: "App.jsx",
                        data: files.app,
                        icon: "fa-brands fa-js",
                    },
                    {
                        type: "file",
                        name: "main.jsx",
                        data: files.rootIndex,
                        icon: "fa-brands fa-js",
                    },
                    {
                        type: "file",
                        name: "index.scss",
                        data: files.indexScss,
                        icon: "fa-brands fa-sass",
                    },
                    {
                        type: "file",
                        name: "nav.jsx",
                        data: files.nav,
                        icon: "fa-brands fa-js",
                    },
                    {
                        type: "file",
                        name: "routes.jsx",
                        data: files.routesCode,
                        icon: "fa-brands fa-js",
                    },
                ],
            },
            {
                type: "file",
                name: ".gitignore",
                data: files.gitignore,
                icon: "fa-brands fa-git-alt",
            },
            {
                type: "file",
                name: "index.html",
                data: files.indexHtml,
                icon: "fa-brands fa-html5",
            },
            {
                type: "file",
                name: "package.json",
                data: files.packageJson,
                icon: "fa-regular fa-file",
            },
            {
                type: "file",
                name: "README.md",
                data: files.readmemd,
                icon: "fa-regular fa-file",
            },
            {
                type: "file",
                name: "vite.config.js",
                data: files.viteConfig,
                icon: "fa-regular fa-file",
            },
        ];
        setFileStructures(mainData);
    }, [
        dashboardType,
        files,
        // switch state
        isPublicOpen,
        src,
        components,
        alert,
        card,
        chart,
        apexCharts,
        dashboard,
        stats,
        form,
        stepWizards,
        page,
        icon,
        modalSwitch,
        messageSwitch,
        userProfileSwitch,
        progressBarSwitch,
        sidebarSwitch,
        tabSwitch,
        tabPageSwitch,

        tableSwitch,
        userInfoSwitch,
        layoutsSwitch,
        viewsSwitch,
        assetsSwitch,
        imgSwitch,
        avatarSwitch,
        scssSwitch,
        promoBarSwitch,
        colorControlSwitch,
        searchBarSwitch,
        // dynamic data
        // projectName,

        chartjs,

        recharts,

        dataSwitch,

        dropdownMenu,

        navbarsSwitch,

        notificationSwitch,

        contextSwitch,

        switchControl,
    ]);

    return (
        <Fragment>
            {true ? (
                <div>
                    <ul className="rag_view__tree">
                        <li>
                            <ViewTree
                                toggle={pluginNameToggole}
                                setToggle={setPluginNameToggole}
                                folderName={rootFolderName}
                                isExpand={true}
                            />
                            {pluginNameToggole ? (
                                <FileRenderer
                                    files={fileStructures}
                                    modalStates={modalStates}
                                />
                            ) : null}
                        </li>
                    </ul>
                    <button
                        onClick={() => {
                            if (!dashboardType) {
                                return window.alert(
                                    "Please select a dashboard type! (free | pro)"
                                );
                            }

                            getDownload(fileStructures, rootFolderName);
                        }}
                        className="mt-4 bg-[#007bff] text-white text-[1rem] w-full py-[6px] px-[12px] rounded-[3px]"
                    >
                        Download
                    </button>
                </div>
            ) : (
                <Fragment>
                    <p className="text-[#24263a] text-[1rem] font-normal leading-[1.5rem] text-center italic">
                        No Preview Available
                    </p>
                </Fragment>
            )}
        </Fragment>
    );
};

export default GeneratedFiles;
