import React from "react";
// Docs
import GitIgnore from "./gitignore";
import BabelConfig from "./babelConfig";
import DocusaurusConfig from "./docusaurusConfig";
import DocPackageJson from "./packageJson";
import DocReadmeMd from "./readmeMd";
import DocSidebars from "./sidebars";
import DocData from "./data/data";
import DocTableData from "./data/tableData";
import DocIndexMd from "./docs/indexMd";
import DocInstallationMDX from "./docs/installationMDX";
import DocConfigurationMDX from "./docs/configurationMDX";
import DocChartsCategoryJson from "./docs/charts/chartsCategoryJson";
import DocApexChartsMDX from "./docs/charts/apexChartsMDX";
import DocChartjsMDX from "./docs/charts/chartjsMDX";
import DocRechartsMDX from "./docs/charts/rechartsMDX";
import DocComponentsCategoryJson from "./docs/components/componentsCategoryJson";
import DocAlertMDX from "./docs/components/alertMDX";
import DocBadgeMDX from "./docs/components/badgeMDX";
import DocButtonMDX from "./docs/components/buttonMDX";
import DocCardMDX from "./docs/components/cardMDX";
import DocModalMDX from "./docs/components/modalMDX";
import DocProgressMDX from "./docs/components/progressMDX";
import DocSocialsMDX from "./docs/components/socialsMDX";
import DocTableMDX from "./docs/components/tableMDX";
import DocTabsMDX from "./docs/components/tabsMDX";
import DocTypographyMDX from "./docs/components/typographyMDX";
import DocWidgetsMDX from "./docs/components/widgetsMDX";
import DocFormsCategoryJson from "./docs/forms/formsCategoryJson";
import DocAdvancedFormMDX from "./docs/forms/advancedFormMDX";
import DocBasicFormMDX from "./docs/forms/basicFormMDX";
import DocFormWizardMDX from "./docs/forms/formWizardMDX";
import DocMapsCategoryJson from "./docs/maps/mapsCategoryJson";
import DocGoogleMapMDX from "./docs/maps/googleMapMDX";
import DocLeafletMapsMDX from "./docs/maps/leafletMapsMDX";
import DocWaitForElm from "./utils/waitForElm";
import DocNotice from "./static/notice";
import DocCustomCSS from "./src/css/customCSS";
import DocVariableSCSS from "./src/css/variableSCSS";
import DocCardJSX from "./src/components/Card/cardJSX";
import DocCardModuleSCSS from "./src/components/Card/cardModuleSCSS";
import DocApexPaiChartJSX from "./src/components/Charts/ApexPaiChart/ApexPaiChartJSX";
import DocAreaChartJSX from "./src/components/Charts/AreaChart/AreaChartJSX";
import DocAreaRechartJSX from "./src/components/Charts/AreaRechart/AreaRechartJSX";
import DocBarChartJSX from "./src/components/Charts/BarChart/BarChartJSX";
import DocBarRechartJSX from "./src/components/Charts/BarRechart/BarRechartJSX";
import DocCandlestickChartJSX from "./src/components/Charts/CandlestickChart/CandlestickChartJSX";
import DocColumnChartJSX from "./src/components/Charts/ColumnChart/ColumnChartJSX";
import DocLineChartJSX from "./src/components/Charts/LineChart/LineChartJSX";
import DocLineRechartJSX from "./src/components/Charts/LineRechart/LineRechartJSX";
import DocPaiChartJSX from "./src/components/Charts/PaiChart/PaiChartJSX";
import DocScatterRechartJSX from "./src/components/Charts/ScatterRechart/ScatterRechartJSX";
import DocCodePreviewJSX from "./src/components/CodePreview/CodePreviewJSX";
import DocFeatureTableJSX from "./src/components/FeatureTable/FeatureTableJSX";
import DocGoogleJSX from "./src/components/Maps/Google/GoogleJSX";
import DocLeafletJSX from "./src/components/Maps/Leaflet/LeafletJSX";
import DocLargeModalJSX from "./src/components/Modal/LargeModalJSX";
import DocMediumModalJSX from "./src/components/Modal/MediumModalJSX";
import DocSmallModalJSX from "./src/components/Modal/SmallModalJSX";
import DocWithoutAnimatedModalJSX from "./src/components/Modal/WithoutAnimatedModalJSX";
import DocAppJSX from "./src/components/Notice/AppJSX";
import DocFlipClockJSX from "./src/components/Notice/FlipClock/FlipClockJSX";
import DocFlipClockCSS from "./src/components/Notice/FlipClock/FlipClockCSS";
import UseReactCounterDownJS from "./src/components/Notice/hooks/useReactCounterDownJS";
import DocSocialButton from "./src/components/SocialButton/SocialButtonJSx";
import DocSocialButtonSCSS from "./src/components/SocialButton/SocialButtonSCSS";
import DocTabJSX from "./src/components/Tab/TabJSX";
import DocTabSCSS from "./src/components/Tab/TabSCSS";
import DocBasicTableJSX from "./src/components/Table/BasicTableJSX";
import DocSearchTableJSX from "./src/components/Table/SearchTableJSX";
import DocVariantsTableJSX from "./src/components/Table/VariantsTableJSX";
import DocTableSCSS from "./src/components/Table/TableSCSS";
import DocSocialCounterJSX from "./src/components/Widgets/SocialCounter/SocialCounterJSX";
import DocSocialCounterSCSS from "./src/components/Widgets/SocialCounter/SocialCounterSCSS";
import DocStasCardJSX from "./src/components/Widgets/StatsCard/StatsCardJSX";
import DocStasCardSCSS from "./src/components/Widgets/StatsCard/StatsCardSCSS";
import DocUserStatsJSX from "./src/components/Widgets/UserStats/UserStatsJSX";
import DocStepCircleWizardJSX from "./src/components/WizardForm/StepCircleWizardJSX";
import DocStepCircleWizardSCSS from "./src/components/WizardForm/StepCircleWizardSCSS";
import DocStepSquarWizardJSX from "./src/components/WizardForm/StepSquarWizardJSX";
import DocStepSquarWizardSCSS from "./src/components/WizardForm/StepSquarWizardSCSS";
import DocStepTabWizardJSX from "./src/components/WizardForm/StepTabWizardJSX";
import DocStepTabWizardSCSS from "./src/components/WizardForm/StepTabWizardSCSS";
import DocAdditionalInfoJSX from "./src/components/WizardForm/Page/AdditionalInfoJSX";
import DocLoggedInJSX from "./src/components/WizardForm/Page/LoggedInJSX";
import DocPersonalDetails from "./src/components/WizardForm/Page/PersonalDetailsJSX";
import DocProgressBarJSX from "./src/components/ProgressBar/ProgressBarJSX";
import DocProgressBarSCSS from "./src/components/ProgressBar/ProgressBarSCSS";
import DocOfferNoticeJSX from "./src/components/Notice/OfferNotice/OfferNoticeJSX";
import DocOfferNoticeCSS from "./src/components/Notice/OfferNotice/OfferNoticeCSS";

function DocsProjectFiles() {
    return (
        <>
            {/* root */}
            <GitIgnore />
            <BabelConfig />
            <DocusaurusConfig />
            <DocPackageJson />
            <DocReadmeMd />
            <DocSidebars />
            {/* data */}
            <DocData />
            <DocTableData />
            {/* docs */}
            <DocIndexMd />
            <DocInstallationMDX />
            <DocConfigurationMDX />
            {/* docs > charts */}
            <DocChartsCategoryJson />
            <DocApexChartsMDX />
            <DocChartjsMDX />
            <DocRechartsMDX />
            {/* docs > components */}
            <DocComponentsCategoryJson />
            <DocAlertMDX />
            <DocBadgeMDX />
            <DocButtonMDX />
            <DocCardMDX />
            <DocModalMDX />
            <DocProgressMDX />
            <DocSocialsMDX />
            <DocTableMDX />
            <DocTabsMDX />
            <DocTypographyMDX />
            <DocWidgetsMDX />
            {/* docs > forms */}
            <DocFormsCategoryJson />
            <DocAdvancedFormMDX />
            <DocBasicFormMDX />
            <DocFormWizardMDX />
            {/* docs > maps */}
            <DocMapsCategoryJson />
            <DocGoogleMapMDX />
            <DocLeafletMapsMDX />
            {/* docs > utils */}
            <DocWaitForElm />
            {/* docs > static */}
            <DocNotice />
            {/* docs > src > css */}
            <DocCustomCSS />
            <DocVariableSCSS />
            {/* docs > src > components > Card */}
            <DocCardJSX />
            <DocCardModuleSCSS />
            {/* docs > src > components > Charts > ApexPaiChart */}
            <DocApexPaiChartJSX />
            {/* docs > src > components > Charts > AreaChart */}
            <DocAreaChartJSX />
            {/* docs > src > components > Charts > AreaRechart */}
            <DocAreaRechartJSX />
            {/* docs > src > components > Charts > BarChart */}
            <DocBarChartJSX />
            {/* docs > src > components > Charts > BarRechart */}
            <DocBarRechartJSX />
            {/* docs > src > components > Charts > CandlestickChart */}
            <DocCandlestickChartJSX />
            {/* docs > src > components > Charts > ColumnChart */}
            <DocColumnChartJSX />
            {/* docs > src > components > Charts > LineChart */}
            <DocLineChartJSX />
            {/* docs > src > components > Charts > LineRechart */}
            <DocLineRechartJSX />
            {/* docs > src > components > Charts > PaiChart */}
            <DocPaiChartJSX />
            {/* docs > src > components > Charts > ScatterRechart */}
            <DocScatterRechartJSX />
            {/* docs > src > components > CodePreview */}
            <DocCodePreviewJSX />
            {/* docs > src > components > FeatureTable */}
            <DocFeatureTableJSX />
            {/* docs > src > components > Maps > Google */}
            <DocGoogleJSX />
            {/* docs > src > components > Maps > Leaflet */}
            <DocLeafletJSX />
            {/* docs > src > components > Modal */}
            <DocLargeModalJSX />
            <DocMediumModalJSX />
            <DocSmallModalJSX />
            <DocWithoutAnimatedModalJSX />
            {/* docs > src > components > Notice */}
            <DocAppJSX />
            {/* docs > src > components > Notice > OfferNotice */}
            <DocOfferNoticeJSX />
            <DocOfferNoticeCSS />
            {/* docs > src > components > Notice > FlipClock */}
            <DocFlipClockJSX />
            <DocFlipClockCSS />
            {/* docs > src > components > Notice > hooks */}
            <UseReactCounterDownJS />
            {/* docs > src > components > ProgressBar */}
            <DocProgressBarJSX />
            <DocProgressBarSCSS />
            {/* docs > src > components > SocialButton */}
            <DocSocialButton />
            <DocSocialButtonSCSS />
            {/* docs > src > components > Tab */}
            <DocTabJSX />
            <DocTabSCSS />
            {/* docs > src > components > Table */}
            <DocBasicTableJSX />
            <DocSearchTableJSX />
            <DocVariantsTableJSX />
            <DocTableSCSS />
            {/* docs > src > components > Widgets > SocialCounter */}
            <DocSocialCounterJSX />
            <DocSocialCounterSCSS />
            {/* docs > src > components > Widgets > StasCard */}
            <DocStasCardJSX />
            <DocStasCardSCSS />
            {/* docs > src > components > Widgets > UserStats */}
            <DocUserStatsJSX />
            {/* docs > src > components > WizardForm */}
            <DocStepCircleWizardJSX />
            <DocStepCircleWizardSCSS />
            <DocStepSquarWizardJSX />
            <DocStepSquarWizardSCSS />
            <DocStepTabWizardJSX />
            <DocStepTabWizardSCSS />
            {/* docs > src > components > WizardForm > Page */}
            <DocAdditionalInfoJSX />
            <DocLoggedInJSX />
            <DocPersonalDetails />
        </>
    );
}

export default DocsProjectFiles;
