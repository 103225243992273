import React, { Fragment } from "react";

const AddFile = ({ addItem = "", setAddItem = "" }) => {
    const updateDependencies = (value, index, handle) => {
        if (!!value) {
            let newItems = [...addItem];
            newItems[index][handle] = value;

            return setAddItem(newItems);
        }
    };

    const removeItem = (id) => {
        const updateItem = addItem.filter((item, ind) => {
            return ind !== id;
        });

        setAddItem(updateItem);
    };

    return (
        <Fragment>
            {addItem?.map((item, index) => {
                return (
                    <div className="flex items-center justify-between mt-4" key={index}>
                        <div className="grid grid-cols-2 gap-4 w-[92%]">
                            <div>
                                <label
                                    htmlFor={item?.packageName}
                                    className="block text-[#24263a] text-xs leading-[15px] font-bold">
                                    Package Name
                                </label>
                                <input
                                    type="text"
                                    id={item?.packageName}
                                    defaultValue={item?.packageName}
                                    placeholder="Package Name"
                                    className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e] lowercase"
                                    onBlur={(e) =>
                                        updateDependencies(e.target.value, index, "packageName")
                                    }
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor={item?.version}
                                    className="block text-[#24263a] text-xs leading-[15px] font-bold">
                                    version
                                </label>
                                <input
                                    type="text"
                                    id={item?.version}
                                    defaultValue={item?.version}
                                    placeholder="version"
                                    className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                                    onBlur={(e) =>
                                        updateDependencies(e.target.value, index, "version")
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex items-center justify-center mt-4">
                            <button
                                onClick={() => removeItem(index)}
                                className="w-12 h-12 bg-red-200 text-red-600 rounded-full">
                                <i className="fa-solid fa-trash" />
                            </button>
                        </div>
                    </div>
                );
            })}
            <div className="mt-5 flex items-center justify-end">
                <button
                    className="bg-[#5966d2] py-2 px-3 text-[0.75rem] text-[#fff] font-bold  rounded"
                    onClick={() =>
                        setAddItem([
                            ...addItem,
                            {
                                packageName: "",
                                version: "",
                            },
                        ])
                    }>
                    ADD
                </button>
            </div>
        </Fragment>
    );
};

export default AddFile;
