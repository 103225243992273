import React, { Fragment } from "react";
import PackageJson from "./package.json";
import Gitignore from "./Gitignore";
import Readme from "./Readme";
import ViteConfig from "./ViteConfig";
import IndexHtml from "./IndexHtml";
import AnalyticsStats from "./src/components/AnalyticsStats/AnalyticsStats";
import App from "./src/App";
import NotificationStatsCart from "./src/components/NotificationStatsCart/NotificationStatsCart";
import OverlayCard from "./src/components/OverlayCard/OverlayCard";
import ProfileCard from "./src/components/ProfileCard/ProfileCard";
import WidgetsCard from "./src/components/Card/WidgetsCard";
import BarChart from "./src/components/Chartjs/BarChart";
import DoughnutChart from "./src/components/Chartjs/DoughnutChart";
import LineChartCjs from "./src/components/Chartjs/LineChart";
import PieChartCJs from "./src/components/Chartjs/PieChartCJs";
import PolarAreaChart from "./src/components/Chartjs/PolarAreaChart";
import RadarChart from "./src/components/Chartjs/RadarChart";
import AreaRechart from "./src/components/Recharts/AreaRechart";
import BarRechart from "./src/components/Recharts/BarRechart";
import LineRechart from "./src/components/Recharts/LineRechart";
import PieRechart from "./src/components/Recharts/PieRechart";
import RechartRadialBar from "./src/components/Recharts/RechartRadialBar";
import RechartScatter from "./src/components/Recharts/RechartScatter";
import Day from "./src/components/Stats/Day";
import Month from "./src/components/Stats/Month";
import Stats from "./src/components/Stats/Stats";
import Year from "./src/components/Stats/Year";
import Download from "./src/components/Download/Download";
import Earning from "./src/components/Earning/Earning";
import MonthlyStats from "./src/components/MonthlyStats/MonthlyStats";
import Revenue from "./src/components/Revenue/Revenue";
import SocialCounter from "./src/components/SocialCounter/SocialCounter";
import StatsCard from "./src/components/StatsCard/StatsCard";
import Timeline from "./src/components/Timeline/Timeline";
import Traffic from "./src/components/Traffic/Traffic";
import UserStats from "./src/components/UserStats/UserStats";
import WeatherStats from "./src/components/WeatherStats/WeatherStats";
import DropdownMenu from "./src/components/DropdownMenu/DropdownMenu";
import AdditionalInfo from "./src/components/StepWizards/Page/AdditionalInfo";
import LoggedIn from "./src/components/StepWizards/Page/LoggedIn";
import PersonalDetails from "./src/components/StepWizards/Page/PersonalDetails";
import StepCircleWizard from "./src/components/StepWizards/StepCircleWizard";
import StepSquarWizard from "./src/components/StepWizards/StepSquarWizard";
import StepTabWizard from "./src/components/StepWizards/StepTabWizard";
import Brandico from "./src/components/Icon/Brandico";
import Entypo from "./src/components/Icon/Entypo";
import FontAwesome from "./src/components/Icon/FontAwesome";
import Fontelico from "./src/components/Icon/Fontelico";
import Iconviewer from "./src/components/Icon/Iconviewer";
import MessageCode from "./src/components/Navbars/Message/MessageCode";
import NotificationCode from "./src/components/Navbars/Notification/Notification";
import UserProfile from "./src/components/Navbars/UserProfile/UserProfile";
import Tab from "./src/components/Tab/Tab";
import AdvancedForm from "./src/views/AdvancedForm";
import Alerts from "./src/views/Alerts";
import ApexCharts from "./src/views/ApexCharts";
import Badges from "./src/views/Badges";
import BasicForm from "./src/views/BasicForm";
import Buttons from "./src/views/Buttons";
import Cards from "./src/views/Cards";
import Chartjs from "./src/views/Chartjs";
import Dashboard from "./src/views/Dashboard";
import GoogleMaps from "./src/views/GoogleMaps";
import Grids from "./src/views/Grids";
import Icons from "./src/views/Icons";
import LeafletMaps from "./src/views/LeafletMaps";
import Login from "./src/views/Login";
import Modals from "./src/views/Modals";
import Page404 from "./src/views/Page404";
import Page500 from "./src/views/Page500";
import ProgressBarsCode from "./src/views/ProgressBarsCode";
import Recharts from "./src/views/Recharts";
import Register from "./src/views/Register";
import SocialButtons from "./src/views/SocialButtons";
import Tables from "./src/views/Tables";
import Tabs from "./src/views/Tabs";
import Typography from "./src/views/Typography";
import Widgets from "./src/views/Widgets";
import WizardForm from "./src/views/WizardForm";
import RootIndex from "./src";
import IndexScss from "./src/index.scss";
import Nav from "./src/Nav";
import RoutesCode from "./src/RoutesCode";
import EarningScss from "./src/assets/scss/earning.scss";
import IconStyleScss from "./src/assets/scss/icon-style.scss";
import MessageScss from "./src/assets/scss/message.scss";
import NotificationScss from "./src/assets/scss/notification.scss";
import OverlayCardScss from "./src/assets/scss/overlay-card.scss";
import SocialButtonsScss from "./src/assets/scss/social-buttons.scss";
import StatsCardScss from "./src/assets/scss/stats-card.scss";
import TablesScss from "./src/assets/scss/tables.scss";
import TabsScss from "./src/assets/scss/tabs.scss";
import TimelineScss from "./src/assets/scss/timeline.scss";
import TypographyScss from "./src/assets/scss/typography.scss";
import UesrProfileScss from "./src/assets/scss/user-profile.scss";
import Switcher from "./src/components/Switcher/Switcher";
import SearchBarScss from "./src/assets/scss/search-bar.scss";
import SearchBar from "./src/components/SearchBar/SearchBar";
import DashboardDataContext from "./src/context/DashboardDataContext";
import SwitcherScss from "./src/components/Switcher/SwitcherScss";
import DarkModeSwitch from "./src/components/DarkModeSwitch/DarkModeSwitch";
import Authentication from "./src/assets/scss/authentication";
import DarkModeSwitchScss from "./src/assets/scss/dark-mode-switch";
import GridsScss from "./src/assets/scss/grids";
import ProfileCardScss from "./src/assets/scss/profile-card";
import StatsScss from "./src/assets/scss/stats";
import StepSquarWizardScss from "./src/assets/scss/step-squar-wizard";
import StepCircleWizardScss from "./src/assets/scss/step-crcle-wizard";
import StepTabWizardScss from "./src/assets/scss/step-tab-wizard";
import TrafficScss from "./src/assets/scss/traffic";
import WeatherStatsScss from "./src/assets/scss/weather-stats";
import ColorControl from "./src/components/Switcher/ColorControl/ColorControl";
import ColorControlScss from "./src/components/Switcher/ColorControl/ColorControlScss";
import FlipClock from "./src/components/Switcher/FlipClock/FlipClock";
import FlipClockScss from "./src/components/Switcher/FlipClock/FlipClockScss";
import UseReactCounterDown from "./src/components/Switcher/hooks/UseReactCounterDown";
import NavigationType from "./src/components/Switcher/NavigationType/NavigationType";
import NavigationTypeScss from "./src/components/Switcher/NavigationType/NavigationTypeScss";
import OfferNotice from "./src/components/Switcher/OfferNotice/OfferNotice";
import OfferNoticeScss from "./src/components/Switcher/OfferNotice/OfferNoticeScss";
import SidebarBgControl from "./src/components/Switcher/SidebarBgControl/SidebarBgControl";
import SidebarBgControlScss from "./src/components/Switcher/SidebarBgControl/SidebarBgControlScss";
import SwitchControl from "./src/components/Switcher/SwitchControl/SwitchControl";
import Redirects from "../../../public/Redirects";
import NotificationStatsCartScss from "./src/assets/scss/notification-stats-cart";
import CopyButton from "./src/components/Icon/CopyButton";
import DarkMatter from "./src/components/LeafletMaps/DarkMatter";
import StreetMap from "./src/components/LeafletMaps/StreetMap";
import Voyager from "./src/components/LeafletMaps/Voyager";
import WorldImagery from "./src/components/LeafletMaps/WorldImagery";
import ComboScss from "./src/assets/scss/navbars/ComboScss";
import DualNavScss from "./src/assets/scss/navbars/DualNavScss";
import HorizontalScss from "./src/assets/scss/navbars/horizontalScss";
import AnalyticsStatsScss from "./src/assets/scss/analytics-stats-scss";
import CardScss from "./src/assets/scss/cardscss";
import DeviceVisitorStatsScss from "./src/assets/scss/device-visitor-stats";
import Layouts from "./src/Layouts/Layouts";
import LayoutsScss from "./src/assets/scss/layouts-scss";
import MonthlyStatsScss from "./src/assets/scss/monthly-stats-scss";
import NavbarBgWrapperScss from "./src/assets/scss/navbar-bg-wrapper-scss";
import SidebarScss from "./src/assets/scss/sidebar-scss";
import SidebarBgWrapperScss from "./src/assets/scss/sidebar-bg-wrapper-scss";
import SocialCounterScss from "./src/assets/scss/social-counter-scss";
import SocialStatsScss from "./src/assets/scss/social-stats-scss";
import VerticalScss from "./src/assets/scss/navbars/verticalScss";
import CardContent from "./src/components/Card/CardContent";
import AreaChart from "./src/components/ApexCharts/AreaChart";
import BubbleChart from "./src/components/ApexCharts/BubbleChart";
import CandlestickChart from "./src/components/ApexCharts/CandlestickChart";
import ColumnChart from "./src/components/ApexCharts/ColumnChart";
import PieChart from "./src/components/ApexCharts/PieChart";
import BarChartApex from "./src/components/ApexCharts/BarChartApex";
import BrandicoCode from "./src/data/BrandicoCode";
import EntypoCode from "./src/data/EntypoCode";
import FontAwesomeCode from "./src/data/FontAwesomeCode";
import FontelicoCode from "./src/data/FontelicoCode";
import TableData from "./src/data/TableData";
import NavBarBgWrapper from "./src/components/Navbars/NavBarBgWrapper";
import Navbars from "./src/components/Navbars/Navbars";
import Combo from "./src/components/Navbars/NavbarType/Combo";
import DualTopNav from "./src/components/Navbars/NavbarType/DualTopNav";
import HorizontalNav from "./src/components/Navbars/NavbarType/HorizontalNav";
import VerticalNav from "./src/components/Navbars/NavbarType/VerticalNav";
import RealTime from "./src/components/RealTime/RealTime";
import Sidebar from "./src/components/Sidebar/Sidebar";
import SidebarBgWrapper from "./src/components/Sidebar/SidebarBgWrapper";
import SidebarMenu from "./src/components/Sidebar/SidebarMenu";
import SocialButton from "./src/components/SocialButton/SocialButton";
import SocialStats from "./src/components/SocialStats/SocialStats";
import DocsProjectFiles from "./docs";
import VariableScss from "./src/assets/scss/variable.scss";
import DeviceVisitorStats from "./src/components/DeviceVisitorStats/DeviceVisitorStats";
import BootstrapProductAdminDemoYML from "./github/workflows/bootstrapProductAdminDemoYML";
import Funding from "./github/funding";

const ProjectFiles = () => {
    return (
        <Fragment>
            <IndexHtml />
            <PackageJson />
            <Gitignore />
            <Readme />
            <ViteConfig />
            {/* public */}
            <Redirects />
            {/* src files */}
            <App />
            <AnalyticsStats />
            <CardContent />
            <NotificationStatsCart />
            <NotificationStatsCartScss />
            <OverlayCard />
            <ProfileCard />
            <WidgetsCard />

            {/* Apex chart */}
            <AreaChart />
            <BarChartApex />
            <BubbleChart />
            <CandlestickChart />
            <ColumnChart />
            <PieChart />
            <BarChart />
            <DoughnutChart />
            <LineChartCjs />
            <PieChartCJs />
            <PolarAreaChart />
            <RadarChart />
            <AreaRechart />
            <BarRechart />
            <LineRechart />
            <PieRechart />
            <RechartRadialBar />
            <RechartScatter />
            <Day />
            <Month />
            <Stats />
            <Year />
            <Download />
            <Earning />
            <MonthlyStats />
            <Revenue />
            <VariableScss />
            <SocialCounter />
            <SocialCounterScss />
            <StatsCard />
            <Timeline />
            <Traffic />
            <UserStats />
            <WeatherStats />
            <BrandicoCode />
            <EntypoCode />
            <FontAwesomeCode />
            <CopyButton />
            <FontelicoCode />
            <TableData />
            <DropdownMenu />
            <DeviceVisitorStats />
            <AdditionalInfo />
            <LoggedIn />
            <PersonalDetails />
            <StepCircleWizard />
            <StepSquarWizard />
            <StepTabWizard />
            <Brandico />
            <Entypo />
            <FontAwesome />
            <Fontelico />
            <Iconviewer />
            <MessageCode />
            <NotificationCode />
            <UserProfile />
            <Tab />
            <SocialStats />
            <AdvancedForm />
            <Alerts />
            <ApexCharts />
            <Badges />
            <BasicForm />
            <Buttons />
            <Cards />
            <Chartjs />
            <Dashboard />
            <GoogleMaps />
            <Grids />
            <Icons />
            <LeafletMaps />
            <DarkMatter />
            <StreetMap />
            <Voyager />
            <WorldImagery />
            <Login />
            <Modals />
            <Page404 />
            <Page500 />
            <ProgressBarsCode />
            <Recharts />
            <Register />
            <SocialButtons />
            <SocialButton />
            <Tables />
            <Tabs />
            <Typography />
            <Widgets />
            <WizardForm />
            <RootIndex />
            <IndexScss />
            <Nav />
            <RoutesCode />
            <CardScss />
            <EarningScss />
            <DeviceVisitorStatsScss />
            <IconStyleScss />
            <MessageScss />
            <NotificationScss />
            <OverlayCardScss />
            <SocialButtonsScss />
            <StatsCardScss />
            <TablesScss />
            <TabsScss />
            <TimelineScss />
            <TypographyScss />
            <UesrProfileScss />
            <SearchBarScss />
            <SearchBar />
            <DarkModeSwitch />
            <DarkModeSwitchScss />
            <Authentication />
            <GridsScss />
            <ProfileCardScss />
            <StatsScss />
            <StepCircleWizardScss />
            <StepSquarWizardScss />
            <StepTabWizardScss />
            <TrafficScss />
            <WeatherStatsScss />
            <AnalyticsStatsScss />
            <MonthlyStatsScss />
            <SocialStatsScss />
            <RealTime />

            {/* layouts type */}
            <Layouts />
            <LayoutsScss />

            {/* sidebar */}
            <Sidebar />
            <SidebarScss />
            <SidebarBgWrapper />
            <SidebarBgWrapperScss />
            <SidebarMenu />

            {/* navbar type */}
            <Navbars />
            <NavBarBgWrapper />
            <Combo />
            <DualTopNav />
            <HorizontalNav />
            <VerticalNav />
            <ComboScss />
            <DualNavScss />
            <HorizontalScss />
            <VerticalScss />
            <NavbarBgWrapperScss />

            <DashboardDataContext />
            {/* Switcher */}
            <Switcher />
            <SwitcherScss />
            <ColorControl />
            <ColorControlScss />
            <FlipClock />
            <FlipClockScss />
            <UseReactCounterDown />
            <NavigationType />
            <NavigationTypeScss />
            <OfferNotice />
            <OfferNoticeScss />
            <SidebarBgControl />
            <SidebarBgControlScss />
            <SwitchControl />
            {/* Docs Files */}
            <DocsProjectFiles />

            {/* Github */}
            <BootstrapProductAdminDemoYML />
            <Funding />
        </Fragment>
    );
};

export default ProjectFiles;
