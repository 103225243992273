import { useEffect } from "react";
import { useFileStore } from "../../../../store/useFileStore";

const NavbarBgWrapperScss = () => {
    const [_, setStore] = useFileStore((store) => store.navbarBgWrapperScss);

    useEffect(() => {
        let value = `@import "./variable.scss";
.navbar_bg {
    height: 80px;
    border-bottom: 1px solid var(--border-color);
    &.fixed_top {
        position: fixed;
        top: 0;
        width: -webkit-fill-available;
        z-index: 999999;
    }
    &[nav-type="combo"] {
        position: fixed !important;
        top: 0;
        width: -webkit-fill-available;
        z-index: 999999;
    }
    &[data-bg-color="white"] {
        background-color: #fff;
    }
    &[data-bg-color="black"] {
        background-color: #292b35;
    }
    &[data-bg-color="red"] {
        background-color: #f44336;
    }
}

body[data-theme="dark"] {
    .navbar_bg {
        background-color: var(--bg-content) !important;
    }
}
`;
        setStore({ navbarBgWrapperScss: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default NavbarBgWrapperScss;
