import { useEffect } from "react";
import { useFileStore } from "../../../store/useFileStore";

const DocTableData = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store);

    useEffect(() => {
        let value = `export const tableData = [
    {
        name: "Matthew McCormick",
        email: "matthew30@mail.ol",
        city: "Vancouver",
        score: 93,
    },
    {
        name: "Nancy Bo",
        email: "nancy@boonweb.com",
        city: "Washington",
        score: 280,
    },
    {
        name: "Frederiko Lopez",
        email: "fr.lopez@webmail.sp",
        city: "Barcelona",
        score: 16,
    },
    {
        name: "Stanley Hummer",
        email: "mr_winner_2999@gmail.cb",
        city: "Manchester",
        score: 57,
        tableBg: "table-danger",
        statusIcon: "fa-solid fa-skull-crossbones text-danger",
    },
    {
        name: "Lendley Wintz",
        email: "9938198146@mailster.io",
        city: "Wien",
        score: 113,
        tableBg: "table-success",
        statusIcon: "fa fa-check text-success",
    },
    {
        name: "Barbara Noz",
        email: "barbaranoz@mailster.io",
        city: "Brussels",
        score: 68,
        tableBg: "table-warning",
        statusIcon: "fa fa-exclamation-triangle  text-warning",
    },
    {
        name: "Matthew McCormick",
        email: "matthew30@mail.ol",
        city: "Vancouver",
        score: 93,
    },
    {
        name: "Nancy Bo",
        email: "nancy@boonweb.com",
        city: "Washington",
        score: 280,
        tableBg: "table-info",
        statusIcon: "fa-solid fa-circle-exclamation text-info",
    },
    {
        name: "Matthew McCormick",
        email: "matthew30@mail.ol",
        city: "Vancouver",
        score: 93,
    },
    {
        name: "Nancy Bo",
        email: "nancy@boonweb.com",
        city: "Washington",
        score: 280,
    },
    {
        name: "Frederiko Lopez",
        email: "fr.lopez@webmail.sp",
        city: "Barcelona",
        score: 16,
    },
    {
        name: "Stanley Hummer",
        email: "mr_winner_2999@gmail.cb",
        city: "Manchester",
        score: 57,
    },
    {
        name: "Lendley Wintz",
        email: "9938198146@mailster.io",
        city: "Wien",
        score: 113,
    },
    {
        name: "Barbara Noz",
        email: "barbaranoz@mailster.io",
        city: "Brussels",
        score: 68,
    },
    {
        name: "Matthew McCormick",
        email: "matthew30@mail.ol",
        city: "Vancouver",
        score: 93,
    },
    {
        name: "Nancy Bo",
        email: "nancy@boonweb.com",
        city: "Washington",
        score: 280,
    },
    {
        name: "Matthew McCormick",
        email: "matthew30@mail.ol",
        city: "Vancouver",
        score: 93,
    },
    {
        name: "Nancy Bo",
        email: "nancy@boonweb.com",
        city: "Washington",
        score: 280,
    },
    {
        name: "Frederiko Lopez",
        email: "fr.lopez@webmail.sp",
        city: "Barcelona",
        score: 16,
    },
    {
        name: "Stanley Hummer",
        email: "mr_winner_2999@gmail.cb",
        city: "Manchester",
        score: 57,
    },
    {
        name: "Lendley Wintz",
        email: "9938198146@mailster.io",
        city: "Wien",
        score: 113,
    },
    {
        name: "Barbara Noz",
        email: "barbaranoz@mailster.io",
        city: "Brussels",
        score: 68,
    },
    {
        name: "Matthew McCormick",
        email: "matthew30@mail.ol",
        city: "Vancouver",
        score: 93,
    },
    {
        name: "Nancy Bo",
        email: "nancy@boonweb.com",
        city: "Washington",
        score: 280,
    },
    {
        name: "Matthew McCormick",
        email: "matthew30@mail.ol",
        city: "Vancouver",
        score: 93,
    },
    {
        name: "Nancy Bo",
        email: "nancy@boonweb.com",
        city: "Washington",
        score: 280,
    },
    {
        name: "Frederiko Lopez",
        email: "fr.lopez@webmail.sp",
        city: "Barcelona",
        score: 16,
    },
    {
        name: "Stanley Hummer",
        email: "mr_winner_2999@gmail.cb",
        city: "Manchester",
        score: 57,
    },
    {
        name: "Lendley Wintz",
        email: "9938198146@mailster.io",
        city: "Wien",
        score: 113,
    },
    {
        name: "Barbara Noz",
        email: "barbaranoz@mailster.io",
        city: "Brussels",
        score: 68,
    },
    {
        name: "Matthew McCormick",
        email: "matthew30@mail.ol",
        city: "Vancouver",
        score: 93,
    },
    {
        name: "Nancy Bo",
        email: "nancy@boonweb.com",
        city: "Washington",
        score: 280,
    },
    {
        name: "Matthew McCormick",
        email: "matthew30@mail.ol",
        city: "Vancouver",
        score: 93,
    },
    {
        name: "Nancy Bo",
        email: "nancy@boonweb.com",
        city: "Washington",
        score: 280,
    },
    {
        name: "Frederiko Lopez",
        email: "fr.lopez@webmail.sp",
        city: "Barcelona",
        score: 16,
    },
    {
        name: "Stanley Hummer",
        email: "mr_winner_2999@gmail.cb",
        city: "Manchester",
        score: 57,
    },
    {
        name: "Lendley Wintz",
        email: "9938198146@mailster.io",
        city: "Wien",
        score: 113,
    },
    {
        name: "Barbara Noz",
        email: "barbaranoz@mailster.io",
        city: "Brussels",
        score: 68,
    },
    {
        name: "Matthew McCormick",
        email: "matthew30@mail.ol",
        city: "Vancouver",
        score: 93,
    },
    {
        name: "Nancy Bo",
        email: "nancy@boonweb.com",
        city: "Washington",
        score: 280,
    },
    {
        name: "Matthew McCormick",
        email: "matthew30@mail.ol",
        city: "Vancouver",
        score: 93,
    },
    {
        name: "Nancy Bo",
        email: "nancy@boonweb.com",
        city: "Washington",
        score: 280,
    },
    {
        name: "Frederiko Lopez",
        email: "fr.lopez@webmail.sp",
        city: "Barcelona",
        score: 16,
    },
    {
        name: "Stanley Hummer",
        email: "mr_winner_2999@gmail.cb",
        city: "Manchester",
        score: 57,
    },
    {
        name: "Lendley Wintz",
        email: "9938198146@mailster.io",
        city: "Wien",
        score: 113,
    },
    {
        name: "Barbara Noz",
        email: "barbaranoz@mailster.io",
        city: "Brussels",
        score: 68,
    },
    {
        name: "Matthew McCormick",
        email: "matthew30@mail.ol",
        city: "Vancouver",
        score: 93,
    },
    {
        name: "Nancy Bo",
        email: "nancy@boonweb.com",
        city: "Washington",
        score: 280,
    },
    {
        name: "Nancy Bo",
        email: "nancy@boonweb.com",
        city: "Washington",
        score: 280,
    },
    {
        name: "Matthew McCormick",
        email: "matthew30@mail.ol",
        city: "Vancouver",
        score: 93,
    },
];`;

        setStore({ docTableData: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default DocTableData;
