import { createContext, useContext, useSyncExternalStore } from "react";

export const GlobalStoreContext = createContext(null);

export const useGlobalStore = (selector) => {
    const store = useContext(GlobalStoreContext);
    if (!store) {
        throw "Error";
    }

    const state = useSyncExternalStore(store.subscribe, () => selector(store.get()));
    return [state, store.set];
};
