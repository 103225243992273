import { useEffect } from "react";
import { useFileStore } from "../../store/useFileStore";

const RootIndex = () => {
    const [_, setStore] = useFileStore((store) => store.rootIndex);
    useEffect(() => {
        let value = `import App from "./App";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "./index.scss";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);
`;
        setStore({ rootIndex: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return null;
};

export default RootIndex;
