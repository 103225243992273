import { useEffect } from "react";
import { useFileStore } from "../../../../store/useFileStore";

const DocAlertMDX = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store);

    useEffect(() => {
        let value = `---
sidebar_position: 1
sidebar_label: Alert
---

# Alert

The Alert component displays a message or feedback to the user, usually in response to an action or an event. The Alert component can have different severity levels, such as \`success\`, \`info\`, \`warning\`, or \`error\`, and can be styled accordingly. The Alert component can also be dismissed by the user or automatically after a certain time.

You can find the Alerts component in the \`src/views/Alerts.jsx\` file.

import { Alert, Badge } from "react-bootstrap";
import FeatureTable from "@site/src/components/FeatureTable/FeatureTable";
import CodePreview from "@site/src/components/CodePreview/CodePreview";

import Tabs from '@theme/Tabs';
import TabItem from '@theme/TabItem';

## Examples

Alerts are available for any length of text, as well as an optional dismiss button. For proper styling, use one of the eight variants. Alert [Preview](https://demo.reactadmin.com/bootstrap/basix-admin/components/alerts)

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <Alert variant="primary" dismissible>This is a primary alert—check it out!</Alert>
            <Alert variant="secondary" dismissible>This is a secondary alert—check it out!</Alert>
            <Alert variant="success" dismissible>This is a success alert—check it out!</Alert>
        </CodePreview>  
    </TabItem>

    <TabItem value="code" label="Code">
        \`\`\`jsx
        import Alert from "react-bootstrap/Alert";

        function MyComponent() {
            return (
                <>
                    <Alert variant="primary" dismissible>This is a primary alert—check it out!</Alert>
                    <Alert variant="secondary" dismissible>This is a secondary alert—check it out!</Alert>
                    <Alert variant="success" dismissible>This is a success alert—check it out!</Alert>
                </>
            );
        }

        export default MyComponent;
        \`\`\`
    </TabItem>
</Tabs>

<br/>
### Link

For links, use the \`<Alert.Link>\` component to provide matching colored links within any alert. Alert Link [Preview](https://demo.reactadmin.com/bootstrap/basix-admin/components/alerts)

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <Alert variant="primary">This is a primary alert with <Alert.Link target="_blank" href="https://demo.reactadmin.com/bootstrap/basix-admin/components/alerts">an example link</Alert.Link>. Give it a click if you like.</Alert>
            <Alert variant="secondary">This is a secondary alert with <Alert.Link target="_blank" href="https://demo.reactadmin.com/bootstrap/basix-admin/components/alerts">an example link</Alert.Link>. Give it a click if you like.</Alert>
            <Alert variant="success">This is a success alert with <Alert.Link target="_blank" href="https://demo.reactadmin.com/bootstrap/basix-admin/components/alerts">an example link</Alert.Link>. Give it a click if you like.</Alert>
        </CodePreview>
    </TabItem>
    
    <TabItem value="code" label="Code">

        \`\`\`jsx title="Alerts"
        import Alert from "react-bootstrap/Alert";

        function MyComponent() {
            return (
                <>
                    <Alert variant="primary">
                        This is a primary alert with 
                        <Alert.Link target="_blank" href="https://demo.reactadmin.com/bootstrap/basix-admin/components/alerts">an example link</Alert.Link>. 
                        Give it a click if you like.
                    </Alert>
                    <Alert variant="secondary">
                        This is a secondary alert with
                        <Alert.Link target="_blank" href="https://demo.reactadmin.com/bootstrap/basix-admin/components/alerts">an example link</Alert.Link>.
                        Give it a click if you like.
                    </Alert>
                    <Alert variant="success">
                        This is a success alert with 
                        <Alert.Link target="_blank" href="https://demo.reactadmin.com/bootstrap/basix-admin/components/alerts">an example link</Alert.Link>.
                        Give it a click if you like.
                    </Alert>
                </>
            );
        }

        export default MyComponent;
        \`\`\`
    </TabItem>
</Tabs>

<br/>
### With Badge
Alerts can contain another component also like \`<Badge>\`. Alert [Preview](https://demo.reactadmin.com/bootstrap/basix-admin/components/alerts)

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <Alert variant="success"><Badge bg="success">Success</Badge> You successfully read this important alert.</Alert>
            <Alert variant="primary"> <Badge bg="primary">Primary</Badge> You successfully read this important alert.</Alert>
            <Alert variant="secondary"><Badge bg="secondary">Secondary</Badge> You successfully read this important alert.</Alert>
        </CodePreview>
    </TabItem>
    
    <TabItem value="code" label="Code">

        \`\`\`jsx title="Alerts"
        import { Alert, Badge } from "react-bootstrap";

        function MyComponent() {
            return (
                <>
                    <Alert variant="success">
                        <Badge bg="success">Success</Badge> 
                        You successfully read this important alert.
                    </Alert>
                    <Alert variant="primary">
                        <Badge bg="primary">Primary</Badge>
                        You successfully read this important alert.
                    </Alert>
                    <Alert variant="secondary">
                        <Badge bg="secondary">Secondary</Badge>
                        You successfully read this important alert.
                    </Alert>
                </>
            );
        }

        export default MyComponent;
        \`\`\`
    </TabItem>
</Tabs>

<br/>
### Additional content
Alerts can contain whatever content you like. Headers, paragraphs, dividers, go crazy. Alert [Preview](https://demo.reactadmin.com/bootstrap/basix-admin/components/alerts)

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <Alert variant="success" dismissible>
                <Alert.Heading>Well done!</Alert.Heading>
                <p>
                You successfully read this important alert message. This example text is going to run a bit longer so that you can see alert content.
                </p>
                <hr />
            <p>Whenever you need to, be sure to use margin utilities nice and tidy.</p>
            </Alert>
        </CodePreview>
    </TabItem>
    
    <TabItem value="code" label="Code">

        \`\`\`jsx title="Alerts"
        import Alert from "react-bootstrap/Alert";

        function MyComponent() {
            return (
                <Alert variant="success" dismissible>
                    <Alert.Heading>Well done!</Alert.Heading>
                    <p>
                    You successfully read this important alert message. This example text is going to run a bit longer so that you can see alert content.
                    </p>
                    <hr />
                    <p>Whenever you need to, be sure to use margin utilities nice and tidy.</p>
                </Alert>
            );
        }

        export default MyComponent;
        \`\`\`
    </TabItem>
</Tabs>

## Props

<FeatureTable>
    <tr>
        <td>variant</td>
        <td>\`primary\`, \`secondary\`, \`success\`, 
       \`danger\`, \`warning\`, \`info\`,
        \`dark\`, \`light\`</td>
        <td></td>
        <td>A string that specifies the color scheme of the alert. You can choose from eight options: \`primary\`, \`secondary\`, \`success\`, \`danger\`, \`warning\`, \`info\`, \`dark\`, or \`light\`. The default value is \`primary\`.</td>
    </tr>
    <tr>
        <td>dismissible</td>
        <td>boolean</td>
        <td></td>
        <td>A boolean that indicates whether the alert can be closed by the user. If \`dismissible\` is \`true\`, a close button will appear on the right side of the alert. If \`dismissible\` is \`false\`, the alert will not have a close button. The default value is \`false\`.</td>
    </tr>
    <tr>
        <td>show</td>
        <td>boolean</td>
        <td></td>
        <td>A boolean that indicates whether the alert is visible or not. If \`show\` is \`true\`, the alert will be rendered on the screen. If \`show\` is \`false\`, the alert will be hidden. The default value is \`true\`.</td>
    </tr>
    <tr>
        <td>onClose</td>
        <td>func</td>
        <td></td>
        <td> A function that is called when the alert is closed by the user or by the timeout. The function receives the event object as the argument. You can use this prop to perform any action or logic when the alert is closed. For example, you can use onClose to update the state of the parent component or to trigger another alert. The default value is null.</td>
    </tr>
</FeatureTable>

For more props please visit to <a href="https://react-bootstrap.netlify.app/docs/components/alerts" target="_blank">react-bootstrap</a>
`;

        setStore({ docAlertMDX: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default DocAlertMDX;
