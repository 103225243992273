import { useEffect } from "react";
import { useFileStore } from "../../../../store/useFileStore";
const CopyButton = () => {
    const [_, setStore] = useFileStore((store) => store.copyButton);

    useEffect(() => {
        let value = `const CopyButton = ({ copy, setCopy, iconsName, copyId }) => {
    const handelCopy = (e) => {
        navigator.clipboard.writeText(e);
    };

    return (
        <button
            type="button"
            onClick={() => {
                handelCopy(iconsName);
                setCopy(copyId);
            }}
        >
            {copy === copyId ? (
                <i className="fa-solid fa-check"></i>
            ) : (
                <i className="fa-regular fa-copy"></i>
            )}
        </button>
    );
};

export default CopyButton;
`;
        setStore({ copyButton: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return null;
};

export default CopyButton;
