import { useEffect } from "react";
import { useFileStore } from "../../store/useFileStore";

const DocPackageJson = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store);

    useEffect(() => {
        let value = `{
    "name": "docs",
    "version": "0.0.0",
    "private": true,
    "scripts": {
        "docusaurus": "docusaurus",
        "start": "docusaurus start",
        "build": "docusaurus build",
        "swizzle": "docusaurus swizzle",
        "deploy": "docusaurus deploy",
        "clear": "docusaurus clear",
        "serve": "docusaurus serve",
        "write-translations": "docusaurus write-translations",
        "write-heading-ids": "docusaurus write-heading-ids"
    },
    "dependencies": {
        "@docusaurus/core": "3.1.1",
        "@docusaurus/preset-classic": "3.1.1",
        "@fortawesome/fontawesome-free": "^6.5.1",
        "@mdx-js/react": "^3.0.0",
        "@react-google-maps/api": "^2.19.3",
        "bootstrap": "^5.3.2",
        "chart.js": "^4.4.1",
        "classnames": "^2.5.1",
        "clsx": "^2.0.0",
        "css-loader": "^6.10.0",
        "docusaurus-lunr-search": "^3.3.2",
        "docusaurus-plugin-sass": "^0.2.5",
        "font-awesome": "^4.7.0",
        "prism-react-renderer": "^2.3.0",
        "react": "^18.0.0",
        "react-apexcharts": "^1.4.1",
        "react-bootstrap": "^2.10.1",
        "react-chartjs-2": "^5.2.0",
        "react-countup": "^6.5.0",
        "react-dom": "^18.0.0",
        "react-leaflet": "^4.2.1",
        "recharts": "^2.12.0",
        "sass": "^1.70.0",
        "styled": "^1.0.0",
        "styled-components": "^6.1.8"
    },
    "devDependencies": {
        "@docusaurus/module-type-aliases": "3.1.1",
        "@docusaurus/types": "3.1.1"
    },
    "browserslist": {
        "production": [
        ">0.5%",
        "not dead",
        "not op_mini all"
        ],
        "development": [
        "last 3 chrome version",
        "last 3 firefox version",
        "last 5 safari version"
        ]
    },
    "engines": {
        "node": ">=18.0"
    }
}`;

        setStore({ docPackageJson: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default DocPackageJson;
