import { useEffect } from "react";
import { useFileStore } from "../../../../store/useFileStore";

const MessageScss = () => {
    const [_, setStore] = useFileStore((store) => store.messageScss);
    useEffect(() => {
        let value = `@import "../scss/variable.scss";

.message_menu {
    width: max-content;
    padding-bottom: 8px;
    background-color: var(--bg-content);
    .message_counter {
        font-size: 15px;
        font-family: Open Sans, sans-serif;
        font-weight: 400;
        line-height: 24px;
        color: var(--content-text-color);
        border: 1px solid var(--border-color);
        padding: 5px 15px;
    }
    .message_body {
        padding: 10px 15px 3px;
        .user_img {
            width: 25px;
            display: block;
            img {
                width: 100%;
            }
        }
        .message {
            width: 100%;
            span {
                color: $light;
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
            }
            p {
                color: $light;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
            }
        }
    }

    .bg_1 {
        background-color: $periwinkle;
    }
    .bg_2 {
        background-color: $valentine-red;
    }
    .bg_3 {
        background-color: $mint;
    }
    .bg_4 {
        background-color: $warning;
    }
}
`;
        setStore({ messageScss: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return null;
};

export default MessageScss;
