import { useEffect } from "react";
import { useFileStore } from "../../../../store/useFileStore";

const DocProgressMDX = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store);

    useEffect(() => {
        let value = `---
sidebar_position: 5
sidebar_label: Progress
---

# Progress

The Progress component shows the completion or status of a task or an operation. The Progress component can have different type like success, danger, warning, info and different modes, such as animated, striped. The Progress component can also have labels, colors.

You can find the progressBars component in the \`src/views/progressBars.jsx\` file.

import FeatureTable from "@site/src/components/FeatureTable/FeatureTable";
import CodePreview from "@site/src/components/CodePreview/CodePreview";
import ProgressBar from "react-bootstrap/ProgressBar";
import Tabs from '@theme/Tabs';
import TabItem from '@theme/TabItem';

## Examples
Provide up-to-date feedback on the progress of a workflow with simple yet flexible progress bars. Progress Bar [Preview](https://demo.reactadmin.com/bootstrap/basix-admin/components/progressbars)

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <div className="d-flex flex-column gap-2">
                <ProgressBar variant="success" now={25} label="25" striped />
                <ProgressBar variant="info" now={50} label="50" striped />
                <ProgressBar variant="warning" now={75} label="75" striped />
                <ProgressBar variant="danger" now={100} label="100" striped />
            </div>
        </CodePreview>
    </TabItem>
    
    <TabItem value="code" label="Code">

        \`\`\`jsx title="Progress Bar"
        import ProgressBar from "react-bootstrap/ProgressBar";

        function MyComponent() {
            return (
                <>
                    <ProgressBar variant="success" now={25} label="25" striped />
                    <ProgressBar variant="info" now={50} label="50" striped />
                    <ProgressBar variant="warning" now={75} label="75" striped />
                    <ProgressBar variant="danger" now={100} label="100" striped />
                </>
            );
        }

        export default MyComponent;
        \`\`\`
    </TabItem>
</Tabs>

<br/>
### Animated Progress
Add animated prop to animate the stripes right to left. Progress Bar [Preview](https://demo.reactadmin.com/bootstrap/basix-admin/components/progressbars)

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <div className="d-flex flex-column gap-2">
                <ProgressBar variant="success" now={25} label="25" striped animated />
                <ProgressBar variant="info" now={50} label="50" striped animated />
                <ProgressBar variant="warning" now={75} label="75" striped animated />
                <ProgressBar variant="danger" now={100} label="100" striped animated />
            </div>
        </CodePreview>
    </TabItem>
    
    <TabItem value="code" label="Code">

        \`\`\`jsx title="Progress Bar"
        import ProgressBar from "react-bootstrap/ProgressBar";

        function MyComponent() {
            return (
                <>
                    <ProgressBar
                        label="25"
                        variant="success"
                        now={25}
                        striped
                        Animated
                    />
                    <ProgressBar 
                        label="50"
                        variant="info"
                        value="50"
                        isStriped={true}
                        isAnimated={true} />
                    <ProgressBar
                        label="75"
                        variant="warning"
                        value="75"
                        isStriped={true}
                        isAnimated={true}
                    />
                    <ProgressBar
                        label="100"
                        variant="danger"
                        value="100"
                        isStriped={true}
                        isAnimated={true}
                    />
                </>
            );
        }

        export default MyComponent;
        \`\`\`
    </TabItem>
</Tabs>

### Stacked
Nest \`<ProgressBar />\`s to stack them. Progress Bar [Preview](https://demo.reactadmin.com/bootstrap/basix-admin/components/progressbars)

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <div className="d-flex flex-column gap-2">
                <ProgressBar>
                    <ProgressBar striped variant="success" now={30} key={1} />
                    <ProgressBar variant="warning" now={16} key={2} />
                    <ProgressBar striped variant="danger" now={22} key={3} />
                </ProgressBar>
            </div>
        </CodePreview>
    </TabItem>
    
    <TabItem value="code" label="Code">
        \`\`\`jsx title="Progress Bar"
        import ProgressBar from "react-bootstrap/ProgressBar";

        function MyComponent() {
            return (
                <ProgressBar>
                    <ProgressBar striped variant="success" now={30} key={1} />
                    <ProgressBar variant="warning" now={16} key={2} />
                    <ProgressBar striped variant="danger" now={22} key={3} />
                </ProgressBar>
            );
        }

        export default MyComponent;
        \`\`\`
    </TabItem>
</Tabs>

## Props

<FeatureTable>
    <tr>
        <td>variant</td>
        <td>\`success\`, \`danger\`, \`warning\`, \`info\`</td>
        <td></td>
        <td>A string that specifies the color of the progress bar. You can choose from four options: \`success\`, \`danger\`, \`warning\`, or \`info\`.</td>
    </tr>
    <tr>
        <td>now</td>
        <td>number</td>
        <td></td>
        <td>A number that specifies the current value of the progress. You can use any positive number between \`0\` and \`100\`.</td>
    </tr>
    <tr>
        <td>label</td>
        <td>node</td>
        <td></td>
        <td>A node that specifies the content of the label. You can use any valid React element, such as a \`text\`, an \`icon\`, or a \`custom component\`.</td>
    </tr>
    <tr>
        <td>striped</td>
        <td>boolean</td>
        <td></td>
        <td>A boolean that indicates whether the progress bar has a \`striped\` pattern or not. If \`striped\` is \`true\`, the progress bar will have a \`striped\` effect that adds more contrast and texture. If \`striped\` is \`false\`, the progress bar will have a solid color.</td>
    </tr>
    <tr>
        <td>animated</td>
        <td>boolean</td>
        <td></td>
        <td>A boolean that indicates whether the progress bar has an animation or not. If \`animated\` is \`true\`, the progress bar will have a smooth transition that creates a sense of motion and activity. If \`animated\` is \`false\`, the progress bar will have a static appearance.</td>
    </tr>

</FeatureTable>

For more props please visit to <a href="https://react-bootstrap.netlify.app/docs/components/progress/#api" target="_blank">react-bootstrap</a>
`;

        setStore({ docProgressMDX: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default DocProgressMDX;
