import { useEffect } from "react";
import { useDynamicDashboardData } from "../context/dashboardDataContext";
import { getSlug } from "../../../../../utils/getSlug";
import { getKeywords } from "../../../../../utils/getKeywords";
import { useFileStore } from "../store/useFileStore";

const PackageJson = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.packageJson);
    const {
        projectInfo,
        dependencies,
        devDependencies,
        addDevDependencies,
        addDependencies,
    } = useDynamicDashboardData();
    const { projectName, version, description, keywords, dashboardType } =
        projectInfo;

    useEffect(() => {
        let value = `{
    "name": "${getSlug(projectName, "-")}",
    "private": true,
    "version": "${version}",
    "type": "module",
    "build": "babel src -d lib",
    "test": "jest",
    "keywords": [${getKeywords(keywords)}],
    "scripts": {
        "dev": "vite",
        "build": "vite build",
        "lint": "eslint . --ext js,jsx --report-unused-disable-directives --max-warnings 0",
        "preview": "vite preview"
    },
    "dependencies": {
        "@entypo-icons/react": "^1.0.4",
        "@entypo-icons/utils": "^1.0.3",
        "@iconify/react": "^4.1.1",
        "@react-google-maps/api": "^2.19.2",
        "apexcharts": "^3.46.0",
        "bootstrap": "^5.3.2",
        "chart.js": "^4.4.1",
        "classnames": "^2.5.1",
        "leaflet": "^1.9.4",
        "node-sass": "^9.0.0",
        "peity-react": "^0.0.7",
        "react": "^18.2.0",
        "react-apexcharts": "^1.4.1",
        "react-bootstrap": "^2.9.2",
        "react-chartjs-2": "^5.2.0",
        "react-countup": "^6.5.0",
        "react-dom": "^18.2.0",
        "react-leaflet": "^4.2.1",
        "react-router-dom": "^6.21.1",
        "react-select": "^5.8.0",
        "recharts": "^2.10.3",
        "sass": "^1.69.7",
        "styled-components": "^6.1.8"
        ${
            addDependencies &&
            addDependencies.map((item) => {
                return `"${item?.packageName}": "${item?.version}"\n`;
            })
        }
    },
    "devDependencies": {
        "@types/react": "^18.2.43",
        "@types/react-dom": "^18.2.17",
        "@vitejs/plugin-react": "^4.2.1",
        "eslint": "^8.55.0",
        "eslint-plugin-react": "^7.33.2",
        "eslint-plugin-react-hooks": "^4.6.0",
        "eslint-plugin-react-refresh": "^0.4.5",
        "vite": "^5.0.8"
        ${
            addDevDependencies &&
            addDevDependencies.map((item) => {
                return `"${item?.packageName}": "${item?.version}"\n`;
            })
        }
    }
}`;

        setStore({ packageJson: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        projectName,
        version,
        description,
        dependencies,
        devDependencies,
        addDevDependencies,
        addDependencies,
        keywords,
    ]);

    return null;
};

export default PackageJson;
