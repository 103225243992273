import { useEffect } from "react";
import { useFileStore } from "../../../../store/useFileStore";

const DocApexChartsMDX = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store);

    useEffect(() => {
        let value = `---
sidebar_position: 2
sidebar_label: Apex Charts
---

# Apex Charts

Apex Charts is a modern charting library that helps developers to create stunning and responsive charts for their web applications. Apex Charts is built with SVG elements and offers a wide range of chart types, such as area, column, bar, line, scatter, heatmap, radar, radial, and funnel. Apex Charts also features advanced functionalities, such as validation, calculation, file upload, progress bar, date picker, color picker, range slider, and more. Apex Charts also supports multiple steps or tabs to break down a long or complex form into smaller and easier sections.

You can find the ApexCharts component in the \`src/views/ApexCharts.jsx\` file

import CodePreview from "@site/src/components/CodePreview/CodePreview";
import AreaChart from "@site/src/components/Charts/AreaChart/AreaChart";
import CandlestickChart from "@site/src/components/Charts/CandlestickChart/CandlestickChart";
import ColumnChart from "@site/src/components/Charts/ColumnChart/ColumnChart";
import ApexPaiChart from "@site/src/components/Charts/ApexPaiChart/ApexPaiChart";
import Tabs from '@theme/Tabs';
import TabItem from '@theme/TabItem';

## Examples

## Area Chart
Area Charts are used to represent quantitative variations. Be it examining the variation in the population of a tribe or determining the average performance of the students. Area charts differ from line charts because the area bounded by the plotted data points is filled with shades or colors. Apex Chart [Preview](https://demo.reactadmin.com/bootstrap/basix-admin/components/apex-charts)

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <AreaChart />
        </CodePreview>
    </TabItem>
    
    <TabItem value="code" label="Code">
        \`\`\`jsx title="Area Chart"
        import { useState } from "react";
        import ReactApexChart from "react-apexcharts";
        import Card from "@/components/Card/Card";
        import { CardBody } from "react-bootstrap";

        const AreaChart = () => {
            const [state, setState] = useState({
                series: [
                    {
                        name: "XYZ MOTORS",
                        data: [
                            [1327359600000, 30.95],
                            [1327446000000, 31.34],
                            [1327532400000, 31.18],
                            ...
                        ],
                    },
                ],
                options: {
                    chart: {
                        type: "area",
                        stacked: false,
                        height: 400,
                        zoom: {
                            type: "x",
                            enabled: true,
                            autoScaleYaxis: true,
                        },
                        toolbar: {
                            autoSelected: "zoom",
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    markers: {
                        size: 0,
                    },
                    fill: {
                        type: "gradient",
                        gradient: {
                            shadeIntensity: 1,
                            inverseColors: false,
                            opacityFrom: 0.5,
                            opacityTo: 0,
                            stops: [0, 90, 100],
                        },
                    },
                    yaxis: {
                        labels: {
                            formatter: function (val) {
                                return (val / 1000000).toFixed(0);
                            },
                        },
                    },
                    xaxis: {
                        type: "datetime",
                    },
                    tooltip: {
                        shared: false,
                        y: {
                            formatter: function (val) {
                                return (val / 1000000).toFixed(0);
                            },
                        },
                    },
                },
            });
            return (
                <Card title="Area Chart">
                    <CardBody>
                        <div className="d-flex justify-content-center align-items-center overflow-hidden">
                            <ReactApexChart
                                options={state.options}
                                series={state.series}
                                type="area"
                                height={300}
                                style={{ width: "100%" }}
                            />
                        </div>
                    </CardBody>
                </Card>
            );
        };

        export default AreaChart;
        \`\`\`
    </TabItem>
</Tabs>

<br />
## Candlestick Chart
A Candlestick Chart (or Candle Chart) is a type of financial chart that describes the price changes of a security, derivative, or currency. Candlestick graphs and charts usually represent data as numerical values in vertical axes and compare the price movements across time, visualizing the open, high, low, and close values. Apex Chart [Preview](https://demo.reactadmin.com/bootstrap/basix-admin/components/apex-charts)

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <CandlestickChart />
        </CodePreview>
    </TabItem>
    
    <TabItem value="code" label="Code">
        \`\`\`jsx title="Candlestick Chart"
        import { useState } from "react";
        import ReactApexChart from "react-apexcharts";
        import Card from "@/components/Card/Card";
        import { CardBody } from "react-bootstrap";

        const CandlestickChart = () => {
            const [state, setState] = useState({
                series: [
                    {
                        data: [
                            {
                                x: new Date(1538778600000),
                                y: [6629.81, 6650.5, 6623.04, 6633.33],
                            },
                            {
                                x: new Date(1538780400000),
                                y: [6632.01, 6643.59, 6620, 6630.11],
                            },
                            ...
                        ],
                    },
                ],
                options: {
                    chart: {
                        type: "candlestick",
                        height: 350,
                    },
                    xaxis: {
                        type: "datetime",
                    },
                    yaxis: {
                        tooltip: {
                            enabled: true,
                        },
                    },
                },
            });
            return (
                <Card title="Candlestick Chart">
                    <CardBody>
                        <div className="d-flex justify-content-center align-items-center overflow-hidden">
                            <ReactApexChart
                                options={state.options}
                                series={state.series}
                                type="candlestick"
                                height={300}
                                style={{ width: "100%" }}
                            />
                        </div>
                    </CardBody>
                </Card>
            );
        };

        export default CandlestickChart;
        \`\`\`
    </TabItem>
</Tabs>

<br/>
## Column Chart
A Column Chart is a vertical graphical representation of different data categories. It visualizes measured values in rectangular columns or bars plotted along two axes. In general, Column Graphs and Charts are generally used for displaying statistical comparisons between categories of data over time. Apex Chart [Preview](https://demo.reactadmin.com/bootstrap/basix-admin/components/apex-charts)

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <ColumnChart />
        </CodePreview>
    </TabItem>
    
    <TabItem value="code" label="Code">
        \`\`\`jsx title="Column Chart"
        import { useState } from "react";
        import ReactApexChart from "react-apexcharts";
        import Card from "@/components/Card/Card";
        import { CardBody } from "react-bootstrap";

        const ColumnChart = () => {
            const [state, setState] = useState({
                series: [
                    {
                        name: "Inflation",
                        data: [
                            2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2,
                        ],
                    },
                ],
                options: {
                    chart: {
                        height: 350,
                        type: "bar",
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 10,
                            dataLabels: {
                                position: "top", // top, center, bottom
                            },
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        formatter: function (val) {
                            return val + "%";
                        },
                        offsetY: -20,
                        style: {
                            fontSize: "12px",
                            colors: ["#304758"],
                        },
                    },

                    xaxis: {
                        categories: [
                            "Jan",
                            "Feb",
                            "Mar",
                            "Apr",
                            "May",
                            "Jun",
                            "Jul",
                            "Aug",
                            "Sep",
                            "Oct",
                            "Nov",
                            "Dec",
                        ],
                        position: "top",
                        axisBorder: {
                            show: false,
                        },
                        axisTicks: {
                            show: false,
                        },
                        crosshairs: {
                            fill: {
                                type: "gradient",
                                gradient: {
                                    colorFrom: "#D8E3F0",
                                    colorTo: "#BED1E6",
                                    stops: [0, 100],
                                    opacityFrom: 0.4,
                                    opacityTo: 0.5,
                                },
                            },
                        },
                        tooltip: {
                            enabled: true,
                        },
                    },
                    yaxis: {
                        axisBorder: {
                            show: false,
                        },
                        axisTicks: {
                            show: false,
                        },
                        labels: {
                            show: false,
                            formatter: function (val) {
                                return val + "%";
                            },
                        },
                    },
                    title: {
                        text: "Monthly Inflation in Argentina, 2002",
                        floating: true,
                        offsetY: 330,
                        align: "center",
                        style: {
                            color: "#444",
                        },
                    },
                },
            });
            return (
                <Card title="Column Chart">
                    <CardBody>
                        <div className="d-flex justify-content-center align-items-center overflow-hidden">
                            <ReactApexChart
                                options={state.options}
                                series={state.series}
                                type="bar"
                                height={300}
                                style={{ width: "100%" }}
                            />
                        </div>
                    </CardBody>
                </Card>
            );
        };

        export default ColumnChart;
        \`\`\`
    </TabItem>
</Tabs>

<br/>
## Pai Chart
Pie Charts and Donut Charts are instrumental visualization tools useful in expressing data and information in terms of percentages, ratios. A pie chart is most effective when dealing with a small collection of data. 

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <ApexPaiChart />
        </CodePreview>
    </TabItem>
    
    <TabItem value="code" label="Code">
        \`\`\`jsx title="Pai Chart"
        import { useState } from "react";
        import ReactApexChart from "react-apexcharts";
        import Card from "@/components/Card/Card";
        import { CardBody } from "react-bootstrap";

        const PieChart = () => {
            const [state, setState] = useState({
                series: [44, 55, 41, 17, 15],
                options: {
                    chart: {
                        type: "donut",
                    },
                    labels: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
                    responsive: [
                        {
                            breakpoint: 480,
                            options: {
                                chart: {
                                    width: 200,
                                },
                                legend: {
                                    position: "bottom",
                                },
                            },
                        },
                    ],
                },
            });
            return (
                <Card title="Pie Chart">
                    <CardBody>
                        <ReactApexChart
                            options={state.options}
                            series={state.series}
                            type="donut"
                            height={300}
                            style={{ width: "100%" }}
                        />
                    </CardBody>
                </Card>
            );
        };

        export default PieChart;
        \`\`\`
    </TabItem>
</Tabs>

For more information please visit [chartjs](https://apexcharts.com/docs/chart-types/line-chart/)
`;

        setStore({ docApexChartsMDX: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default DocApexChartsMDX;
