const FileView = ({
    fileName = "demo.txt",
    icon = "fa-brands fa-css3",
    data = "",
    modalStates = {},
}) => {
    return (
        <div
            // className="ml-8"
            onClick={() => {
                modalStates.setIsOpenModal(true);
                modalStates.setFileInfo(data);
                modalStates.setFileName(fileName);
            }}
        >
            <i className={`text-[12px] ${icon}`} />
            <span className="text-[13px] select-none ml-[6px]">{fileName}</span>
        </div>
    );
};

export default FileView;
