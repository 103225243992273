import { useEffect } from "react";
import { useFileStore } from "../../../../store/useFileStore";

const DocGoogleMapMDX = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store);

    useEffect(() => {
        let value = `---
sidebar_position: 1
sidebar_label: Google Map
---

# Google Map

Google Maps is a web mapping service that provides high-quality and detailed maps, satellite imagery, street view, directions, and more. Google Maps also offers various APIs and libraries that allow you to customize and enhance your maps with markers, shapes, layers, controls, and events.
To use Google Maps in your React application, you need to integrate the Google Maps API and obtain an API key. You can follow the steps in the official documentation (https://developers.google.com/maps/) to get started.

You can find the Google Maps components in the \`src/views/GoogleMaps.jsx\` file.

import CodePreview from "@site/src/components/CodePreview/CodePreview";
import Google from "@site/src/components/Maps/Google/Google";
import Tabs from '@theme/Tabs';
import TabItem from '@theme/TabItem';

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <Google />
        </CodePreview>
    </TabItem>
    
    <TabItem value="code" label="Code">
        \`\`\`jsx title="Google Map"
        import React from 'react'
        import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';

        const libraries = ['places'];

        const mapContainerStyle = {
            width: '100%',
            height: '350px',
        };

        const center = {
            lat: 7.2905715, // default latitude
            lng: 80.6337262, // default longitude
        };

        const API_KEY = 'AI******************************-NTU'

        function MyComponent() {
            const { isLoaded, loadError } = useLoadScript({
                googleMapsApiKey: API_KEY,
                libraries,
            });
            
            if (loadError) {
                return <div>Error loading maps</div>;
            }
            
            if (!isLoaded) {
                return <div>Loading maps</div>;
            }

            return (
                <div>
                    <GoogleMap
                        mapContainerStyle={mapContainerStyle}
                        zoom={10}
                        center={center}
                    >
                        <Marker position={center} />
                    </GoogleMap>
                </div>
            )
        }

        export default MyComponent;
        \`\`\`
    </TabItem>
</Tabs>

For more  information please visit [Google Map Package](https://www.npmjs.com/package/@react-google-maps/api).
`;

        setStore({ docGoogleMapMDX: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default DocGoogleMapMDX;
