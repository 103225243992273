import { useEffect } from "react";
import { useFileStore } from "../../../../store/useFileStore";

const ProfileCardScss = () => {
    const [_, setStore] = useFileStore((store) => store.profileCardScss);

    useEffect(() => {
        let value = `.profile_card {
    padding: 30px 16px;
    img {
        width: 156px;
    }
    .title {
        font-size: 28px;
        color: var(--hedging-text-color);
    }
    span {
        color: var(--content-text-color);
    }
    hr {
        background-color: var(--border-color);
        height: 1px;
        opacity: 1;
        margin-bottom: 0;
    }
    p {
        color: var(--content-text-color);
    }
    .card_footer {
        padding: 20px;
        border: 0 !important;
        background-color: var(--bg-content);
    }
}
`;
        setStore({ profileCardScss: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return null;
};

export default ProfileCardScss;
