import { createContext, useContext, useState } from "react";

export const DashboardCodebaseContext = createContext();

export const CodebaseContextProvider = ({ children }) => {
    // codebase
    const [indexHtml, setIndexHtml] = useState();
    const [packageJson, setPackageJson] = useState();
    const [gitignore, setGitignore] = useState();
    const [eslintrcCjs, setEslintrcCjs] = useState();
    const [readmemd, setReadmemd] = useState();
    const [viteConfig, setViteConfig] = useState();

    // public
    const [redirects, setRedirects] = useState();

    // src files
    const [app, setApp] = useState();
    const [alertCode, setAlertCode] = useState();
    const [cardContent, setCardContent] = useState();
    const [notificationStatsCart, setNotificationStatsCart] = useState();
    const [overlayCard, setOverlayCard] = useState();
    const [profileCard, setProfileCard] = useState();
    const [widgetsCard, setWidgetsCard] = useState();
    const [areaChart, setAreaChart] = useState();
    const [bubbleChart, setBubbleChart] = useState();
    const [candlestickChart, setCandlestickChart] = useState();
    const [columnChart, setColumnChart] = useState();
    const [lineChart, setLineChart] = useState();
    const [pieChart, setPieChart] = useState();
    const [barChart, setBarChart] = useState();
    const [doughnutChart, setDoughnutChart] = useState();
    const [lineChartCJs, setLineChartCJs] = useState();
    const [pieChartCJs, setPieChartCJs] = useState();
    const [polarAreaChart, setPolarAreaChart] = useState();
    const [radarChart, setRadarChart] = useState();
    const [areaRechart, setAreaRechart] = useState();
    const [barRechart, setBarRechart] = useState();
    const [lineRechart, setLineRechart] = useState();
    const [pieRechart, setPieRechart] = useState();
    const [rechartRadialBar, setRechartRadialBar] = useState();
    const [rechartScatter, setRechartScatter] = useState();
    const [analyticsInfo, setAnalyticsInfo] = useState();
    const [deviceVisitorStats, setDeviceVisitorStats] = useState();
    const [monthlyStatsChart, setMonthlyStatsChart] = useState();
    const [day, setDay] = useState();
    const [month, setMonth] = useState();
    const [statsCodeBase, setStatsCodeBase] = useState();
    const [year, setYear] = useState();
    const [analyticsStatsCodeBase, setAnalyticsStatsCodeBase] = useState();
    const [download, setDownload] = useState();
    const [earning, setEarning] = useState();
    const [monthlyStats, setMonthlyStats] = useState();
    const [revenue, setRevenue] = useState();
    const [socialCounter, setSocialCounter] = useState();
    const [statsCard, setStatsCard] = useState();
    const [timeline, setTimeline] = useState();
    const [areaChartApx, setAreaChartApx] = useState();
    const [traffic, setTraffic] = useState();
    const [userStats, setUserStats] = useState();
    const [weatherStats, setWeatherStats] = useState();
    const [brandico, setBrandico] = useState();
    const [entypo, setEntypo] = useState();
    const [fontAwesome, setFontAwesome] = useState();
    const [fontelico, setFontelico] = useState();
    const [tableData, setTableData] = useState();
    const [dropdownMenuCode, setDropdownMenuCode] = useState();
    const [additionalInfo, setAdditionalInfo] = useState();
    const [loggedIn, setLoggedIn] = useState();
    const [personalDetails, setPersonalDetails] = useState();
    const [stepCircleWizard, setStepCircleWizard] = useState();
    const [stepSquarWizard, setStepSquarWizard] = useState();
    const [stepTabWizard, setStepTabWizard] = useState();
    const [basicElementForm, setBasicElementForm] = useState();
    const [companyForm, setCompanyForm] = useState();
    const [creditCardForm, setCreditCardForm] = useState();
    const [horizontalForm, setHorizontalForm] = useState();
    const [maskedInput, setMaskedInput] = useState();
    const [multipleTagSelect, setMultipleTagSelect] = useState();
    const [stackedForm, setStackedForm] = useState();
    const [widgetsForm, setWidgetsForm] = useState();
    const [brandicoCode, setBrandicoCode] = useState();
    const [entypoCode, setEntypoCode] = useState();
    const [fontAwesomeCode, setFontAwesomeCode] = useState();
    const [copyButton, setCopyButton] = useState();
    const [fontelicoCode, setFontelicoCode] = useState();
    const [iconviewer, setIconviewer] = useState();
    const [modal, setModal] = useState();
    const [messageCode, setMessageCode] = useState();
    const [notificationCode, setNotificationCode] = useState();
    const [userProfile, setUserProfile] = useState();
    const [progressBar, setProgressBar] = useState();
    const [tab, setTab] = useState();
    const [tabOne, setTabOne] = useState();
    const [tabTwo, setTabTwo] = useState();
    const [tabThree, setTabThree] = useState();
    const [table, settable] = useState();
    const [userInfo, setUserInfo] = useState();
    const [advancedForm, setAdvancedForm] = useState();
    const [alerts, setAlerts] = useState();
    const [apexChartsCode, setApexChartsCode] = useState();
    const [badges, setBadges] = useState();
    const [basicForm, setBasicForm] = useState();
    const [buttons, setButtons] = useState();
    const [cards, setCards] = useState();
    const [chartjsCode, setChartjsCode] = useState();
    const [dashboardCode, setDashboardCode] = useState();
    const [googleMaps, setGoogleMaps] = useState();
    const [grids, setGrids] = useState();
    const [iconsCode, setIconsCode] = useState();
    const [leafletMaps, setLeafletMaps] = useState();
    const [darkMatter, setDarkMatter] = useState();
    const [streetMap, setStreetMap] = useState();
    const [voyager, setVoyager] = useState();
    const [worldImagery, setWorldImagery] = useState();
    const [login, setLogin] = useState();
    const [modalsCode, setModalsCode] = useState();
    const [page404, setPage404] = useState();
    const [page500, setPage500] = useState();
    const [progressBars, setProgressBars] = useState();
    const [rechartsCode, setRechartsCode] = useState();
    const [register, setRegister] = useState();
    const [socialButtons, setSocialButtons] = useState();
    const [tables, setTables] = useState();
    const [tabs, setTabs] = useState();
    const [typography, setTypography] = useState();
    const [widgets, setWidgets] = useState();
    const [wizardForm, setWizardForm] = useState();
    const [rootIndex, setRootIndex] = useState();
    const [indexScss, setIndexScss] = useState();
    const [nav, setNav] = useState();
    const [routes, setRoutes] = useState();
    const [cardContentScss, setCardContentScss] = useState();
    const [earningScss, setEarningScss] = useState();
    const [iconStyleScss, setIconStyleScss] = useState();
    const [messageScss, setMessageScss] = useState();
    const [notificationScss, setNotificationScss] = useState();
    const [overlayCardScss, setOverlayCardScss] = useState();
    const [progressBarScss, setProgressBarScss] = useState();
    const [socialButtonsScss, setSocialButtonsScss] = useState();
    const [statsCardScss, setStatsCardScss] = useState();
    const [tablesScss, setTablesScss] = useState();
    const [tabsScss, setTabsScss] = useState();
    const [timelineScss, setTimelineScss] = useState();
    const [typographyScss, setTypographyScss] = useState();
    const [uesrProfileScss, setUesrProfileScss] = useState();
    const [variableScss, setVariableScss] = useState();
    // Switcher states
    const [switcherScss, setSwitcherScss] = useState("");
    const [switcher, setSwitcher] = useState("");
    const [colorControl, setColorControl] = useState("");
    const [colorControlScss, setColorControlScss] = useState("");
    const [flipClock, setFlipClock] = useState("");
    const [flipClockScss, setFlipClockScss] = useState("");
    const [useReactCounterDown, setUseReactCounterDown] = useState("");
    const [navigationType, setNavigationType] = useState("");
    const [navigationTypeScss, setNavigationTypeScss] = useState("");
    const [offerNotice, setOfferNotice] = useState("");
    const [offerNoticeScss, setOfferNoticeScss] = useState("");
    const [sidebarBgControl, setSidebarBgControl] = useState("");
    const [sidebarBgControlScss, setSidebarBgControlScss] = useState("");
    const [switchControl, setSwitchControl] = useState("");

    const [dashboardDataContext, setDashboardDataContext] = useState();
    const [searchBarScss, setSearchBarScss] = useState();
    const [searchBar, setSearchBar] = useState();
    const [darkModeSwitch, setDarkModeSwitch] = useState();
    const [darkModeSwitchScss, setDarkModeSwitchScss] = useState();
    const [authentication, setAuthentication] = useState();
    const [gridsScss, setGridsScss] = useState();
    const [multipleTagSelectScss, setMultipleTagSelectScss] = useState();
    const [notificationStatsCartScss, setNotificationStatsCartScss] =
        useState();
    const [profileCardScss, setProfileCardScss] = useState();
    const [statsScss, setStatsScss] = useState();
    const [stepCircleWizardScss, setStepCircleWizardScss] = useState();
    const [stepSquarWizardScss, setStepSquarWizardScss] = useState();
    const [stepTabWizardScss, setStepTabWizardScss] = useState();
    const [trafficScss, setTrafficScss] = useState();
    const [userStatsScss, setUserStatsScss] = useState();
    const [weatherStatsScss, setWeatherStatsScss] = useState();
    const [widgetsCardScss, setWidgetsCardScss] = useState();

    // variation layouts
    const [layouts, setLayouts] = useState();
    const [combo, setCombo] = useState();
    const [dualNav, setDualNav] = useState();
    const [horizontal, setHorizontal] = useState();
    const [vertical, setVertical] = useState();
    const [comboScss, setComboScss] = useState();
    const [dualNavScss, setDualNavScss] = useState();
    const [horizontalScss, setHorizontalScss] = useState();
    const [verticalScss, setVerticalScss] = useState();

    // sidebar variation
    const [comboSidebar, setComboSidebar] = useState();
    const [verticalSidebar, setVerticalSidebar] = useState();
    const [comboSidebarScss, setComboSidebarScss] = useState();
    const [verticalSidebarScss, setVerticalSidebarScss] = useState();

    // Top navbar variation
    const [dualTopNav, setDualTopNav] = useState();
    const [horizontalNav, setHorizontalNav] = useState();
    const [verticalNav, setVerticalNav] = useState();
    const [dualTopNavScss, setDualTopNavScss] = useState();
    const [verticalNavScss, setVerticalNavScss] = useState();
    const [horizontalNavScss, setHorizontalNavScss] = useState();

    return (
        <DashboardCodebaseContext.Provider
            value={{
                indexHtml,
                setIndexHtml,
                packageJson,
                setPackageJson,
                gitignore,
                setGitignore,
                eslintrcCjs,
                setEslintrcCjs,
                readmemd,
                setReadmemd,
                viteConfig,
                setViteConfig,
                // public
                redirects,
                setRedirects,
                // src files
                app,
                setApp,
                alertCode,
                setAlertCode,
                cardContent,
                setCardContent,
                notificationStatsCart,
                setNotificationStatsCart,
                overlayCard,
                setOverlayCard,
                profileCard,
                setProfileCard,
                widgetsCard,
                setWidgetsCard,
                areaChart,
                setAreaChart,
                bubbleChart,
                setBubbleChart,
                candlestickChart,
                setCandlestickChart,
                columnChart,
                setColumnChart,
                lineChart,
                setLineChart,
                pieChart,
                setPieChart,
                barChart,
                setBarChart,
                doughnutChart,
                setDoughnutChart,
                lineChartCJs,
                setLineChartCJs,
                pieChartCJs,
                setPieChartCJs,
                polarAreaChart,
                setPolarAreaChart,
                radarChart,
                setRadarChart,
                areaRechart,
                setAreaRechart,
                barRechart,
                setBarRechart,
                lineRechart,
                setLineRechart,
                pieRechart,
                setPieRechart,
                rechartRadialBar,
                setRechartRadialBar,
                rechartScatter,
                setRechartScatter,
                analyticsInfo,
                setAnalyticsInfo,
                deviceVisitorStats,
                setDeviceVisitorStats,
                monthlyStatsChart,
                setMonthlyStatsChart,
                day,
                setDay,
                month,
                setMonth,
                statsCodeBase,
                setStatsCodeBase,
                year,
                setYear,
                analyticsStatsCodeBase,
                setAnalyticsStatsCodeBase,
                download,
                setDownload,
                earning,
                setEarning,
                monthlyStats,
                setMonthlyStats,
                revenue,
                setRevenue,
                socialCounter,
                setSocialCounter,
                statsCard,
                setStatsCard,
                timeline,
                setTimeline,
                areaChartApx,
                setAreaChartApx,
                traffic,
                setTraffic,
                userStats,
                setUserStats,
                weatherStats,
                setWeatherStats,
                brandico,
                setBrandico,
                entypo,
                setEntypo,
                fontAwesome,
                setFontAwesome,
                fontelico,
                setFontelico,
                tableData,
                setTableData,
                dropdownMenuCode,
                setDropdownMenuCode,
                additionalInfo,
                setAdditionalInfo,
                loggedIn,
                setLoggedIn,
                personalDetails,
                setPersonalDetails,
                stepCircleWizard,
                setStepCircleWizard,
                stepSquarWizard,
                setStepSquarWizard,
                stepTabWizard,
                setStepTabWizard,
                basicElementForm,
                setBasicElementForm,
                companyForm,
                setCompanyForm,
                creditCardForm,
                setCreditCardForm,
                horizontalForm,
                setHorizontalForm,
                maskedInput,
                setMaskedInput,
                multipleTagSelect,
                setMultipleTagSelect,
                stackedForm,
                setStackedForm,
                widgetsForm,
                setWidgetsForm,
                brandicoCode,
                setBrandicoCode,
                entypoCode,
                setEntypoCode,
                copyButton,
                setCopyButton,
                fontAwesomeCode,
                setFontAwesomeCode,
                fontelicoCode,
                setFontelicoCode,
                iconviewer,
                setIconviewer,
                modal,
                setModal,
                messageCode,
                setMessageCode,
                notificationCode,
                setNotificationCode,
                userProfile,
                setUserProfile,
                progressBar,
                setProgressBar,
                tab,
                setTab,
                tabOne,
                setTabOne,
                tabTwo,
                setTabTwo,
                tabThree,
                setTabThree,
                table,
                settable,
                userInfo,
                setUserInfo,
                advancedForm,
                setAdvancedForm,
                alerts,
                setAlerts,
                apexChartsCode,
                setApexChartsCode,
                badges,
                setBadges,
                basicForm,
                setBasicForm,
                buttons,
                setButtons,
                cards,
                setCards,
                chartjsCode,
                setChartjsCode,
                dashboardCode,
                setDashboardCode,
                googleMaps,
                setGoogleMaps,
                grids,
                setGrids,
                iconsCode,
                setIconsCode,
                leafletMaps,
                setLeafletMaps,
                darkMatter,
                setDarkMatter,
                streetMap,
                setStreetMap,
                voyager,
                setVoyager,
                worldImagery,
                setWorldImagery,
                login,
                setLogin,
                modalsCode,
                setModalsCode,
                page404,
                setPage404,
                page500,
                setPage500,
                progressBars,
                setProgressBars,
                rechartsCode,
                setRechartsCode,
                register,
                setRegister,
                socialButtons,
                setSocialButtons,
                tables,
                setTables,
                tabs,
                setTabs,
                typography,
                setTypography,
                widgets,
                setWidgets,
                wizardForm,
                setWizardForm,
                rootIndex,
                setRootIndex,
                indexScss,
                setIndexScss,
                nav,
                setNav,
                routes,
                setRoutes,
                cardContentScss,
                setCardContentScss,
                earningScss,
                setEarningScss,
                iconStyleScss,
                setIconStyleScss,
                messageScss,
                setMessageScss,
                notificationScss,
                setNotificationScss,
                overlayCardScss,
                setOverlayCardScss,
                progressBarScss,
                setProgressBarScss,
                socialButtonsScss,
                setSocialButtonsScss,
                statsCardScss,
                setStatsCardScss,
                tablesScss,
                setTablesScss,
                tabsScss,
                setTabsScss,
                timelineScss,
                setTimelineScss,
                typographyScss,
                setTypographyScss,
                uesrProfileScss,
                setUesrProfileScss,
                variableScss,
                setVariableScss,
                //Switcher
                switcher,
                setSwitcher,
                switcherScss,
                setSwitcherScss,
                colorControl,
                setColorControl,
                colorControlScss,
                setColorControlScss,
                flipClock,
                setFlipClock,
                flipClockScss,
                setFlipClockScss,
                useReactCounterDown,
                setUseReactCounterDown,
                navigationType,
                setNavigationType,
                navigationTypeScss,
                setNavigationTypeScss,
                offerNotice,
                setOfferNotice,
                offerNoticeScss,
                setOfferNoticeScss,
                sidebarBgControl,
                setSidebarBgControl,
                sidebarBgControlScss,
                setSidebarBgControlScss,
                switchControl,
                setSwitchControl,

                //
                dashboardDataContext,
                setDashboardDataContext,
                searchBarScss,
                setSearchBarScss,
                searchBar,
                setSearchBar,
                darkModeSwitch,
                setDarkModeSwitch,
                darkModeSwitchScss,
                setDarkModeSwitchScss,
                authentication,
                setAuthentication,
                gridsScss,
                setGridsScss,
                multipleTagSelectScss,
                setMultipleTagSelectScss,
                notificationStatsCartScss,
                setNotificationStatsCartScss,
                profileCardScss,
                setProfileCardScss,
                statsScss,
                setStatsScss,
                stepCircleWizardScss,
                setStepCircleWizardScss,
                stepSquarWizardScss,
                setStepSquarWizardScss,
                stepTabWizardScss,
                setStepTabWizardScss,
                trafficScss,
                setTrafficScss,
                userStatsScss,
                setUserStatsScss,
                weatherStatsScss,
                setWeatherStatsScss,
                widgetsCardScss,
                setWidgetsCardScss,
                verticalScss,
                setVerticalScss,

                // layouts vertical
                layouts,
                setLayouts,
                combo,
                setCombo,
                dualNav,
                setDualNav,
                horizontal,
                setHorizontal,
                vertical,
                setVertical,
                comboScss,
                setComboScss,
                dualNavScss,
                setDualNavScss,
                horizontalScss,
                setHorizontalScss,

                // sidebar variation
                comboSidebar,
                setComboSidebar,
                verticalSidebar,
                setVerticalSidebar,
                comboSidebarScss,
                setComboSidebarScss,
                verticalSidebarScss,
                setVerticalSidebarScss,

                // Top navbar variation
                dualTopNav,
                setDualTopNav,
                horizontalNav,
                setHorizontalNav,
                verticalNav,
                setVerticalNav,
                dualTopNavScss,
                setDualTopNavScss,
                verticalNavScss,
                setVerticalNavScss,
                horizontalNavScss,
                setHorizontalNavScss,
            }}
        >
            {children}
        </DashboardCodebaseContext.Provider>
    );
};

export const useCodebaseContext = () => {
    return useContext(DashboardCodebaseContext);
};
