import { useEffect } from "react";
import { useFileStore } from "../../../store/useFileStore";

const DocData = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store);

    useEffect(() => {
        let value = `export const statsCardWithChart = [
    {
        name: "Page A",
        uv: 2000,
        pv: 2400,
        amt: 2400,
    },
    {
        name: "Page B",
        uv: 6000,
        pv: 1398,
        amt: 2210,
    },
    {
        name: "Page C",
        uv: 4000,
        pv: 9800,
        amt: 2290,
    },
    {
        name: "Page D",
        uv: 8000,
        pv: 3908,
        amt: 2000,
    },
    {
        name: "Page E",
        uv: 4000,
        pv: 4800,
        amt: 2181,
    },
    {
        name: "Page F",
        uv: 6000,
        pv: 3800,
        amt: 2500,
    },
    {
        name: "Page G",
        uv: 2000,
        pv: 4300,
        amt: 2100,
    },
    {
        name: "Page G",
        uv: 5000,
        pv: 4300,
        amt: 2100,
    },
];`;

        setStore({ docData: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default DocData;
