const ColorPicker = ({ title, pickColor, serPickColor }) => {
    return (
        <div className="flex gap-3 items-center mt-4 py-2 px-4 bg-slate-100 mb-2 w-3/4 rounded-md">
            <h2 className="block text-[#24263a] text-xs leading-[15px] font-bold">{title}</h2>
            <div
                className="w-[35px] h-[35px] rounded-[50%] border-[1px] border-[#D8D8D8] relative"
                style={{ backgroundColor: `${pickColor}` }}>
                <input
                    type="color"
                    className="opacity-0"
                    onChange={(e) => serPickColor(e.target.value)}
                />
                <span
                    className="absolute top-[5px] left-[42px]"
                    // style={{
                    //     color: `${pickColor}`,
                    // }}
                >
                    {pickColor}
                </span>
            </div>
        </div>
    );
};

export default ColorPicker;
