import React, { useEffect, useState } from "react";
import Accordion from "../../../../../../../components/Accordion/Accordion";
import { useDynamicDashboardData } from "../../../context/dashboardDataContext";
import TextControl from "../../../../../../../components/TextControl/TextControl";
import { getSlug } from "../../../../../../../utils/getSlug";

function SwitcherUitls() {
    const { projectInfo, setProjectInfo } = useDynamicDashboardData();
    const [freeDownload, setFreeDownload] = useState("free");

    useEffect(() => {
        if (freeDownload === "pro") {
            setProjectInfo({
                ...projectInfo,
                downloadLabel: "Get Pro Version",
            });
        } else {
            setProjectInfo({
                ...projectInfo,
                downloadLabel: "Get Free Version",
            });
        }
    }, [freeDownload]);

    useEffect(() => {
        setProjectInfo({
            ...projectInfo,
            purchaseURL: `https://reactadmin.com/${getSlug(projectInfo?.projectName)}`,
            docURL: `https://demo.reactadmin.com/bootstrap/${getSlug(
                projectInfo?.projectName
            )}/docs/`,
            productSlug: getSlug(projectInfo?.projectName, "_"),
            downloadURL: `https://www.reactadmin.com/${getSlug(projectInfo?.projectName)}`,
            fbShareURL: `https://reactadmin.com/${getSlug(projectInfo?.projectName)}`,
            tweetURL: `https://reactadmin.com/${getSlug(projectInfo?.projectName)}`,
        });
    }, [projectInfo?.projectName]);

    return (
        <Accordion title="Swithcer Utils">
            <div className="p-5">
                <div className="flex items-center gap-6 mb-4">
                    <h3>Download Button: </h3>
                    <div className="flex gap-2">
                        <input
                            type="radio"
                            id="free"
                            checked={freeDownload === "free" ? true : false}
                            name="download_version"
                            value="free"
                            onChange={(e) => setFreeDownload(e.target.value)}
                        />
                        <label htmlFor="free">Free</label>
                    </div>
                    <div className="flex gap-2">
                        <input
                            type="radio"
                            id="pro"
                            checked={freeDownload === "pro" ? true : false}
                            name="download_version"
                            value="pro"
                            onChange={(e) => setFreeDownload(e.target.value)}
                        />
                        <label htmlFor="pro">Pro</label>
                    </div>
                </div>

                <div className="grid grid-cols-2 gap-4 mb-4">
                    <TextControl
                        label={`${freeDownload === "free" ? "Free" : "Pro"} Version Download Label`}
                        value={projectInfo?.downloadLabel}
                        onChange={(e) =>
                            setProjectInfo({
                                ...projectInfo,
                                downloadLabel: e.target.value?.trim(),
                            })
                        }
                    />
                    <TextControl
                        label={`${freeDownload === "free" ? "Free" : "Pro"} Version Download URL`}
                        value={projectInfo?.downloadURL}
                        onChange={(e) =>
                            setProjectInfo({
                                ...projectInfo,
                                downloadURL: e.target.value?.trim(),
                            })
                        }
                    />
                </div>

                <div className="grid grid-cols-2 gap-4 mb-4">
                    <TextControl
                        label="Purchase Now Label"
                        value={projectInfo?.purchaseLabel}
                        onChange={(e) =>
                            setProjectInfo({
                                ...projectInfo,
                                purchaseLabel: e.target.value?.trim(),
                            })
                        }
                    />
                    <TextControl
                        label="Purchase Now URL"
                        value={projectInfo?.purchaseURL}
                        onChange={(e) =>
                            setProjectInfo({
                                ...projectInfo,
                                purchaseURL: e.target.value?.trim(),
                            })
                        }
                    />
                </div>

                <div className="grid grid-cols-2 gap-4 mb-4">
                    <TextControl
                        label="Documentation Label"
                        value={projectInfo?.docLabel}
                        onChange={(e) =>
                            setProjectInfo({
                                ...projectInfo,
                                docLabel: e.target.value?.trim(),
                            })
                        }
                    />
                    <TextControl
                        label="Documentation URL"
                        value={projectInfo?.docURL}
                        onChange={(e) =>
                            setProjectInfo({
                                ...projectInfo,
                                docURL: e.target.value?.trim(),
                            })
                        }
                    />
                </div>

                <div className="mb-4">
                    <TextControl
                        label="Facebook Share URL"
                        value={projectInfo?.fbShareURL}
                        onChange={(e) =>
                            setProjectInfo({
                                ...projectInfo,
                                fbShareURL: e.target.value?.trim(),
                            })
                        }
                    />
                </div>

                <div className="grid grid-cols-2 gap-4 mb-4">
                    <TextControl
                        label="Tweet Text"
                        value={projectInfo?.tweetText}
                        onChange={(e) =>
                            setProjectInfo({
                                ...projectInfo,
                                tweetText: e.target.value?.trim(),
                            })
                        }
                    />
                    <TextControl
                        label="Tweet URL"
                        value={projectInfo?.tweetURL}
                        onChange={(e) =>
                            setProjectInfo({
                                ...projectInfo,
                                tweetURL: e.target.value?.trim(),
                            })
                        }
                    />
                    <TextControl
                        label="Tweet Hashtags"
                        value={projectInfo?.tweetHashTags}
                        onChange={(e) =>
                            setProjectInfo({
                                ...projectInfo,
                                tweetHashTags: e.target.value?.trim(),
                            })
                        }
                    />
                </div>
            </div>
        </Accordion>
    );
}

export default SwitcherUitls;
