import { useEffect } from "react";
import { useFileStore } from "../../../../store/useFileStore";

const TypographyScss = () => {
    const [_, setStore] = useFileStore((store) => store.typographyScss);

    useEffect(() => {
        let value = `.typography {
    .typo_headers {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: var(--hedging-text-color);
        }
    }
    .typo_articles {
        width: 90%;
        p {
            color: var(--content-text-color);
        }
        footer {
            color: var(--hedging-text-color);
        }
    }
    .lists {
        h2 {
            color: var(--hedging-text-color);
        }
        .unordered_list {
            h3 {
                color: var(--hedging-text-color);
            }
            ul {
                padding: 30px;
                li {
                    color: var(--content-text-color);
                }
            }
            .list_square {
                list-style-type: square;
            }
        }
        .ordered_Lists {
            h3 {
                color: var(--hedging-text-color);
            }
            ol {
                padding: 30px;
                li {
                    color: var(--content-text-color);
                }
            }
        }
        .misc {
            h2,
            h3 {
                color: var(--hedging-text-color);
            }
            address {
                color: var(--content-text-color);
                a {
                    cursor: pointer;
                    color: var(--link-color);
                }
            }
            .well {
                span {
                    color: var(--hedging-text-color);
                }
            }
        }
    }
}
`;
        setStore({ typographyScss: value });
    });
    return null;
};

export default TypographyScss;
