import { useEffect } from "react";
import { useFileStore } from "../../../../store/useFileStore";

const Authentication = () => {
    const [_, setStore] = useFileStore((store) => store.authentication);
    useEffect(() => {
        let value = `@import "../scss/variable.scss";

.auth_wrapper {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
    background-color: var(--body-bg);
    .from_container {
        width: 50%;
        background-color: transparent;
        .card {
            border-color: var(--border-color);
            background-color: var(--bg-content);
        }
        .card_header {
            color: var(--hedging-text-color);
            padding: 12px 20px;
            border-color: var(--border-color);
            background-color: var(--bg-content);
        }
        .card_body {
            padding: 40px;
            form {
                .input_group_icon {
                    color: var(--hedging-text-color);
                    border-color: var(--border-color);
                    background-color: var(--bg-content);
                }
                button[type="submit"] {
                    padding: 6px 12px;
                    font-size: 14px;
                    font-weight: 400;
                    background-color: #28a745;
                }
                a[type="action"] {
                    padding: 6px 12px;
                    font-size: 14px;
                    font-weight: 400;
                    background-color: #007bff;
                }
            }
        }
    }
    .logo_container {
        position: relative;
        width: 50%;
        height: 100%;
        background-color: #333;
        overflow: hidden;
        .oblique {
            position: absolute;
            background-color: #282828;
            left: 38.5%;
            transform: rotate(150deg);
            width: 27%;
            height: 110%;
        }
        .logo {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            position: relative;
        }
    }
    .error_container {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        .error_code {
            font-family: inherit;
            font-size: 4rem;
            font-weight: 300;
            margin-bottom: 0px;
            color: var(--hedging-text-color);
        }
        .error_title {
            font-family: inherit;
            font-weight: 500;
            font-size: 1em;
            color: var(--content-text-color);
        }
        .error_description {
            font-family: Open Sans, sans-serif;
            font-weight: 400;
            font-size: 14px;
            color: var(--content-text-color);
        }
        .input_group {
            margin-top: 20px;
            .error_search_btn {
                padding: 8px 12px;
                background-color: var(--bg-content);
                border-radius: 5px 0 0 5px;
                border: 1px solid var(--border-color);
                color: var(--content-text-color);
            }
            .error_submit_btn {
                padding: 6px 12px;
                border: 1px solid #17a2b8;
                background-color: #17a2b8;
                color: #fff;
            }
        }
    }
}
body[data-theme="true"] {
    .input_group {
        .input_group_icon {
            border-radius: 0 0.375rem 0.375rem 0 !important;
        }
        input {
            border-radius: 0.375rem 0 0 0.375rem !important;
        }
    }
    .error_search_btn {
        border-radius: 0 0.375rem 0.375rem 0 !important;
    }
}

/* responsive  */
@media (max-width: 425px) {
    .auth_wrapper {
        .from_container {
            width: 100%;
        }
        .logo_container {
            display: none;
        }
    }
}
`;
        setStore({ authentication: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return null;
};

export default Authentication;
