import { useEffect } from "react";
import { useFileStore } from "../../../../../store/useFileStore";

const DocTabSCSS = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store);

    useEffect(() => {
        let value = `@import "../../css/variable.scss";

.tablist_wrapper {
    display: flex;
    border: 1px solid var(--border-color);
    background-color: var(--bg-content);
    .tab_title {
        .title_list {
            padding-left: 0;
            margin-bottom: 0;
            .inactive_tab {
                margin-top: 0;
                list-style: none;
                line-height: 1.42857;
                padding: 10px 20px;
                color: var(--content-text-color);
                cursor: pointer;
                span {
                    font-size: 15px;
                    font-weight: 300;
                }
                i {
                    font-size: 14px;
                }
                &.active_tab {
                    cursor: default !important;
                }
            }
        }
    }
    &.top {
        flex-direction: column;
        .tab_title {
            border-bottom: 1px solid var(--border-color);
            .title_list {
                display: flex;
            }
        }
    }
    &.bottom {
        flex-direction: column-reverse;
        .tab_title {
            border-top: 1px solid var(--border-color);
            .title_list {
                display: flex;
            }
        }
    }
    &.left {
        .tab_title {
            border-right: 1px solid var(--border-color);
        }
        li {
            padding: 16px 20px!important;
        }
    }
    &.right {
        flex-direction: row-reverse;
        .tab_title {
            border-left: 1px solid var(--border-color);
        }
        li {
            padding: 16px 20px!important;
        }
    }
}
`;

        setStore({ docTabSCSS: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default DocTabSCSS;
