const ViewTree = ({ setToggle, toggle, folderName, isExpand }) => {
    return (
        <div className="flex items-center">
            {isExpand && (
                <button
                    className="border-[1px] border-[#000] w-[15px] h-[15px] flex justify-center items-center mr-3 "
                    onClick={() => setToggle(!toggle)}>
                    {!toggle ? (
                        <i className="fa-solid fa-plus text-[10px] font-bold"></i>
                    ) : (
                        <i className="fa-solid fa-minus text-[10px] font-bold"></i>
                    )}
                </button>
            )}

            <div className="flex items-center gap-[6px] hover:bg-[#FF0000] px-1">
                <i className="fa-solid fa-folder text-[#DBC056]"></i>
                <span className="text-[13px] select-none">{folderName}</span>
            </div>
        </div>
    );
};

export default ViewTree;
