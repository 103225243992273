import { useEffect } from "react";
import { useFileStore } from "../../../store/useFileStore";
import { useDynamicDashboardData } from "../../../context/dashboardDataContext";

const DocIndexMd = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store);

    const { projectInfo } = useDynamicDashboardData();

    useEffect(() => {
        let value = `---
sidebar_position: 1
path: /
---

# Getting Started

${projectInfo?.projectName} is a React Dashboard template that helps you create a powerful and user-friendly web application. ${projectInfo?.projectName} is built with React, Bootstrap, Chart.js, react apexcharts, react chartjs 2, react countup, react leaflet, react select, recharts, sass, react-google-maps, styled-components and it provides you with all the necessary components and forms to make a better dashboard. Some of the features of ${projectInfo?.projectName} are:

-   Responsive and modern design
-   Customizable and reusable components
-   Charts, Widgets, tables, forms, Maps
-   Dark and light themes
-   Notifications and alerts
-   Icons and fonts
-   RTL Supports

    ${projectInfo?.projectName} is a perfect choice for any web project that requires a dashboard, such as e-commerce, analytics, CRM, or CMS.
`;

        setStore({ docIndexMd: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectInfo?.projectName]);

    return null;
};

export default DocIndexMd;
