import { useEffect } from "react";
import { useFileStore } from "../../../../store/useFileStore";

const DocBadgeMDX = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store);

    useEffect(() => {
        let value = `---
sidebar_position: 2
sidebar_label: Badge
---

# Badge

The Badge component shows a small label or indicator that is attached to another element, such as a button, an icon, or a menu item. The Badge component can be used to display counts, statuses, or other short information. The Badge component can have different variants, such as filled or outlined, and different colors.

You can find the Badges component in the \`src/views/Badges.jsx\` file

import { Badge, Button } from "react-bootstrap";
import FeatureTable from "@site/src/components/FeatureTable/FeatureTable";
import CodePreview from "@site/src/components/CodePreview/CodePreview";

import Tabs from '@theme/Tabs';
import TabItem from '@theme/TabItem';

## Examples
Badges scale to match the size of the immediate parent element by using size and em units. Badge [Preview](https://demo.reactadmin.com/bootstrap/basix-admin/components/badges)

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <div>
                <h1 style={{marginBottom: "8px"}}>Example heading <Badge bg="secondary">New</Badge></h1>
                <h2>Example heading <Badge bg="secondary">New</Badge></h2>
                <h3>Example heading <Badge bg="secondary">New</Badge></h3>
                <h4>Example heading <Badge bg="secondary">New</Badge></h4>
                <h5>Example heading <Badge bg="secondary">New</Badge></h5>
                <h6>Example heading <Badge bg="secondary">New</Badge></h6>
            </div>
        </CodePreview>
    </TabItem>
    
    <TabItem value="code" label="Code">

        \`\`\`jsx title="Badges"
        import Badge from "react-bootstrap/Badge";

        function MyComponent() {
            return (
                <div>
                    <h1>Example heading <Badge bg="secondary">New</Badge></h1>
                    <h2>Example heading <Badge bg="secondary">New</Badge></h2>
                    <h3>Example heading <Badge bg="secondary">New</Badge></h3>
                    <h4>Example heading <Badge bg="secondary">New</Badge></h4>
                    <h5>Example heading <Badge bg="secondary">New</Badge></h5>
                    <h6>Example heading <Badge bg="secondary">New</Badge></h6>
                </div>
            );
        }

        export default MyComponent;
        \`\`\`
    </TabItem>
</Tabs>

<br/>
### With Button Counter
Badge use also counter with append \`<Button >\` or any other component. Badge [Preview](https://demo.reactadmin.com/bootstrap/basix-admin/components/badges)

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <div className="d-flex gap-2">
                <Button variant="primary">Profile <Badge bg="light" text="dark">9</Badge><span className="visually-hidden">unread messages</span></Button>
                <Button variant="success">Profile <Badge bg="light" text="dark">9</Badge><span className="visually-hidden">unread messages</span></Button>
                <Button variant="info">Profile <Badge bg="light" text="dark">9</Badge><span className="visually-hidden">unread messages</span></Button>
                <Button variant="warning">Profile <Badge bg="light" text="dark">9</Badge><span className="visually-hidden">unread messages</span></Button>
                <Button variant="danger">Profile <Badge bg="light" text="dark">9</Badge><span className="visually-hidden">unread messages</span></Button>
            </div>
        </CodePreview>
    </TabItem>
    
    <TabItem value="code" label="Code">

        \`\`\`jsx title="Badges"
        import Badge from "react-bootstrap/Badge";

        function MyComponent() {
            return (
                <div>
                    <Button variant="primary">
                        Profile <Badge bg="light" text="dark">9</Badge>
                    </Button>
                    <Button variant="success">
                        Profile <Badge bg="light" text="dark">9</Badge>    
                    </Button>
                    <Button variant="info">
                        Profile <Badge bg="light" text="dark">9</Badge>
                    </Button>
                    <Button variant="warning">
                        Profile <Badge bg="light" text="dark">9</Badge>
                    </Button>
                    <Button variant="danger">
                        Profile <Badge bg="light" text="dark">9</Badge>
                    </Button>
                </div>
            );
        }

        export default MyComponent;
        \`\`\`
    </TabItem>
</Tabs>

<br/>
### Contextual Variations
Add any of the below mentioned modifier classes to change the appearance of a badge. Badge [Preview](https://demo.reactadmin.com/bootstrap/basix-admin/components/badges)

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <div className="d-flex flex-wrap gap-2">
                <Badge bg="primary">Primary</Badge>
                <Badge bg="secondary">Secondary</Badge>
                <Badge bg="success">Success</Badge>
                <Badge bg="danger">Danger</Badge>
                <Badge bg="warning" text="dark">Warning</Badge>
                <Badge bg="info" text="dark">Info</Badge>
                <Badge bg="light" text="dark">Light</Badge>
                <Badge bg="dark">Dark</Badge>
            </div>
        </CodePreview>
    </TabItem>
    
    <TabItem value="code" label="Code">

        \`\`\`jsx title="Badges"
        import Badge from "react-bootstrap/Badge";

        function MyComponent() {
            return (
                <div className="d-flex flex-wrap gap-2">
                    <Badge bg="primary">Primary</Badge>
                    <Badge bg="secondary">Secondary</Badge>
                    <Badge bg="success">Success</Badge>
                    <Badge bg="danger">Danger</Badge>
                    <Badge bg="warning" text="dark">Warning</Badge>
                    <Badge bg="info" text="dark">Info</Badge>
                    <Badge bg="light" text="dark">Light</Badge>
                    <Badge bg="dark">Dark</Badge>
                </div>
            );
        }

        export default MyComponent;
        \`\`\`
    </TabItem>
</Tabs>

<br/>
### Pill badges
Use the pill modifier props to make badges more rounded. Badge [Preview](https://demo.reactadmin.com/bootstrap/basix-admin/components/badges)

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <div className="d-flex flex-wrap gap-2">
                <Badge pill bg="primary">Primary</Badge>
                <Badge pill bg="secondary">Secondary</Badge>
                <Badge pill bg="success">Success</Badge>
                <Badge pill bg="danger">Danger</Badge>
                <Badge pill bg="warning" text="dark">Warning</Badge>
                <Badge pill bg="info" text="dark">Info</Badge>
                <Badge pill bg="light" text="dark">Light</Badge>
                <Badge pill bg="dark">Dark</Badge>
            </div>
        </CodePreview>
    </TabItem>
    
    <TabItem value="code" label="Code">

        \`\`\`jsx title="Badges"
        import Badge from "react-bootstrap/Badge";

        function MyComponent() {
            return (
                <div className="d-flex flex-wrap gap-2">
                    <Badge pill bg="primary">Primary</Badge>
                    <Badge pill bg="secondary">Secondary</Badge>
                    <Badge pill bg="success">Success</Badge>
                    <Badge pill bg="danger">Danger</Badge>
                    <Badge pill bg="warning" text="dark">Warning</Badge>
                    <Badge pill bg="info" text="dark">Info</Badge>
                    <Badge pill bg="light" text="dark">Light</Badge>
                    <Badge pill bg="dark">Dark</Badge>
                </div>
            );
        }

        export default MyComponent;
        \`\`\`
    </TabItem>
</Tabs>

<br/>
## Props

<FeatureTable>
    <tr>
        <td>bg</td>
        <td>\`primary\`, \`secondary\`, \`success\`, \`danger\`, \`warning\`, \`info\`, \`light\`, \`dark\`</td>
        <td>\`primary\`</td>
        <td>A string that specifies the background color of the badge. You can choose from eight options: \`primary\`, \`secondary\`, \`success\`, \`danger\`, \`warning\`, \`info\`, \`dark\`, or \`light\`. The default value is \`primary\`.</td>
    </tr>
    <tr>
        <td>pill</td>
        <td>boolean</td>
        <td>\`false\`</td>
        <td>A boolean that indicates whether the badge has a rounded shape or not. If \`pill\` is \`true\`, the badge will have a pill-like shape with rounded corners. If \`pill\` is \`false\`, the badge will have a square shape with sharp corners. The default value is \`false\`.</td>
    </tr>
</FeatureTable>

For more props please visit to <a href="https://react-bootstrap.netlify.app/docs/components/badge" target="_blank">react-bootstrap</a>
`;

        setStore({ docBadgeMDX: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default DocBadgeMDX;
