import { useEffect } from "react";
import { useFileStore } from "../../../../store/useFileStore";

const DocWidgetsMDX = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store);

    useEffect(() => {
        let value = `---
sidebar_position: 10
sidebar_label: Widgets
---

# Widgets

Widgets are small and interactive components that display useful information or perform specific actions on your dashboard. You can customize the appearance and behavior of each widget by changing the color, counter, symbol, title, description, border, width, height, padding, and link. You can also create your own widgets by following the same structure and style of the existing widgets.

You can find the widgets component in the \`src/views/widgets.jsx\` file.

import FeatureTable from "@site/src/components/FeatureTable/FeatureTable";
import CodePreview from "@site/src/components/CodePreview/CodePreview";
import StatsCard from "@site/src/components/Widgets/StatsCard/StatsCard";
import SocialCounter from "@site/src/components/Widgets/SocialCounter/SocialCounter";
import UserStats from "@site/src/components/Widgets/UserStats/UserStats";
import { BarChart, Bar } from "recharts";
import { statsCardWithChart } from "../../data/data";
import Tabs from '@theme/Tabs';
import TabItem from '@theme/TabItem';

## Examples

### Stats Card
Stats Card widget in a dashboard enables concise presentation of key statistics for efficient data visualization and monitoring in a single view. Widgets [Preview](https://demo.reactadmin.com/bootstrap/basix-admin/components/widgets)

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <div className="d-flex gap-2">
                <div className="col-4">
                    <StatsCard
                        type="revenue-counter"
                        bgColor="#5c6bc0"
                        symbol="$"
                        counter={23569}
                        isCounter={true}
                        title="Revenue"
                        icon="fa-solid fa-cart-shopping"
                    />
                </div>

                <div className="col-4">
                    <StatsCard
                        type="revenue-counter"
                        bgColor="#66bb6a"
                        symbolPosition="right"
                        symbol="%"
                        counter={23569}
                        isCounter={true}
                        title="Revenue"
                        icon={
                            <BarChart width={100} height={80} data={statsCardWithChart}>
                                <Bar dataKey="uv" fill="#fff" />
                            </BarChart>
                        }
                    />
                </div>

                <div className="col-4">
                    <StatsCard
                        type="revenue-progressBar"
                        bgColor="#5c6bc0"
                        symbol="%"
                        symbolPosition="right"
                        counter={89}
                        isCounter={true}
                        title="Lorem ipsum..."
                        progressPercent={25}
                        description="Lorem ipsum dolor sit amet enim."
                    />
                </div>
            </div>

        </CodePreview>
    </TabItem>
    
    <TabItem value="code" label="Code">
        \`\`\`jsx title="Widgets"
        import React from "react";
        import StatsCard from "@/components/StatsCard/StatsCard";
        import { BarChart, Bar } from "recharts";

        const data = [
            {
                name: "Page A",
                uv: 2000,
                pv: 2400,
                amt: 2400,
            },
            {
                name: "Page B",
                uv: 6000,
                pv: 1398,
                amt: 2210,
            },
            {
                name: "Page C",
                uv: 4000,
                pv: 9800,
                amt: 2290,
            },
            {
                name: "Page D",
                uv: 8000,
                pv: 3908,
                amt: 2000,
            },
            {
                name: "Page E",
                uv: 4000,
                pv: 4800,
                amt: 2181,
            },
            {
                name: "Page F",
                uv: 6000,
                pv: 3800,
                amt: 2500,
            },
            {
                name: "Page G",
                uv: 2000,
                pv: 4300,
                amt: 2100,
            },
            {
                name: "Page G",
                uv: 5000,
                pv: 4300,
                amt: 2100,
            },
        ];

        const MyComponent = () => {
            return (
                <>
                    <StatsCard
                        type="revenue-counter"
                        bgColor="#5c6bc0"
                        symbol="$"
                        counter={23569}
                        isCounter={true}
                        title="Revenue"
                        icon="fa-solid fa-cart-shopping"
                    />
                    <StatsCard
                        type="revenue-counter"
                        bgColor="#66bb6a"
                        symbolPosition="right"
                        symbol="%"
                        counter={23569}
                        isCounter={true}
                        title="Revenue"
                        icon={
                            <BarChart width={100} height={80} data={statsCardWithChart}>
                                <Bar dataKey="uv" fill="#fff" />
                            </BarChart>
                        }
                    />
                    <StatsCard
                        type="revenue-progressBar"
                        bgColor="#5c6bc0"
                        symbol="%"
                        symbolPosition="right"
                        counter={89}
                        isCounter={true}
                        title="Lorem ipsum..."
                        progressPercent={25}
                        description="Lorem ipsum dolor sit amet enim."
                    />
                </>
            );
        };

        export default MyComponent;
        \`\`\`
    </TabItem>
</Tabs>

## Props

<FeatureTable>
    <tr>
        <td>type</td>
        <td>
            \`revenue-counter\`, 
             \`user-info\`, 
              \`revenue-progressBar\`, 
            \`revenue-earning\`
        </td>
        <td>\`revenue-counter\`</td>
        <td>
           A string that specifies the type of the widget. You can choose from four options: \`info\`, \`stats\`, \`chart\`, or \`list\`. The type determines the layout and the content of the widget. For example, \`type="user-info"\` will show an \`icon\`, a \`title\`, and a \`description\`, while \`type="revenue-counter"\` will show a \`counter\`, a \`symbol\`, and a \`title\`.
        </td>
    </tr>
    <tr>
        <td>bgColor</td>
        <td>string</td>
        <td>\`#fff\`</td>
        <td>A string that specifies the background color of the widget. You can use any valid CSS color value as the value of \`bgColor\`. The default value is \`#fff\`.</td>
    </tr>
    <tr>
        <td>icon</td>
        <td>string</td>
        <td></td>
        <td>
        A string that specifies the \`icon\` of the widget. You can use any \`icon\` from the \`Font Awesome\` icons library. The \`icon\` will be displayed in the top left corner of the widget. For example \`icon="fa-user"\`.
        </td>
    </tr>
    <tr>
        <td>isCounter</td>
        <td>boolean</td>
        <td>\`false\`</td>
        <td>A boolean that indicates whether the widget has a counter or not. If \`isCounter\` is \`true\`, the widget will show a numeric value that represents a count or a percentage. If \`isCounter\` is \`false\`, the widget will not show a counter. The default value is \`false\`.</td>
    </tr>
    <tr>
        <td>counter</td>
        <td>number</td>
        <td></td>
        <td>A number that specifies the value of the \`counter\`. You can use any positive number as the value of \`counter\`. The \`counter\` will be displayed in the top right corner of the widget. For example \`counter={50}\`.</td>
    </tr>
    <tr>
        <td>symbolPosition</td>
        <td>string</td>
        <td>\`left\`</td>
        <td>A string that specifies the position of the symbol relative to the counter. You can choose from two options: \`left\` or \`right\`. The default value is \`left\`.</td>
    </tr>
    <tr>
        <td>symbol</td>
        <td>string</td>
        <td></td>
        <td>A string that specifies the \`symbol\` that is displayed next to the counter. You can use any valid character or string as the value of \`symbol\`. The \`symbol\` can be used to indicate the unit or the change of the counter. For example, \`symbol="$" \`or \`symbol="%"\`.</td>
    </tr>
    <tr>
        <td>title</td>
        <td>string</td>
        <td></td>
        <td>A string that specifies the \`title\` of the widget. You can use any valid text as the value of \`title\`. The \`title\` will be displayed below the icon and the counter. The \`title\` can be used to give a brief and descriptive name to the widget. For example \`title="Total Revenue"\`.</td>
    </tr>
    <tr>
        <td>description</td>
        <td>string</td>
        <td></td>
        <td>A string that specifies the \`description\` of the widget. You can use any valid text as the value of \`description\`. The \`description\` will be displayed below the title. The \`description\` can be used to give more details or information about the widget. For example \`description="This month's revenue from all sources"\`.</td>
    </tr>
    <tr>
        <td>progressPercent</td>
        <td>number</td>
        <td></td>
        <td>A number that specifies the percentage of the progress bar. You can use any positive number between \`0\` to \`100\` as the value of \`progressPercent\`. The progress bar will be displayed below the description. The progress bar can be used to show the completion or the progress of a task or a process. For example \`progressPercent={75}\`.</td>
    </tr>
    <tr>
        <td>isIconBorder</td>
        <td>boolean</td>
        <td>\`false\`</td>
        <td>A boolean that indicates whether the icon has a border or not. If \`isIconBorder\` is \`true\`, the icon will have a circular border with the same color as the \`borderColor\` prop. If \`isIconBorder\` is \`false\`, the icon will not have a border. The default value is \`false\`.</td>
    </tr>
    <tr>
        <td>borderColor</td>
        <td>string</td>
        <td></td>
        <td>A string that specifies the color of the border of the icon. You can use any valid CSS color value as the value of \`borderColor\`. The border color will also be used as the color of the progress bar. For example \`borderColor="#3b5998"\`.</td>
    </tr>
    <tr>
        <td>padding</td>
        <td>string</td>
        <td>\`16px\`</td>
        <td>A string that specifies the \`padding\` of the widget. You can use any valid CSS value for the \`padding\` property. The \`padding\` will affect the spacing of the widget elements. The default value is \`16px\`.</td>
    </tr>
    <tr>
        <td>width</td>
        <td>string</td>
        <td>\`45px\`</td>
        <td>A string that specifies the \`width\` of the icon. You can use any valid CSS value for the \`width\` property. The \`width\` will affect the size of the icon. The default value is \`45px\`.</td>
    </tr>
    <tr>
        <td>hight</td>
        <td>string</td>
        <td>\`45px\`</td>
        <td> A string that specifies the \`height\` of the icon. You can use any valid CSS value for the \`height\` property. The \`height\` will affect the size of the icon. The default value is \`45px\`.</td>
    </tr>
    <tr>
        <td>isViewMore</td>
        <td>boolean</td>
        <td>\`false\`</td>
        <td>A boolean that indicates whether the widget has a view more link or not. If \`isViewMore\` is \`true\`, the widget will show a link that says “View More” at the bottom of the widget. If \`isViewMore\` is \`false\`, the widget will not show a view more link. The default value is \`false\`.</td>
    </tr>
    <tr>
        <td>link</td>
        <td>string</td>
        <td></td>
        <td>A string that specifies the URL of the view more l\`ink\`. You can use any valid URL as the value of \`link\`. The \`link\` will be opened in a new tab when the user clicks on the view more \`link\`. For example, \`link="https://www.example.com"\`.</td>
    </tr>
</FeatureTable>

<br/>
### Social Counter
The Social Counter component facilitates the display of social media metrics such as followers, friends, and more. Widgets [Preview](https://demo.reactadmin.com/bootstrap/basix-admin/components/widgets)

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <div className="col-4">
                <SocialCounter
                    bgColor="#3B5998"
                    padding="40px"
                    icon="fa-brands fa-facebook-f"
                    count="1875980"
                    iconPosition="top"
                    socialActivity={{
                        followers: 40,
                        countType: "K",
                        followersType: "Friends",
                        usre: 40,
                        feeds: "feeds",
                    }}
                />
            </div>
        </CodePreview>
    </TabItem>
    
    <TabItem value="code" label="Code">
        \`\`\`jsx title="Widgets"
        import React from "react";
        import SocialCounter from "@/components/SocialCounter/SocialCounter";

        const MyComponent = () => {
            return (
                <SocialCounter
                    bgColor="#3B5998"
                    padding="40px"
                    icon="fa-brands fa-facebook-f"
                    count="1875980"
                    iconPosition="top"
                    socialActivity={{
                        followers: 40,
                        countType: "K",
                        followersType: "Friends",
                        usre: 40,
                        feeds: "feeds",
                    }}
                />
            );
        };

        export default MyComponent;
        \`\`\`
    </TabItem>
</Tabs>

## Props

<FeatureTable>
    <tr>
        <td>bgColor</td>
        <td>string</td>
        <td>\`transparent\`</td>
        <td>A string that specifies the background color of the widget. You can use any valid CSS color value as the value of \`bgColor\`. The default value is \`transparent\`.</td>
    </tr>
    <tr>
        <td>icon</td>
        <td>string</td>
        <td></td>
        <td>A string that specifies the \`icon\` of the social media platform or the website. You can use any \`icon\` from the \`Font Awesome\` icons library. The \`icon\` will be displayed in the widget. For example \`icon="fa-twitter"\`.</td>
    </tr>
    <tr>
        <td>iconPosition</td>
        <td>string</td>
        <td>\`left\`</td>
        <td>A string that specifies the position of the icon relative to the count. You can choose from two options: \`left\` or \`right\`. The default value is \`left\`.</td>
    </tr>
    <tr>
        <td>count</td>
        <td>number</td>
        <td>\`0\`</td>
        <td>A number that specifies the value of the \`count\`. You can use any positive number as the value of \`count\`. The \`count\` will be displayed in the widget. For example \`count={5000}\`.</td>
    </tr>
    <tr>
        <td>padding</td>
        <td>string</td>
        <td>\`24px\`</td>
        <td>A string that specifies the \`padding\` of the widget. You can use any valid CSS value for the p\`adding\` property. The \`padding\` will affect the spacing of the widget elements. The default value is \`24px\`.</td>
    </tr>
    <tr>
        <td>isCounter</td>
        <td>boolean</td>
        <td>\`false\`</td>
        <td>A boolean that indicates whether the widget has a counter or not. If \`isCounter\` is \`true\`, the widget will show a numeric value that represents a count or a percentage. If \`isCounter\` is \`false\`, the widget will not show a counter. The default value is \`false\`.</td>
    </tr>
    <tr>
        <td>socialActivity</td>
        <td>object</td>
        <td>\`{}\`</td>
        <td>An object that specifies the social activity of the social media platform or the website. You can use any valid object as the value of \`socialActivity\`. The social activity will be displayed in the widget. The social activity object should have the following properties: \`title\`, \`description\`, \`time\`, and \`link\`. For example \`socialActivity={{title: "New Tweet", description: "Hello world!", time: "10 minutes ago", link: "https://twitter.com/example/status/123456789"}}\`.</td>
    </tr>
</FeatureTable>

<br/>
### User Stats
User Stats component showcases user activity progress and details, offering a concise overview of engagement and interactions. Widgets [Preview](https://demo.reactadmin.com/bootstrap/basix-admin/components/widgets)

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <div className="col-4">
                <UserStats
                    icon="fa-solid fa-users"
                    conunt={87500}
                    title="Visitors"
                    progressBg="#5c6bc0"
                    progressPercent={80}
                />
            </div>
        </CodePreview>
    </TabItem>
    
    <TabItem value="code" label="Code">
        \`\`\`jsx title="Widgets"
        import React from "react";
        import UserStats from "@/components/UserStats/UserStats";

        const MyComponent = () => {
            return (
                <UserStats
                    icon="fa-solid fa-users"
                    conunt={87500}
                    title="Visitors"
                    progressBg="#5c6bc0"
                    progressPercent={80}
                />
            );
        };

        export default MyComponent;
        \`\`\`
    </TabItem>
</Tabs>

## Props

<FeatureTable>
    <tr>
        <td>bgColor</td>
        <td>string</td>
        <td></td>
        <td>A string that specifies the background color of the widget. You can use any valid CSS color value as the value of \`bgColor\`. For example \`bgColor="#3b5998"\`.</td>
    </tr>
    <tr>
        <td>icon</td>
        <td>string</td>
        <td></td>
        <td>A string that specifies the \`icon\` of the user or the group. You can use any \`icon\` from the \`Font Awesome\` icons library. The \`icon\` will be displayed in the widget. For example \`icon="fa-users"\`.</td>
    </tr>
    <tr>
        <td>conunt</td>
        <td>number</td>
        <td>0</td>
        <td>A number that specifies the value of the \`count\`. You can use any positive number as the value of \`count\`. The \`count\` will be displayed in the widget. For example \`count={50}\`. The default value is 0.</td>
    </tr>
    <tr>
        <td>title</td>
        <td>string</td>
        <td></td>
        <td>A string that specifies the \`title\` of the widget. You can use any valid text as the value of title. The title will be displayed in the widget. The \`title\` can be used to give a brief and descriptive name to the user or the group. For example \`title="Total Users"\`.</td>
    </tr>
    <tr>
        <td>size</td>
        <td>string</td>
        <td>\`17px\`</td>
        <td>A string that specifies the \`size\` of the icon and the count. You can use any valid CSS value for the \`font-size\` property. The \`size\` will affect the appearance of the icon and the count. The default value is \`17px\`.</td>
    </tr>
    <tr>
        <td>progressBg</td>
        <td>string</td>
        <td>\`#fff\`</td>
        <td>A string that specifies the background color of the progress bar. You can use any valid CSS color value as the value of \`progressBg\`. The default value is \`#fff\`.</td>
    </tr>
    <tr>
        <td>progressPercent</td>
        <td>number</td>
        <td></td>
        <td>A number that specifies the percentage of the progress bar. You can use any positive number between \`0\` to \`100\` as the value of \`progressPercent\`. The progress bar will be displayed in the widget. The progress bar can be used to show the completion or the progress of a task or a process. For example \`progressPercent={75}\`.</td>
    </tr>
</FeatureTable>
`;

        setStore({ docWidgetsMDX: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default DocWidgetsMDX;
