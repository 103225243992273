import React from "react";
import vertical_nav from "../../../images/nav-type/vertical-nav.png";
import horizontal_nav from "../../../images/nav-type/horizontal-nav.png";
import combo_nav from "../../../images/nav-type/combo-nav.png";
import dual_nav from "../../../images/nav-type/dual-nav.png";
import { useDynamicDashboardData } from "../../../context/dashboardDataContext";

const NavigationType = () => {
    const { navigationType, setNavigationType } = useDynamicDashboardData();

    const navType = [
        {
            id: 1,
            name: "Vertical",
            url: vertical_nav,
        },
        {
            id: 2,
            name: "Horizontal",
            url: horizontal_nav,
        },
        {
            id: 3,
            name: "Combo",
            url: combo_nav,
        },
        {
            id: 4,
            name: "Dual Nav",
            url: dual_nav,
        },
    ];

    return (
        <div className="col-span-8">
            <div className="p-5">
                <div className="grid grid-cols-4 gap-3">
                    {navType.map((item) => {
                        return (
                            <div
                                key={item.id}
                                className={`p-2 rounded-[4px] border-[2px]  ${
                                    item.name.toLowerCase().replace(/\s/g, "_") === navigationType
                                        ? "border-[#5966d2]"
                                        : "border-[transparent]"
                                }`}
                                onClick={() =>
                                    setNavigationType(item.name.toLowerCase().replace(/\s/g, "_"))
                                }>
                                <label htmlFor="navbarType">
                                    <img src={item.url} alt="navbar-type" />
                                </label>
                                <span
                                    className="text-[#24263a] text-xs leading-[15px] font-bold"
                                    id="navbarType">
                                    {item.name}
                                </span>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default NavigationType;
