import { useEffect } from "react";
import { useFileStore } from "../../../../../store/useFileStore";

const SidebarBgControlScss = () => {
    const [_, setStore] = useFileStore((store) => store.sidebarBgControlScss);

    useEffect(() => {
        let value = `.switcher_wrapper {
    padding: 16px 0;
    .title {
        font-size: 14px;
        color: var(--hedging-text-color);
        font-weight: 700;
        line-height: 20px;
        margin: 0;
        -webkit-user-select: none;
        user-select: none;
    }
    .bg_container {
        display: flex;
        gap: 4px;
        margin-top: 12px;
        img {
            width: 46px;
            height: 96px;
            border: 2px solid var(--border-color);
            border-radius: 6px;
        }
        .active {
            span {
                &::after {
                    position: absolute;
                    content: "✓";
                    top: 36%;
                    left: 0;
                    font-family: Fontawesome;
                    font-size: 20px;
                    text-align: center;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
`;

        setStore({ sidebarBgControlScss: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default SidebarBgControlScss;
