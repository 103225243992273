import { useEffect } from "react";
import { useFileStore } from "../../../../store/useFileStore";

const DocFormsCategoryJson = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store);

    useEffect(() => {
        let value = `{
    "label": "Forms",
    "position": 5,
    "collapsible": false,
    "collapsed": false
}`;

        setStore({ docFormsCategoryJson: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default DocFormsCategoryJson;
