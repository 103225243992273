import { useEffect } from "react";
import { useFileStore } from "../../../../../store/useFileStore";

const DocFeatureTableJSX = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store);

    useEffect(() => {
        let value = `import React from "react";

function FeatureTable({ thead = ["Name", "Type", "Default", "Description"], children }) {
    return (
        <table className="featureTable">
            <thead>
                <tr>
                    {thead?.map((item, i) => (
                        <td key={i} className="td">
                            {item}
                        </td>
                    ))}
                </tr>
            </thead>
            <tbody>{children}</tbody>
        </table>
    );
}

export default FeatureTable;
`;

        setStore({ docFeatureTableJSX: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default DocFeatureTableJSX;
