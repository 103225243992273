import { useEffect } from "react";
import { useCodebaseContext } from "../admin-templates/base/context/dashboardCodebaseContext";

const Redirects = () => {
    const { setRedirects } = useCodebaseContext();
    useEffect(() => {
        const value = `/* /index.html 200`;
        setRedirects(value);
    }, [setRedirects]);
    return null;
};

export default Redirects;
