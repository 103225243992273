import ColorPicker from "../../../../../../components/ColorPicker/ColorPicker";
import { useDynamicDashboardData } from "../../context/dashboardDataContext";

const Colors = () => {
    const {
        sidebarText,
        setSidebarText,
        sidebarBg,
        setSidebarBg,
        topNavText,
        serTopNavText,
        topNavBg,
        setTopNavBg,
    } = useDynamicDashboardData();

    return (
        <div className="grid grid-cols-2">
            <div className="border-[1px] border-[#D8D8D8] border-r-0 p-5">
                <h2 className="block text-[#24263a] text-xs leading-[15px] font-bold">Sidebar</h2>
                <ColorPicker title="Text" pickColor={sidebarText} serPickColor={setSidebarText} />
                <ColorPicker title="Background" pickColor={sidebarBg} serPickColor={setSidebarBg} />
            </div>
            <div className="border-[1px] border-[#D8D8D8]  p-5">
                <h2 className="block text-[#24263a] text-xs leading-[15px] font-bold">
                    Top Navbar
                </h2>
                <ColorPicker title="Text" pickColor={topNavText} serPickColor={serTopNavText} />
                <ColorPicker title="Background" pickColor={topNavBg} serPickColor={setTopNavBg} />
            </div>
        </div>
    );
};

export default Colors;
