import { useEffect } from "react";
import { useFileStore } from "../../../../store/useFileStore";

const DocCardMDX = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store);

    useEffect(() => {
        let value = `---
sidebar_position: 4
sidebar_label: Card
---

# Card

The Card component is a container that groups related content and actions on a single topic. The Card component can have different parts, such as a header, a media, a content, and an actions area. The Card component can also have different variants. The Card component can be used to display information, images, links, or buttons

You can find the Cards component in the \`src/views/Cards.jsx\` file

import { Col } from "react-bootstrap";
import FeatureTable from "@site/src/components/FeatureTable/FeatureTable";
import CodePreview from "@site/src/components/CodePreview/CodePreview";
import Card from "@site/src/components/Card/Card"
import image from "@site/src/assets/images/admin.jpg"
import placeholderImage from "@site/src/assets/images/placeholder.png"
import Tabs from '@theme/Tabs';
import TabItem from '@theme/TabItem';

## Examples

### With Header
A card component with a header section provides structured content presentation and visual hierarchy for streamlined information display. Card [Preview](https://demo.reactadmin.com/bootstrap/basix-admin/components/cards)

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <Col md={6}>
                <Card 
                    title="Card" 
                    titleIcon="fa-solid fa-user" 
                    icons={[{ icon: "fa fa-cog"}, { icon: "fa fa-angle-down" }]}
                    dismissible={true}
                >
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                </Card>
            </Col>
        </CodePreview>
    </TabItem>
    
    <TabItem value="code" label="Code">

        \`\`\`jsx title="Card"
        import Card from "@/components/Card/Card"

        function MyComponent() {
            return (
                <Card 
                    title="Card" 
                    titleIcon="fa-solid fa-user" 
                    icons={[{ icon: "fa fa-cog"}, { icon: "fa fa-angle-down" }]}
                    dismissible={true}
                >
                    Lorem Ipsum is simply dummy text of the printing and typesetting 
                    industry. Lorem Ipsum has been the industry's standard dummy 
                    text ever since the 1500s, when an unknown printer took a galley 
                    of type and scrambled it to make a type specimen book.
                </Card>
            );
        }

        export default MyComponent;
        \`\`\`
    </TabItem>
</Tabs>

<br/>
### Without Header
Card can also contain jsx or another component without header. Card [Preview](https://demo.reactadmin.com/bootstrap/basix-admin/components/cards) 

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <Col md={6}>
                <Card isHeader={false}>
                    Lorem Ipsum is simply dummy text of the printing and typesetting 
                    industry. Lorem Ipsum has been the industry's standard dummy 
                    text ever since the 1500s, when an unknown printer took a galley 
                    of type and scrambled it to make a type specimen book.
                </Card>
            </Col>
        </CodePreview>
    </TabItem>
    
    <TabItem value="code" label="Code">

        \`\`\`jsx title="Card"
        import Card from "@/components/Card/Card"

        function MyComponent() {
            return (
                <Card isHeader={false}>
                    Lorem Ipsum is simply dummy text of the printing and typesetting 
                    industry. Lorem Ipsum has been the industry's standard dummy 
                    text ever since the 1500s, when an unknown printer took a galley 
                    of type and scrambled it to make a type specimen book.
                </Card>
            );
        }

        export default MyComponent;
        \`\`\`
    </TabItem>
</Tabs>

<br />
### Card With Image
A versatile card component featuring an image and additional elements for visually appealing and informative content presentation. Card [Preview](https://demo.reactadmin.com/bootstrap/basix-admin/components/cards)

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <Col md={6}>
                <Card
                    isHeader={false}
                >
                    <img src={placeholderImage} alt="" style={{width: "100%"}}  />
                    <h2 className="fs-5 mt-3">Card Wtih Image Title</h2>
                    <p className="fs-6 mt-2">
                        Some quick example text to build on the card
                        title and make up the bulk of the card's
                        content.
                    </p>
                </Card>
            </Col>
        </CodePreview>
    </TabItem>
    
    <TabItem value="code" label="Code">

        \`\`\`jsx title="Card"
        import Card from "@/components/Card/Card"

        function MyComponent() {
            return (
                <Card
                    isHeader={false}
                >
                    <img src={...img} alt="" style={{width: "100%"}}  />
                    <h2 className="fs-5 mt-3">Card Wtih Image Title</h2>
                    <p className="fs-6 mt-2">
                        Some quick example text to build on the card
                        title and make up the bulk of the card's
                        content.
                    </p>
                </Card>
            );
        }

        export default MyComponent;
        \`\`\`
    </TabItem>
</Tabs>

## Props

<FeatureTable>
    <tr>
        <td>title</td>
        <td>string</td>
        <td></td>
        <td>A string that specifies the \`title\` of the card. The \`title\` will be displayed in the header area of the card. You can use this prop to give a brief and descriptive name to the card. For example \`title="User Profile"\`.</td>
    </tr>
    <tr>
        <td>titleIcon</td>
        <td>string</td>
        <td></td>
        <td>A string that specifies the icon of the card. The icon will be displayed next to the title in the header area of the card. You can use this prop to add a visual cue to the card. You can use any icon from the \`Font Awesome\` icons library. For example, \`titleIcon="fa-user"\`.</td>
    </tr>
    <tr>
        <td>titleBg</td>
        <td>string</td>
        <td></td>
        <td>A string that specifies the background color of the title area of the card. You can use this \`prop\` to change the appearance of the \`title\` area. </td>
    </tr>
    <tr>
        <td>isHeader</td>
        <td>boolean</td>
        <td>\`true\`</td>
        <td>A boolean that indicates whether the card has a header area or not. If \`isHeader\` is \`true\`, the card will have a header area that contains the \`title\`, the \`icon\`, and the \`icons\` array. If \`isHeader\` is \`false\`, the card will not have a header area. The default value is \`true\`.</td>
    </tr>
    <tr>
        <td>headerPosition</td>
        <td>\`top\`, \`bottom\`</td>
        <td>\`top\`</td>
        <td>A string that specifies the position of the header area of the card. You can use this prop to change the layout of the card. You can choose from two options: \`top\` or \`bottom\`. The default value is \`top\`.</td>
    </tr>
    <tr>
        <td>padding</td>
        <td>string</td>
        <td>\`16px\`</td>
        <td>A string that specifies the \`padding\` of the card. You can use this prop to change the spacing of the card. You can use any valid CSS value for the \`padding\` property. The default value is \`16px\`</td>
    </tr>
    <tr>
        <td>bodyBg</td>
        <td>string</td>
        <td></td>
        <td>A string that specifies the background color of the body area of the card. You can use \`bodyBg\` to change the appearance of the body area.</td>
    </tr>
    <tr>
        <td>icons</td>
        <td>array</td>
        <td>\`[]\`</td>
        <td>An array that specifies the icons that are displayed in the header area of the card. You can use this prop to add more actions or options to the card. You can use any icon from the \`Font Awesome\` icons library. Each icon should be an object with the following properties: \`icon\`, \`color\`, and \`onClick\`. For example, \`icons={[{icon: "fa-edit", color: "primary", onClick: () => console.log("Edit")}, {icon: "fa-trash", color: "danger", onClick: () => console.log("Delete")}]}\`</td>
    </tr>
    <tr>
        <td>dismissible</td>
        <td>boolean</td>
        <td>\`false\`</td>
        <td>A boolean that indicates whether the card can be dismissed by the user or not. If \`dismissible\` is \`true\`, a close button will appear on the right side of the header area of the card. If \`dismissible\` is \`false\`, the card will not have a close button. The default value is \`false\`.</td>
    </tr>
    <tr>
        <td>onClose</td>
        <td>func</td>
        <td></td>
        <td>A function that is called when the card is \`dismissed\` by the user. The function receives the event object as the argument. You can use this prop to perform any action or logic when the card is \`dismissed\`. For example, you can use \`onClose\` to remove the card from the parent component or to trigger another alert.</td>
    </tr>

</FeatureTable>
`;

        setStore({ docCardMDX: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default DocCardMDX;
