import { useEffect } from "react";
import { useFileStore } from "../../../../../store/useFileStore";

const FlipClockScss = () => {
    const [_, setStore] = useFileStore((store) => store.flipClockScss);

    useEffect(() => {
        let value = `.flipClock {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding-top: 4px;
}

.flipUnitContainer {
    display: block;
    position: relative;
    width: 36px;
    height: 30px;
    perspective-origin: 50% 50%;
    perspective: 300px;
    background-color: #1d1c1c;
    border-radius: 3px;
    // box-shadow: 0px 10px 10px -10px grey;
}

.upperCard,
.lowerCard {
    display: flex;
    position: relative;
    justify-content: center;
    width: 100%;
    height: 50%;
    overflow: hidden;
    border: 1px solid #1d1c1c;
}
.upperCard span,
.lowerCard span {
    font-size: 1.3em;
    font-weight: 600;
    color: #fff;
}

.upperCard {
    align-items: flex-end;
    border-bottom: 0.5px solid #1d1c1c;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}
.upperCard span {
    transform: translateY(50%);
}

.lowerCard {
    align-items: flex-start;
    border-top: 0.5px solid #1d1c1c;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}
.lowerCard span {
    transform: translateY(-50%);
}

.flipCard {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    width: 100%;
    height: 50%;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.flipCard span {
    font-size: 1.3em;
    font-weight: 600;
    color: #fff;
}
.flipCard.unfold {
    top: 50%;
    align-items: flex-start;
    transform-origin: 50% 0%;
    transform: rotateX(180deg);
    background-color: #393939;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border: 0.5px solid #393939;
    border-top: 0.5px solid #393939;
}
.flipCard.unfold span {
    transform: translateY(-50%);
}
.flipCard.fold {
    top: 0%;
    align-items: flex-end;
    transform-origin: 50% 100%;
    transform: rotateX(0deg);
    background-color: #393939;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border: 0.5px solid #393939;
    border-bottom: 0.5px solid #393939;
}
.flipCard.fold span {
    transform: translateY(50%);
}

.fold {
    -webkit-animation: fold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
    animation: fold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
    transform-style: preserve-3d;
}

.unfold {
    -webkit-animation: unfold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
    animation: unfold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
    transform-style: preserve-3d;
}
.title {
    font-size: 9px;
    font-weight: 500;
    text-align: center;
}

@-webkit-keyframes fold {
    0% {
        transform: rotateX(0deg);
    }
    100% {
        transform: rotateX(-180deg);
    }
}

@keyframes fold {
    0% {
        transform: rotateX(0deg);
    }
    100% {
        transform: rotateX(-180deg);
    }
}
@-webkit-keyframes unfold {
    0% {
        transform: rotateX(180deg);
    }
    100% {
        transform: rotateX(0deg);
    }
}
@keyframes unfold {
    0% {
        transform: rotateX(180deg);
    }
    100% {
        transform: rotateX(0deg);
    }
}`;

        setStore({ flipClockScss: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default FlipClockScss;
