import React, { useState } from "react";
import { useDynamicDashboardData } from "../../../context/dashboardDataContext";
import Tabs from "../../../../../../../components/Tabs/Tabs";
import AddFile from "./index";

const Dependencies = () => {
    const {
        addDependencies,
        setAddDependencies,
        addDevDependencies,
        setAddDevDependencies,
    } = useDynamicDashboardData();

    const [firstFilesTabs, setFirstFilesTabs] = useState(1);

    const fileName = [
        {
            id: 1,
            name: "Dependencies",
        },
        {
            id: 2,
            name: "Dev-Dependencies",
        },
    ];

    return (
        <div className="border-[1px] border-[#D8D8D8] rounded-[2px]">
            <Tabs
                tabs={firstFilesTabs}
                setTabs={setFirstFilesTabs}
                tabName={fileName}
                tabsItems={
                    <div className="px-5 pb-5 mt-10">
                        {firstFilesTabs === 1 && (
                            <AddFile
                                addItem={addDependencies}
                                setAddItem={setAddDependencies}
                            />
                        )}
                        {firstFilesTabs === 2 && (
                            <AddFile
                                addItem={addDevDependencies}
                                isAddItem={setAddDevDependencies}
                            />
                        )}
                    </div>
                }
            />
        </div>
    );
};

export default Dependencies;
