import Accordion from "../../../../../../../components/Accordion/Accordion";
import TextControl from "../../../../../../../components/TextControl/TextControl";
import { useDynamicDashboardData } from "../../../context/dashboardDataContext";

function SwitcherFields() {
    const { projectInfo, setProjectInfo } = useDynamicDashboardData();

    return (
        <Accordion title="Switcher (for Google Sheet)">
            <div className="p-5">
                <div className="grid grid-cols-2 gap-4 mb-4">
                    <TextControl
                        label="Sheet ID"
                        id="sheet_id"
                        placeholder="Google Sheet ID here..."
                        value={projectInfo?.sheetId}
                        onChange={(e) =>
                            setProjectInfo({
                                ...projectInfo,
                                sheetId: e.target.value,
                            })
                        }
                    />
                    <TextControl
                        label="Tab ID"
                        id="tab_id"
                        placeholder="Tab ID here..."
                        value={projectInfo?.tabId}
                        onChange={(e) =>
                            setProjectInfo({
                                ...projectInfo,
                                tabId: e.target.value,
                            })
                        }
                    />
                </div>
                <div className="grid grid-cols-2 gap-4">
                    <TextControl
                        label="Product Slug"
                        id="product_slug"
                        placeholder="Product Slug here..."
                        value={projectInfo?.productSlug}
                        onChange={(e) =>
                            setProjectInfo({
                                ...projectInfo,
                                productSlug: e.target.value,
                            })
                        }
                    />
                </div>
            </div>
        </Accordion>
    );
}

export default SwitcherFields;
