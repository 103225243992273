import { useEffect } from "react";
import { useFileStore } from "../../../../store/useFileStore";

const DocAdvancedFormMDX = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store);

    useEffect(() => {
        let value = `---
sidebar_position: 2
sidebar_label: Advanced Form
---

# Advanced Form

An advanced form is a more complex and powerful way to collect user input. An advanced form can include features such as Date input, Credit Card Number, Phone input and more.

You can find the Advanced Form in the \`src/views/AdvancedForm.jsx\` file

import CodePreview from "@site/src/components/CodePreview/CodePreview";
import Tabs from '@theme/Tabs';
import TabItem from '@theme/TabItem';

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <form>
                <div className="form-group mb-3">
                    <label className="form-control-label mb-2">Date input</label>
                    <div className="input-group">
                        <span
                            className="input-group-text"
                            style={{
                                backgroundColor: "var(--bg-content)",
                                borderColor: "var(--border-color)",
                                color: "var(  --hedging-text-color)",
                            }}>
                            <i className="fa fa-calendar"></i>
                        </span>
                        <input className="form-control" />
                    </div>
                    <small>ex. 99/99/9999</small>
                </div>
                <div className="form-group mb-3">
                    <label className="form-control-label mb-2">Phone input</label>
                    <div className="input-group">
                        <span
                            className="input-group-text"
                            style={{
                                backgroundColor: "var(--bg-content)",
                                borderColor: "var(--border-color)",
                                color: "var(  --hedging-text-color)",
                            }}>
                            <i className="fa fa-phone"></i>
                        </span>
                        <input className="form-control" />
                    </div>
                    <small>ex. (999) 999-9999</small>
                </div>
                <div className="form-group mb-3">
                    <label className="form-control-label mb-2">Taxpayer Identification Numbers</label>
                    <div className="input-group">
                        <span
                            className="input-group-text"
                            style={{
                                backgroundColor: "var(--bg-content)",
                                borderColor: "var(--border-color)",
                                color: "var(  --hedging-text-color)",
                            }}>
                            <i className="fa fa-usd"></i>
                        </span>
                        <input className="form-control" />
                    </div>
                    <small>ex. 99-9999999</small>
                </div>
                <div className="form-group mb-3">
                    <label className="form-control-label mb-2">Social Security Number</label>
                    <div className="input-group">
                        <span
                            className="input-group-text"
                            style={{
                                backgroundColor: "var(--bg-content)",
                                borderColor: "var(--border-color)",
                                color: "var(  --hedging-text-color)",
                            }}>
                            <i className="fa fa-male"></i>
                        </span>
                        <input className="form-control" />
                    </div>
                    <small>ex. 999-99-9999</small>
                </div>
                <div className="form-group mb-3">
                    <label className="form-control-label mb-2">Eye Script</label>
                    <div className="input-group">
                        <span
                            className="input-group-text"
                            style={{
                                backgroundColor: "var(--bg-content)",
                                borderColor: "var(--border-color)",
                                color: "var(  --hedging-text-color)",
                            }}>
                            <i className="fa fa-asterisk"></i>
                        </span>
                        <input className="form-control" />
                    </div>
                    <small>ex. ~9.99 ~9.99 999</small>
                </div>
                <div className="form-group mb-3">
                    <label className="form-control-label mb-2">Credit Card Number</label>
                    <div className="input-group">
                        <span
                            className="input-group-text"
                            style={{
                                backgroundColor: "var(--bg-content)",
                                borderColor: "var(--border-color)",
                                color: "var(  --hedging-text-color)",
                            }}>
                            <i className="fa fa-credit-card"></i>
                        </span>
                        <input className="form-control" />
                    </div>
                    <small>ex. 9999 9999 9999 9999</small>
                </div>
            </form>
        </CodePreview>
    </TabItem>
    
    <TabItem value="code" label="Code">

        \`\`\`jsx title="Advanced Form"
        import React from "react";

        const MyComponent = () => {
            return (
                <form>
                    <div className="form-group mb-3">
                        <label className="form-control-label mb-2">Date input</label>
                        <div className="input-group">
                            <span
                                className="input-group-text"
                                style={{
                                    backgroundColor: "var(--bg-content)",
                                    borderColor: "var(--border-color)",
                                    color: "var(  --hedging-text-color)",
                                }}>
                                <i className="fa fa-calendar"></i>
                            </span>
                            <input className="form-control" />
                        </div>
                        <small>ex. 99/99/9999</small>
                    </div>
                    <div className="form-group mb-3">
                        <label className="form-control-label mb-2">Phone input</label>
                        <div className="input-group">
                            <span
                                className="input-group-text"
                                style={{
                                    backgroundColor: "var(--bg-content)",
                                    borderColor: "var(--border-color)",
                                    color: "var(  --hedging-text-color)",
                                }}>
                                <i className="fa fa-phone"></i>
                            </span>
                            <input className="form-control" />
                        </div>
                        <small>ex. (999) 999-9999</small>
                    </div>
                    <div className="form-group mb-3">
                        <label className="form-control-label mb-2">Taxpayer Identification Numbers</label>
                        <div className="input-group">
                            <span
                                className="input-group-text"
                                style={{
                                    backgroundColor: "var(--bg-content)",
                                    borderColor: "var(--border-color)",
                                    color: "var(  --hedging-text-color)",
                                }}>
                                <i className="fa fa-usd"></i>
                            </span>
                            <input className="form-control" />
                        </div>
                        <small>ex. 99-9999999</small>
                    </div>
                    <div className="form-group mb-3">
                        <label className="form-control-label mb-2">Social Security Number</label>
                        <div className="input-group">
                            <span
                                className="input-group-text"
                                style={{
                                    backgroundColor: "var(--bg-content)",
                                    borderColor: "var(--border-color)",
                                    color: "var(  --hedging-text-color)",
                                }}>
                                <i className="fa fa-male"></i>
                            </span>
                            <input className="form-control" />
                        </div>
                        <small>ex. 999-99-9999</small>
                    </div>
                    <div className="form-group mb-3">
                        <label className="form-control-label mb-2">Eye Script</label>
                        <div className="input-group">
                            <span
                                className="input-group-text"
                                style={{
                                    backgroundColor: "var(--bg-content)",
                                    borderColor: "var(--border-color)",
                                    color: "var(  --hedging-text-color)",
                                }}>
                                <i className="fa fa-asterisk"></i>
                            </span>
                            <input className="form-control" />
                        </div>
                        <small>ex. ~9.99 ~9.99 999</small>
                    </div>
                    <div className="form-group mb-3">
                        <label className="form-control-label mb-2">Credit Card Number</label>
                        <div className="input-group">
                            <span
                                className="input-group-text"
                                style={{
                                    backgroundColor: "var(--bg-content)",
                                    borderColor: "var(--border-color)",
                                    color: "var(  --hedging-text-color)",
                                }}>
                                <i className="fa fa-credit-card"></i>
                            </span>
                            <input className="form-control" />
                        </div>
                        <small>ex. 9999 9999 9999 9999</small>
                    </div>
                </form>
            )
        }

        export default MyComponent;
        \`\`\`
    </TabItem>
</Tabs>

For more form please visit [React Bootstrap](https://react-bootstrap.netlify.app/docs/forms/overview)
`;

        setStore({ docAdvancedFormMDX: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default DocAdvancedFormMDX;
