import { CodebaseContextProvider } from "./context/dashboardCodebaseContext";
import { DashboardDataProvider } from "./context/dashboardDataContext";
import { ModalContextProvider } from "./context/dashboardModalContext";
import { DashboardNavMenuProvider } from "./context/dashboardNavMenuProvider";
import Layout from "./Layout";
import useFileDataStore from "./store/useFileDataStore";
import { FileStoreContext } from "./store/useFileStore";

function Base() {
    return (
        <div>
            <FileStoreContext.Provider value={useFileDataStore()}>
                <ModalContextProvider>
                    <DashboardDataProvider>
                        <CodebaseContextProvider>
                            <DashboardNavMenuProvider>
                                <Layout />
                            </DashboardNavMenuProvider>
                        </CodebaseContextProvider>
                    </DashboardDataProvider>
                </ModalContextProvider>
            </FileStoreContext.Provider>
        </div>
    );
}

export default Base;
