import { useEffect } from "react";
import { useFileStore } from "../../../../store/useFileStore";

const DocButtonMDX = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store);

    useEffect(() => {
        let value = `---
sidebar_position: 3
sidebar_label: Button
---

# Button

The Button component is a clickable element that triggers an action or a navigation. The Button component can have different types and different variants. The Button component can also have icons, labels, or both.

You can find the Button component in the \`src/views/Buttons.jsx\` file.

import Button from "react-bootstrap/Button";
import FeatureTable from "@site/src/components/FeatureTable/FeatureTable";
import CodePreview from "@site/src/components/CodePreview/CodePreview";
import Tabs from '@theme/Tabs';
import TabItem from '@theme/TabItem';


## Examples
Use any of the available button style types to quickly create a styled button. Just modify the variant prop. Button [Preview](https://demo.reactadmin.com/bootstrap/basix-admin/components/buttons)

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <div className="d-flex gap-2">
                <Button variant="primary">Primary</Button>
                <Button variant="secondary">Secondary</Button>
                <Button variant="success">Success</Button>
                <Button variant="warning">Warning</Button>
                <Button variant="danger">Danger</Button>
                <Button variant="info">Info</Button>
                <Button variant="light">Light</Button>
                <Button variant="dark">Dark</Button>
                <Button variant="link">Link</Button>
            </div>
            
        </CodePreview>
    </TabItem>
    
    <TabItem value="code" label="Code">

        \`\`\`jsx title="Buttons"
        import Button from "react-bootstrap/Button";

        function MyComponent() {
            return (
                <>
                    <Button variant="primary">Primary</Button>
                    <Button variant="secondary">Secondary</Button>
                    <Button variant="success">Success</Button>
                    <Button variant="warning">Warning</Button>
                    <Button variant="danger">Danger</Button>
                    <Button variant="info">Info</Button>
                    <Button variant="light">Light</Button>
                    <Button variant="dark">Dark</Button>
                    <Button variant="link">Link</Button>
                </>
            );
        }

        export default MyComponent;
        \`\`\`
    </TabItem>
</Tabs>

<br/>
### Outline
For a lighter touch, Buttons also come in outline-* variants with no background color. Button [Preview](https://demo.reactadmin.com/bootstrap/basix-admin/components/buttons)

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <div className="d-flex gap-2">
                <Button variant="outline-primary">Primary</Button>
                <Button variant="outline-secondary">Secondary</Button>
                <Button variant="outline-success">Success</Button>
                <Button variant="outline-warning">Warning</Button>
                <Button variant="outline-danger">Danger</Button>
                <Button variant="outline-info">Info</Button>
                <Button variant="outline-light">Light</Button>
                <Button variant="outline-dark">Dark</Button>
                <Button variant="outline-link">Link</Button>
            </div>
            
        </CodePreview>
    </TabItem>
    
    <TabItem value="code" label="Code">

        \`\`\`jsx title="Buttons"
        import Button from "react-bootstrap/Button";

        function MyComponent() {
            return (
                <>
                    <Button variant="outline-primary">Primary</Button>
                    <Button variant="outline-secondary">Secondary</Button>
                    <Button variant="outline-success">Success</Button>
                    <Button variant="outline-warning">Warning</Button>
                    <Button variant="outline-danger">Danger</Button>
                    <Button variant="outline-info">Info</Button>
                    <Button variant="outline-light">Light</Button>
                    <Button variant="outline-dark">Dark</Button>
                    <Button variant="outline-link">Link</Button>
                </>
            );
        }

        export default TypesExample;
        \`\`\`
    </TabItem>
</Tabs>

<br/>
### Sizes
Defarent sizes button are available. Simply add \`size="lg"\`, \`size="sm"\` for additional sizes. Button [Preview](https://demo.reactadmin.com/bootstrap/basix-admin/components/buttons)

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <div className="mb-2">
                <Button variant="primary" size="lg">Large Button</Button>
            </div>
            <div className="mb-2">
                <Button variant="primary">Normal Button</Button>
            </div>
            <div className="mb-2">
                <Button variant="primary" size="sm">Small Button</Button>
            </div>
        </CodePreview>
    </TabItem>
    
    <TabItem value="code" label="Code">

        \`\`\`jsx title="Buttons"
        import Button from "react-bootstrap/Button";

        function MyComponent() {
            return (
                <>
                    <Button variant="primary" size="lg">Large Button</Button>
                    <Button variant="primary">Normal Button</Button>
                    <Button variant="primary" size="sm">Small Button</Button>
                </>
            );
        }

        export default TypesExample;
        \`\`\`
    </TabItem>
</Tabs>

<br/>
## Props

<FeatureTable>
    <tr>
        <td>variant</td>
        <td>string</td>
        <td>\`primary\`</td>
        <td>A string that specifies the type and the variant of the button. You can choose variety of visual variants such as: <br />
        \`primary\`, \`secondary\`, \`success\`, \`danger\`, \`warning\`, \`info\`, \`dark\`, \`light\`, \`link\` as well as \`outline\` versions (prefixed by outline-*) \`outline-primary\`, \`outline-secondary\`, \`outline-success\`, \`outline-danger\`, \`outline-warning\`, \`outline-info\`, \`outline-dark\`, \`outline-light\`. The default value is \`primary\`</td>
    </tr>
    <tr>
        <td>size</td>
        <td>\`sm\`, \`lg\`</td>
        <td></td>
        <td>A string that specifies the \`size\` of the button. You can choose from two options: \`sm\` or \`lg\`. </td>
    </tr> 
    <tr>
        <td>onClick</td>
        <td>func</td>
        <td></td>
        <td>A function that is called when the button is clicked. The function receives the event object as the argument. You can use this prop to perform any action or logic when the button is clicked. For example, you can use \`onClick\` to navigate to another page, submit a form, or open a modal.</td>
    </tr>
    <tr>
        <td>active</td>
        <td>boolean</td>
        <td>\`false\`</td>
        <td>A boolean that indicates whether the button is \`active\` or not. If \`active\` is \`true\`, the button will have a darker background color and a shadow effect. If \`active\` is \`false\`, the button will have a normal appearance. The default value is \`false\`</td>
    </tr>
    <tr>
        <td>disabled</td>
        <td>boolean</td>
        <td>\`false\`</td>
        <td>A boolean that indicates whether the button is \`disabled\` or not. If \`disabled\` is \`true\`, the button will have a lighter background color and a \`disabled\` cursor. If \`disabled\` is \`false\`, the button will be enabled and clickable. The default value is \`false\`.</td>
    </tr>
</FeatureTable>

For more props please visit to <a href="https://react-bootstrap.netlify.app/docs/components/buttons/#button" target="_blank">react-bootstrap</a>
`;

        setStore({ docButtonMDX: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default DocButtonMDX;
