import { useEffect } from "react";
import { useFileStore } from "../../../store/useFileStore";

const DocNotice = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store);

    useEffect(() => {
        let value = `import ExecutionEnvironment from "@docusaurus/ExecutionEnvironment";
import { createRoot } from "react-dom/client";
import App from "../src/components/Notice/App";
import { waitForElm } from "../utils/waitForElm";

if (ExecutionEnvironment.canUseDOM) {
    // As soon as the site loads in the browser, register a global event listener
    waitForElm("#notice-root").then((elm) => {
        /**
         * React App Setup
         */
        const root = createRoot(elm);
        root.render(<App />);


        /**
         * Light or Dark mode for Bootstrap.
         */
        const lightDarkBtn = document.querySelector(".navbar__items--right > div > .clean-btn");


        /**
         * Check light or dark mode set on html tag then bootstrap attributes set html tag
         */
        const lightOrDark = document.documentElement.getAttribute("data-theme");
        
        if(lightOrDark === "dark") {
            document.documentElement.setAttribute('data-bs-theme', "dark");
        }
        
        if(lightOrDark === "light") {
            document.documentElement.setAttribute('data-bs-theme', "light");
        }

        /**
         * Toggle button click to set bootstrap attributes
         */
        lightDarkBtn.addEventListener("click", (e) => {
            const mode = document.documentElement.getAttribute("data-theme");

            if(mode === "light") {
                document.documentElement.setAttribute('data-bs-theme', "dark");
            }

            if(mode === "dark") {
                document.documentElement.setAttribute('data-bs-theme', "light");
            }
        })
    });

   
}
`;

        setStore({ docNotice: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default DocNotice;
