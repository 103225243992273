import { useEffect } from "react";
import { useDynamicDashboardData } from "../context/dashboardDataContext";
import { useFileStore } from "../store/useFileStore";

const IndexHtml = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.indexHtml);
    const { projectInfo } = useDynamicDashboardData();

    useEffect(() => {
        let value = `<!DOCTYPE html>
<html lang="en">
    <head>
        <meta charset="UTF-8" />
        <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <!-- font awesome -->
        <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css"
            integrity="sha512-xh6O/CkQoPOWDdYTDqeRdPCVd1SpvCA9XXcUnZS2FmJNp1coAFzvtCN9BmamE+4aHK8yyUHUSCcJHgXloTyT2A=="
            crossorigin="anonymous"
            referrerpolicy="no-referrer"
        />

        <!-- google font -->
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
            href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700;800;900&family=Open+Sans:wght@400;500;600;700;800&display=swap"
            rel="stylesheet"
        />
        <title>${projectInfo.projectName}</title>
    </head>
    <body>
        <div id="root"></div>
        <script type="module" src="/src/main.jsx"></script>
    </body>
</html>
`;

        setStore({ indexHtml: value });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectInfo.projectName]);
    return null;
};

export default IndexHtml;
