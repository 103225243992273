import React, { useEffect, useState } from "react";
import Cascader from "rsuite/Cascader";
import Show from "./Show";
import { useGlobalStore } from "../../store/useGlobalStore";

const data = [
    {
        label: "Bootstrap",
        value: "bootstrap",
        children: [
            {
                label: "Admin Templates",
                value: "bootstrap_admin_templates",
                children: [
                    {
                        label: "Base",
                        value: "bootstrap_admin_templates_base",
                    },
                ],
            },
            {
                label: "Landing Page",
                value: "bootstrap_landing_page",
            },
        ],
    },
    {
        label: "Tailwind",
        value: "tailwind",
        children: [
            {
                label: "Admin Templates",
                value: "tailwind_admin_templates",
                children: [
                    {
                        label: "Base",
                        value: "tailwind_admin_templates_base",
                    },
                ],
            },
            {
                label: "Landing Page",
                value: "tailwind_landing_page",
                children: [
                    {
                        label: "Base",
                        value: "tailwind_landing_page_base",
                    },
                ],
            },
        ],
    },
];

const Layout = () => {
    const [inputValue, setInputValue] = useState("bootstrap_admin_templates_base");

    // eslint-disable-next-line no-unused-vars
    const [fieldValue, setGlobalStore] = useGlobalStore((store) => store.libName);

    useEffect(() => {
        setGlobalStore({ libName: inputValue?.split("_")?.[0] });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue]);

    return (
        <div className="p-[80px] mx-auto">
            <div className="mb-[80px]">
                <h1 className="text-black-500 text-3xl font-bold leading-[2.75rem] uppercase text-center">
                    React Generator
                </h1>
                <p className="text-[#24263a] text-xl font-light text-center mt-2">
                    Build awesome React Admin Dashboard starter with a structure.
                </p>
            </div>
            <div className="flex items-center gap-4">
                <h3 className="text-base">Select Generator :</h3>
                <Cascader
                    searchable={false}
                    defaultValue={inputValue}
                    data={data}
                    style={{ width: 400 }}
                    onChange={(e) => setInputValue(e)}
                />
            </div>
            <Show showComp={inputValue} />
        </div>
    );
};

export default Layout;
