import { useEffect } from "react";
import { useFileStore } from "../../../../store/useFileStore";

const DocCustomCSS = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store);

    useEffect(() => {
        let value = `@import "./variable.scss";

/* @import "./lib//bootstrap.css"; */
/**
 * Any CSS included here will be global. The classic template
 * bundles Infima by default. Infima is a CSS framework designed to
 * work well for content-centric websites.
 */

/* You can override the default Infima variables here. */
:root {
    --ifm-color-primary: #2e8555;
    --ifm-color-primary-dark: #29784c;
    --ifm-color-primary-darker: #277148;
    --ifm-color-primary-darkest: #205d3b;
    --ifm-color-primary-light: #33925d;
    --ifm-color-primary-lighter: #359962;
    --ifm-color-primary-lightest: #3cad6e;
    --ifm-code-font-size: 95%;
    --docusaurus-highlighted-code-line-bg: rgba(0, 0, 0, 0.1);
}

/* For readability concerns, you should choose a lighter palette in dark mode. */
[data-theme="dark"] {
    --ifm-color-primary: #25c2a0;
    --ifm-color-primary-dark: #21af90;
    --ifm-color-primary-darker: #1fa588;
    --ifm-color-primary-darkest: #1a8870;
    --ifm-color-primary-light: #29d5b0;
    --ifm-color-primary-lighter: #32d8b4;
    --ifm-color-primary-lightest: #4fddbf;
    --docusaurus-highlighted-code-line-bg: rgba(0, 0, 0, 0.3);
}

body {
    font-family: sans-serif;
}

a {
    text-decoration: none !important;
}

button[aria-label="Close"] {
    background: none;
    border: none;
}

[data-theme="dark"] body {
    background: var(--docusaurus-highlighted-code-line-bg);
}

.navbar_github::before {
    background: red;
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12'/%3E%3C/svg%3E")
        no-repeat;
    content: "";
    display: flex;
    height: 24px;
    width: 24px;
}

[data-theme="dark"] .navbar_github::before {
    filter: invert(1%) sepia(1%) saturate(1%) hue-rotate(1deg) brightness(100%) contrast(0%);
}

.navbarSearchContainer_Bca1,
.navbarSearchContainer_node_modules-\\@docusaurus-theme-classic-lib-theme-Navbar-Search-styles-module {
    /* background: red; */
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 300px;
}
#search_input_react,
.algolia-autocomplete {
    width: 100%;
}

#__docusaurus .navbar.navbar--fixed-top {
    padding-left: 32px;
    padding-right: 32px;
}

#__docusaurus > .main-wrapper {
    padding-left: 32px;
    padding-right: 32px;
    margin-top: 60px;
}

.theme-doc-sidebar-item-category {
    margin-top: 16px !important;
}

.theme-doc-sidebar-item-category .menu__list-item-collapsible:hover {
    background: transparent !important;
}

.theme-doc-sidebar-item-category .menu__list-item-collapsible .menu__link {
    /* color: black; */
    color: var(--ifm-heading-color);
    font-weight: 500;
}
.theme-doc-sidebar-item-category .menu__list-item-collapsible .menu__link--active:hover {
    color: var(--ifm-heading-color);
}

aside.theme-doc-sidebar-container > div {
    /* height: unset; */
}

.menu[aria-label="Docs sidebar"] {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
}

.clean-btn > svg:last-child {
    color: rgb(222, 222, 222);
}

.menu__list-item a {
    text-decoration: none;
}

.tabs-container ul li{
    padding: 8px 16px;
}

.navbar__items--right{
    flex-direction: row-reverse;
}
/* Buy now button */
.buy_now{
    background: #0d6efd;
    padding: 8px 16px;
    color: white;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    transition: all 0.2s
}
.buy_now:hover{
    background: #0559d8;
    color: white
}


/* Light or Dark Mode css */
/* .light-dark-wrapper{
    position: relative;
}

.light-dark-wrapper .mode-icon{
    padding: 4px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    transition: all 0.2s ease-out;
}
.light-dark-wrapper .mode-icon:hover{
    background: var(--ifm-color-emphasis-200);
}

.light-dark-wrapper .mode-icon .darkIcon, .light-dark-wrapper .mode-icon .lightIcon{
    position: absolute;
    
}

.light-dark-wrapper .mode-icon .darkIcon{
    display: none;
}

.light-dark-dropdown{
    background: var(--ifm-navbar-background-color);
    width: 120px;
    border: 1px solid var(--ifm-color-emphasis-300);
    border-radius: 4px;
    box-sizing: var(--ifm-navbar-shadow);
    padding: 4px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 40px;
    right: 0;
    z-index: 9999;
    visibility: hidden;
}

.light-dark-dropdown > button{
    background: none;
    border: none;
    text-align: left;
    font-size: 14px;
    color: var(--ifm-menu-color);
    padding: 4px 8px;
}

.light-dark-dropdown > button > svg.lightIcon, .light-dark-dropdown > button > svg.darkIcon, .light-dark-dropdown > button > svg.systemIcon {
    width: 18px;
} */
/* svg.darkIcon {
    
}
svg.systemIcon {
    
} */

/* Feature Table css */
.featureTable {
    /* border: 1px solid #ddd; */
    text-align: left;
    /* width: 100%; */
    border-collapse: collapse;
}

/* Code Preview CSS */
.code-preview {
    margin: 32px 0;
    border: 1px solid var(--ifm-color-emphasis-300);
    border-radius: 8px;
    background: var(--ifm-color-emphasis-100);
}

.code-preview .preview {
    padding: 16px;
}

.code-preview-title {
    background: var(--ifm-color-emphasis-300);
    padding: 6px 16px;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    font-size: 18px;
}

/* Navbar css */
#__docusaurus .navbar.navbar--fixed-top {
    padding-left: 32px;
    padding-right: 32px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
}

.theme-doc-markdown.markdown {
    margin-top: 32px;
}

/* breadcrumbs */
.breadcrumbs__item.breadcrumbs__item--active .breadcrumbs__link{
    background: none;
    color: var(--ifm-font-color-base);
}

.breadcrumbs__item::after{
    margin: 0!important;
}
`;

        setStore({ docCustomCSS: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default DocCustomCSS;
