import { useEffect } from "react";
import { useFileStore } from "../../../../store/useFileStore";

const DocFormWizardMDX = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store);

    useEffect(() => {
        let value = `---
sidebar_position: 3
sidebar_label: Form Wizard
---

# Form Wizard

A form wizard is a type of advanced form that uses multiple steps or tabs to guide the user through a process or a task. A form wizard can help the user to complete a form that requires a lot of information or has a specific order or logic. A form wizard can also provide feedback, help, or confirmation at each step. A form wizard can improve the user experience and the conversion rate of the form.

You can find the FormWizard components in the \`src/views/FormWizard.jsx\` file.

import CodePreview from "@site/src/components/CodePreview/CodePreview";
import StepCircleWizard from "@site/src/components/WizardForm/StepCircleWizard";
import StepSquarWizard from "@site/src/components/WizardForm/StepSquarWizard";
import StepTabWizard from "@site/src/components/WizardForm/StepTabWizard";
import Tabs from '@theme/Tabs';
import TabItem from '@theme/TabItem';

## Examples

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <StepCircleWizard />
        </CodePreview>
    </TabItem>
    
    <TabItem value="code" label="Code">
        \`\`\`jsx title="Wizard Form"
        import { useState } from "react";
        import PersonalDetails from "./Page/PersonalDetails";
        import AdditionalInfo from "./Page/AdditionalInfo";
        import styles from "@/assets/scss/StepCircleWizard.module.scss";

        const StepCircleWizard = () => {
            const [activeTab, setActiveTab] = useState(1);

            const tabs = [
                {
                    id: 1,
                    name: "Personal details",
                },
                {
                    id: 2,
                    name: "Additional Info",
                },
                {
                    id: 3,
                    name: "Last step",
                },
            ];

            const updateStep = (step) => {
                if (step > 0 && step <= tabs.length) {
                    setActiveTab(step);
                }
            };

            return (
                <div className={\`\${styles.wizard_wrapper} position-relative mt-5 px-3\`}>
                    <div className="row">
                        <div
                            className={\`position-absolute \${styles.wizard_progress}\`}
                            style={{
                                width: \`\${
                                    activeTab === 1
                                        ? "18%"
                                        : activeTab === 2
                                        ? "50%"
                                        : activeTab === 3
                                        ? "85%"
                                        : ""
                                }\`,
                            }}></div>
                        {tabs.map((items, index) => (
                            <div className="col-4 text-center p-0" key={items.id}>
                                <div className={styles.step_block}>
                                    <div
                                        className={styles.step_wrapper}
                                        onClick={() => updateStep(index + 1)}>
                                        <div
                                            className={\`\${styles.wizard_icon} \${
            activeTab >= index + 1 ? styles.active : ""
        } mx-auto position-relative\`}>
                                            <span>{items.id}</span>
                                        </div>
                                    </div>
                                </div>
                                <span
                                    className={\`\${styles.step_title} \${
            activeTab >= index + 1 ? styles.step_title_active : ""
        }\`}>
                                    {items.name}
                                </span>
                            </div>
                        ))}
                    </div>
                    <div className={styles.step_content}>
                        {activeTab === 1 && <PersonalDetails />}
                        {activeTab === 2 && <AdditionalInfo />}
                        {activeTab === 3 && (
                            <span className={\`py-2 mb-3 \${styles.step_content_title}\`}>
                                Congratulations This is the Final Step
                            </span>
                        )}
                    </div>
                    <div
                        className={\`\${styles.step_btn_wrapper} mb-4\`}
                        style={{ padding: "0 20px" }}>
                        {activeTab > 1 && <button onClick={() => updateStep(activeTab - 1)}>Back</button>}
                        <button className="float-end" onClick={() => updateStep(activeTab + 1)}>
                            Next
                        </button>
                    </div>
                </div>
            );
        };

        export default StepCircleWizard;
        \`\`\`
    </TabItem>
</Tabs>


<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <StepSquarWizard />
        </CodePreview>
    </TabItem>
    
    <TabItem value="code" label="Code">
        \`\`\`jsx title="Wizard Form"
        import { useState } from "react";
        import PersonalDetails from "./Page/PersonalDetails";
        import AdditionalInfo from "./Page/AdditionalInfo";
        import styles from "@/assets/scss/StepSquarWizard.module.scss";

        const StepSquarWizard = () => {
            const [activeTab, setActiveTab] = useState(1);

            const tabs = [
                {
                    id: 1,
                    icon: "fa-solid fa-user",
                    name: "Personal details",
                },
                {
                    id: 2,
                    icon: "fa-solid fa-gear",
                    name: "Additional Info",
                },
                {
                    id: 3,
                    icon: "fa-solid fa-check",
                    name: "Last step",
                },
            ];

            const updateStep = (step) => {
                if (step > 0 && step <= tabs.length) {
                    setActiveTab(step);
                }
            };
            return (
                <div className={\`\${styles.wizard_wrapper} position-relative mt-5 px-3\`}>
                    <div className="row">
                        <div
                            className={\`position-absolute \${styles.wizard_progress}\`}
                            style={{
                                width: \`\${
                                    activeTab === 1
                                        ? "18%"
                                        : activeTab === 2
                                        ? "50%"
                                        : activeTab === 3
                                        ? "85%"
                                        : ""
                                }\`,
                            }}></div>
                        {tabs.map((items, index) => (
                            <div className="col-4 text-center p-0" key={items.id}>
                                <div className={styles.step_block}>
                                    <div
                                        className={styles.step_wrapper}
                                        onClick={() => updateStep(index + 1)}>
                                        <div
                                            className={\`\${styles.wizard_icon} \${
            activeTab >= index + 1 ? styles.active : ""
        } mx-auto position-relative\`}>
                                            <i className={\`\${
                                                items.icon
                                            }\`} style={{ fontSize: "24px" }}></i>
                                        </div>
                                    </div>
                                </div>
                                <span
                                    className={\`\${styles.step_title} \${
            activeTab >= index + 1 ? styles.step_title_active : ""
        }\`}>
                                    {items.name}
                                </span>
                            </div>
                        ))}
                    </div>
                    <div className={styles.step_content}>
                        {activeTab === 1 && <PersonalDetails />}
                        {activeTab === 2 && <AdditionalInfo />}
                        {activeTab === 3 && (
                            <span className={styles.step_content_title}>
                                Congratulations This is the Final Step
                            </span>
                        )}
                    </div>
                    <div
                        className={\`\${styles.step_btn_wrapper} mb-4\`}
                        style={{ padding: "0 20px" }}>
                        {activeTab > 1 && activeTab < tabs.length && (
                            <button onClick={() => updateStep(activeTab - 1)}>Back</button>
                        )}
                        <button
                            className="float-end"
                            style={{
                                backgroundColor: \`\${
                                    activeTab < tabs.length ? "rgb(52, 152, 219)" : "#43a047"
                                }\`,
                            }}
                            onClick={() => updateStep(activeTab + 1)}>
                            {activeTab < tabs.length ? "Next" : "Done"}
                        </button>
                    </div>
                </div>
            );
        };

        export default StepSquarWizard;
        \`\`\`
    </TabItem>
</Tabs>

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <StepTabWizard />
        </CodePreview>
    </TabItem>
    
    <TabItem value="code" label="Code">
        \`\`\`jsx title="Wizard Form"
        import { useState } from "react";
        import PersonalDetails from "./Page/PersonalDetails";
        import AdditionalInfo from "./Page/AdditionalInfo";
        import LoggedIn from "./Page/LoggedIn";
        import styles from "@/assets/scss/StepTabWizard.module.scss";

        const StepTabWizard = () => {
            const [activeTab, setActiveTab] = useState(1);

            const tabs = [
                {
                    id: 1,
                    icon: "fa-solid fa-user",
                    name: "Personal details",
                },
                {
                    id: 2,
                    icon: "fa-solid fa-user",
                    name: "Is Logged In?",
                },
                {
                    id: 3,
                    icon: "fa-solid fa-gear",
                    name: "Additional Info",
                },
                {
                    id: 4,
                    icon: "fa-solid fa-map",
                    name: "Final step",
                },
            ];

            const updateStep = (step) => {
                if (step > 0 && step <= tabs.length) {
                    setActiveTab(step);
                }
            };
            return (
                <div className={\`\${styles.wizard_wrapper} position-relative mt-5 px-3\`}>
                    <div className="row">
                        <div className={styles.wizard_header}>
                            <h3 className="m-0 text-center">Awesome Wizard</h3>
                            <p className="text-center">Split a complicated flow in multiple steps</p>
                        </div>
                        {tabs.map((items, index) => (
                            <div className="col-3 text-center p-0" key={items.id}>
                                <div
                                    className={\`\${styles.step_block} \${
            activeTab === index + 1 ? styles.active : ""
        }\`}>
                                    <div onClick={() => updateStep(index + 1)}>
                                        <div className="mx-auto position-relative">
                                            <i className={\`\${items.icon}\`}></i>
                                        </div>
                                    </div>
                                </div>
                                <span
                                    className={\`\${styles.step_title} \${
            activeTab >= index + 1 ? styles.step_title_active : ""
        }\`}>
                                    {items.name}
                                </span>
                            </div>
                        ))}
                    </div>
                    <div className={styles.step_content}>
                        {activeTab === 1 && <PersonalDetails />}
                        {activeTab === 2 && <LoggedIn />}
                        {activeTab === 3 && <AdditionalInfo />}
                        {activeTab === 4 && (
                            <span className={styles.step_content_title}>
                                Congratulations This is the Final Step
                            </span>
                        )}
                    </div>
                    <div className={\`\${styles.step_btn_wrapper} mb-4\`}>
                        {activeTab > 1 && activeTab < tabs.length && (
                            <button onClick={() => updateStep(activeTab - 1)}>Back</button>
                        )}
                        <button
                            className="float-end"
                            style={{
                                backgroundColor: \`\${
                                    activeTab < tabs.length
                                        ? "rgb(230, 126, 34)"
                                        : "rgb(67, 160, 71)"
                                }\`,
                            }}
                            onClick={() => updateStep(activeTab + 1)}>
                            {activeTab < tabs.length ? "Next" : "Done"}
                        </button>
                    </div>
                </div>
            );
        };

        export default StepTabWizard;
        \`\`\`
    </TabItem>
</Tabs>

For more form please visit [React Bootstrap](https://react-bootstrap.netlify.app/docs/forms/overview)
`;

        setStore({ docFormWizardMDX: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default DocFormWizardMDX;
