import { useEffect } from "react";
import { useFileStore } from "../../../../../store/useFileStore";

const DocCodePreviewJSX = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store);

    useEffect(() => {
        let value = `import React from 'react'

function CodePreview({children, title = "Result"}) {
  return (
    <div className='code-preview'>
        <h4 className='code-preview-title'>{title}</h4>
        <div className='preview'>{children}</div>
    </div>
  )
}

export default CodePreview
`;

        setStore({ docCodePreviewJSX: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default DocCodePreviewJSX;
