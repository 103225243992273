import { useEffect } from "react";
import { useFileStore } from "../../../store/useFileStore";
import { useDynamicDashboardData } from "../../../context/dashboardDataContext";

const DocConfigurationMDX = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store);

    const { projectInfo } = useDynamicDashboardData();

    useEffect(() => {
        let value = `---
sidebar_position: 3
sidebar_label: Configuration
---

# Configuration

Context is a React feature that allows us to share data across our components without passing props manually. Context is useful when we have some global state or configuration that affects our entire application. In this template, we've used Context to manage the appearance and behavior of ${projectInfo?.projectName} Dashboard.

You can find the context file on this location \`src/context/dashboardDataContext.jsx\`

import FeatureTable from "@site/src/components/FeatureTable/FeatureTable";

### Syntax to declare dashboardDataContext.jsx 

\`\`\`jsx title="Configuration"

import { createContext, useContext, useEffect, useState } from "react";

export const DashboardDataCreateContext = createContext();


export const DashboardDataProvider = ({ children }) => {
    const [isThemeDirection, setIsThemeDirection] = useState(false);
    const [sidebarMini, setSidebarMini] = useState(false);
    const [navbarFixed, setNavbarFixed] = useState(false);
    const [isDark, setIsDark] = useState(false);
    const [topNavbarBgColor, setTopNavbarBgColor] = useState("white");
    const [sidebarBgColor, setSidebarBgColor] = useState("black");
    const [sidebarBgImg, setSidebarBgImg] = useState();
    const [activeVariation, setActiveVariation] = useState("vertical");

    return (
        <DashboardDataCreateContext.Provider
            value={{
                sidebarMini,
                setSidebarMini,
                sidebarBgColor,
                setSidebarBgColor,
                topNavbarBgColor,
                setTopNavbarBgColor,
                navbarFixed,
                setNavbarFixed,
                isDark,
                setIsDark,
                sidebarBgImg,
                setSidebarBgImg,
                isThemeDirection,
                setIsThemeDirection,
                activeVariation,
                setActiveVariation,
            }}
        >
            {children}
        </DashboardDataCreateContext.Provider>
    );
};

export const useDashboardDataContext = () => {
    return useContext(DashboardDataCreateContext);
};
\`\`\`

<br/>
### Theme Direction (LTR - RTL)
<FeatureTable>
    <tr>
        <td>isThemeDirection</td>
        <td>\`boolean\`</td>
        <td>\`false\`</td>
        <td>A boolean value that indicates the direction (LTR/RTL) of the theme. If \`isThemeDirection\` is \`false\`, the theme will be left-to-right (LTR). If \`isThemeDirection\` is \`true\`, the theme will be right-to-left (RTL).</td>
    </tr>
</FeatureTable>

<br/>
### Sidebar expanded and collapsed 
<FeatureTable>
    <tr>
        <td>sidebarMini</td>
        <td>\`boolean\`</td>
        <td>\`false\`</td>
        <td>A boolean value that indicates the size of the sidebar. If \`sidebarMini\` is \`false\`, the sidebar will be expanded. If \`sidebarMini\` is \`true\`, the sidebar will be collapsed.</td>
    </tr>
</FeatureTable>

<br/>
### Navbar Fixed
<FeatureTable>
    <tr>
        <td>navbarFixed</td>
        <td>\`boolean\`</td>
        <td>\`false\`</td>
        <td>A boolean value that indicates the position of the navbar. If \`navbarFixed\` is \`false\`, the navbar will be normal. If \`navbarFixed\` is \`true\`, the navbar will be fixed at the top of the page.</td>
    </tr>
</FeatureTable>

<br/>
### Dark/Light Mode
<FeatureTable>
    <tr>
        <td>isDark</td>
        <td>\`boolean\`</td>
        <td>\`false\`</td>
        <td>A boolean value that indicates the mode of the theme. If \`isDark\` is \`false\`, the theme will be light mode. If \`isDark\` is \`true\`, the theme will be dark mode.</td>
    </tr>
</FeatureTable>

<br />
### Navbar Top Background
<FeatureTable>
    <tr>
        <td>topNavbarBgColor</td>
        <td>string</td>
        <td>\`white\`</td>
        <td>A string value that indicates the background color of the top navbar. You can choose from three options: \`white\`, \`red\`, or \`black\`. The background color of the top navbar will be changed accordingly.</td>
    </tr>
</FeatureTable>

<br/>
### Sidebar Background
<FeatureTable>
    <tr>
        <td>sidebarBgColor</td>
        <td>string</td>
        <td>\`black\`</td>
        <td> A string value that indicates the background color of the sidebar. You can choose from three options: \`white\`, \`red\`, or \`black\`. The background color of the sidebar will be changed accordingly.</td>
    </tr>
</FeatureTable>

<br/>
### Sidebar Background Image
<FeatureTable>
    <tr>
        <td>sidebarBgImg</td>
        <td>string</td>
        <td></td>
        <td>A string value that indicates the background image of the sidebar. You can use any valid image link as the value of \`sidebarBgImg\`. The background image of the sidebar will be changed accordingly.For example, \`setSidebarBgImg: https://example.com/image.jpg\` will make the sidebar use the image from the link.</td>
    </tr>
</FeatureTable>

<br/>
### Navigation Variation
<FeatureTable>
    <tr>
        <td>activeVariation</td>
        <td>string</td>
        <td>\`vertical\`</td>
        <td>A string value that indicates the variation of the dashboard. You can choose from four options: \`vertical\`, \`horizontal\`, \`combo\`, and \`dual-nav\`. The variation of the dashboard will be changed accordingly.</td>
    </tr>
</FeatureTable>
`;

        setStore({ docConfigurationMDX: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectInfo?.projectName]);

    return null;
};

export default DocConfigurationMDX;
