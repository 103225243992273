import { useEffect } from "react";
import { useFileStore } from "../../store/useFileStore";

const Funding = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store);
    useEffect(() => {
        let value = `github: reactadmins
open_collective: reactadmins
patreon: reactadmins
ko_fi: reactadmins
custom: https://www.buymeacoffee.com/reactadmin`;
        setStore({ funding: value });
    }, [setStore]);
    return null;
};

export default Funding;
