import { useEffect } from "react";
import { useFileStore } from "../../../store/useFileStore";
const EntypoCode = () => {
    const [_, setStore] = useFileStore((store) => store.entypo);

    useEffect(() => {
        let value = `{
    "name": "Entypo",
    "href": "entypo",
    "prefix": "entypo",
    "lists": {
        "mainicons": {
            "name": "Main Icons",
            "icons": [
                "note",
                "music",
                "flashlight",
                "mail",
                "heart",
                "star",
                "user",
                "users",
                "video",
                "camera",
                "menu",
                "check",
                "plus",
                "minus",
                "help",
                "info",
                "back",
                "home",
                "link",
                "lock",
                "lock-open",
                "eye",
                "tag",
                "bookmark",
                "bookmarks",
                "flag",
                "thumbs-up",
                "thumbs-down",
                "download",
                "upload",
                "reply",
                "reply-all",
                "forward",
                "quote",
                "code",
                "export",
                "pencil",
                "feather",
                "print",
                "retweet",
                "keyboard",
                "chat",
                "bell",
                "address",
                "location",
                "map",
                "direction",
                "compass",
                "cup",
                "trash",
                "book",
                "folder",
                "archive",
                "box",
                "rss",
                "phone",
                "cog",
                "tools",
                "share",
                "shareable",
                "calendar",
                "login",
                "mic",
                "sound",
                "clock",
                "light-down",
                "light-up",
                "adjust",
                "block",
                "popup",
                "publish",
                "ccw",
                "cw",
                "level-down",
                "level-up",
                "shuffle",
                "loop",
                "switch",
                "palette",
                "list",
                "signal",
                "trophy",
                "battery",
                "back-in-time",
                "mobile",
                "network",
                "inbox",
                "install",
                "globe",
                "cloud",
                "flash",
                "moon",
                "paper-plane",
                "leaf",
                "lifebuoy",
                "mouse",
                "briefcase",
                "suitcase",
                "brush",
                "magnet",
                "infinity",
                "erase",
                "graduation-cap",
                "language",
                "ticket",
                "water",
                "air",
                "credit-card",
                "clipboard",
                "megaphone",
                "database",
                "drive",
                "bucket",
                "thermometer",
                "key",
                "flow-cascade",
                "flow-branch",
                "flow-tree",
                "flow-line",
                "flow-parallel",
                "rocket",
                "gauge",
                "traffic-cone"
            ]
        },
        "socialicons": {
            "name": "Social Icons",
            "icons": [
                "github",
                "flickr",
                "vimeo",
                "twitter",
                "facebook",
                "pinterest",
                "tumblr",
                "linkedin",
                "dribbble",
                "stumbleupon",
                "lastfm",
                "rdio",
                "spotify",
                "qq",
                "instagram",
                "dropbox",
                "evernote",
                "flattr",
                "skype",
                "renren",
                "sina-weibo",
                "paypal",
                "picasa",
                "soundcloud",
                "mixi",
                "behance",
                "smashing",
                "sweden"
            ]
        }
    }
}
`;
        setStore({ entypo: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return null;
};

export default EntypoCode;
