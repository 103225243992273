import { useEffect } from "react";
import { useFileStore } from "../../../../store/useFileStore";

const SidebarBgWrapperScss = () => {
    const [_, setStore] = useFileStore((store) => store.sidebarBgWrapperScss);

    useEffect(() => {
        let value = `@import "../../assets/scss/variable.scss";
.sidebar_bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 280px;
    height: 100vh;
    box-shadow: 0 0px 10px 2px rgba(0, 0, 0, 0.2),
        0 5px 12px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
    z-index: 99999;
    transition: width 0.3s ease;
    .overlay_bg {
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 100%;
        display: block;
        top: 0;
        left: 0;
        background-size: cover;
        background-position: 50%;
        &::before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.8;
        }
    }
    &.active {
        width: 70px;
    }
    &[theme-type="combo"] {
        top: auto !important;
        bottom: 0;
        height: calc(100vh - 80px) !important;
    }
    &[data-background-color="white"] {
        .overlay_bg {
            &::before {
                background-color: #fff;
            }
        }
    }
    &[data-background-color="black"] {
        .overlay_bg {
            &::before {
                background-color: #000;
            }
        }
    }
    &[data-background-color="red"] {
        .overlay_bg {
            &::before {
                background-color: #f44336;
            }
        }
    }
}

body {
    &[data-theme="dark"] {
        .sidebar_bg {
            .overlay_bg {
                &::before {
                    background-color: var(--bg-content) !important;
                }
            }
        }
    }
    &[data-theme-direction="rtl"] {
        .sidebar_bg {
            left: auto;
            right: 0;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .sidebar_bg {
        &.active {
            display: none;
        }
    }
}
`;
        setStore({ sidebarBgWrapperScss: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return null;
};

export default SidebarBgWrapperScss;
