import { useEffect } from "react";
import { useFileStore } from "../../../store/useFileStore";

const FontAwesomeCode = () => {
    const [_, setStore] = useFileStore((store) => store.fontAwesome);

    useEffect(() => {
        let value = `{
    "name": "Font Awesome",
    "href": "font-awesome",
    "prefix": "fa",
    "lists": [
        {
            "name": "Web Applications Icons",
            "icons": [
                "address-book",
                "address-book",
                "address-card",
                "address-card",
                "adjust",
                "american-sign-language-interpreting",
                "anchor",
                "archive",
                "area-chart",
                "arrows",
                "arrows-h",
                "arrows-v",
                "asl-interpreting",
                "assistive-listening-systems",
                "asterisk",
                "at",
                "audio-description",
                "automobile",
                "balance-scale",
                "ban",
                "bank",
                "bar-chart",
                "bar-chart",
                "barcode",
                "bars",
                "bath",
                "bathtub",
                "battery",
                "battery-0",
                "battery-2",
                "battery-3",
                "battery-4",
                "battery-empty",
                "battery-full",
                "battery-half",
                "battery-quarter",
                "battery-three-quarters",
                "bed",
                "beer",
                "bell",
                "bell",
                "bell-slash",
                "bell-slash",
                "bicycle",
                "binoculars",
                "birthday-cake",
                "blind",
                "bolt",
                "bomb",
                "book",
                "bookmark",
                "bookmark",
                "braille",
                "briefcase",
                "bug",
                "building",
                "building",
                "bullhorn",
                "bullseye",
                "bus",
                "cab",
                "calculator",
                "calendar",
                "calendar-check",
                "calendar-minus",
                "calendar",
                "calendar-plus",
                "calendar-times",
                "camera",
                "camera-retro",
                "car",
                "caret-square-down",
                "caret-square-left",
                "caret-square-right",
                "caret-square-up",
                "cart-arrow-down",
                "cart-plus",
                "certificate",
                "check",
                "check-circle",
                "check-circle",
                "check-square",
                "check-square",
                "child",
                "circle",
                "circle",
                "circle-notch",
                "clock",
                "clone",
                "close",
                "cloud",
                "cloud-download",
                "cloud-upload",
                "code",
                "code-fork",
                "coffee",
                "cog",
                "cogs",
                "comment",
                "comment",
                "commenting",
                "commenting",
                "comments",
                "comments",
                "compass",
                "copyright",
                "credit-card",
                "credit-card-alt",
                "crop",
                "crosshairs",
                "cube",
                "cubes",
                "cutlery",
                "dashboard",
                "database",
                "deaf",
                "deafness",
                "desktop",
                "diamond",
                "dot-circle",
                "download",
                "drivers-license",
                "drivers-license",
                "edit",
                "ellipsis-h",
                "ellipsis-v",
                "envelope",
                "envelope",
                "envelope-square",
                "eraser",
                "exchange",
                "exclamation",
                "exclamation-circle",
                "exclamation-triangle",
                "external-link",
                "external-link-square",
                "eye",
                "eye-slash",
                "eyedropper",
                "fax",
                "feed",
                "female",
                "fighter-jet",
                "file-archive",
                "file-audio",
                "file-code",
                "file-excel",
                "file-image",
                "file-pdf",
                "file-powerpoint",
                "file-video",
                "file-word",
                "film",
                "filter",
                "fire",
                "fire-extinguisher",
                "flag",
                "flag-checkered",
                "flag",
                "flask",
                "folder",
                "folder",
                "frown",
                "futbol",
                "gamepad",
                "gavel",
                "gear",
                "gears",
                "gift",
                "globe",
                "graduation-cap",
                "hand-lizard",
                "hand-paper",
                "hand-peace",
                "hand-pointer",
                "hand-rock",
                "hand-scissors",
                "hand-spock",
                "handshake",
                "hashtag",
                "hdd",
                "headphones",
                "heart",
                "heart",
                "heartbeat",
                "history",
                "home",
                "hotel",
                "hourglass",
                "hourglass-1",
                "hourglass-2",
                "hourglass-3",
                "hourglass-end",
                "hourglass-half",
                "hourglass",
                "hourglass-start",
                "i-cursor",
                "id-badge",
                "id-card",
                "id-card",
                "image",
                "inbox",
                "industry",
                "info",
                "info-circle",
                "institution",
                "key",
                "keyboard",
                "language",
                "laptop",
                "leaf",
                "legal",
                "lemon",
                "level-down",
                "level-up",
                "life-ring",
                "lightbulb",
                "line-chart",
                "location-arrow",
                "lock",
                "low-vision",
                "magic",
                "magnet",
                "mail-forward",
                "mail-reply",
                "mail-reply-all",
                "male",
                "map",
                "map-marker",
                "map",
                "map-pin",
                "map-signs",
                "meh",
                "microchip",
                "microphone",
                "microphone-slash",
                "minus",
                "minus-circle",
                "minus-square",
                "minus-square",
                "mobile",
                "mobile-phone",
                "moon",
                "mortar-board",
                "motorcycle",
                "mouse-pointer",
                "music",
                "navicon",
                "newspaper",
                "object-group",
                "object-ungroup",
                "paint-brush",
                "paper-plane",
                "paper-plane",
                "paw",
                "pencil",
                "pencil-square",
                "pencil-square",
                "percent",
                "phone",
                "phone-square",
                "pie-chart",
                "plane",
                "plug",
                "plus",
                "plus-circle",
                "plus-square",
                "plus-square",
                "podcast",
                "print",
                "puzzle-piece",
                "qrcode",
                "question",
                "question-circle",
                "question-circle",
                "quote-left",
                "quote-right",
                "random",
                "recycle",
                "refresh",
                "registered",
                "remove",
                "reorder",
                "reply",
                "reply-all",
                "retweet",
                "road",
                "rocket",
                "rss",
                "rss-square",
                "search",
                "search-minus",
                "search-plus",
                "server",
                "share",
                "share-alt",
                "share-alt-square",
                "share-square",
                "share-square",
                "shield",
                "ship",
                "shopping-bag",
                "shopping-basket",
                "shopping-cart",
                "shower",
                "sign-in",
                "sign-language",
                "signal",
                "signing",
                "sitemap",
                "sliders",
                "smile",
                "snowflake",
                "soccer-ball",
                "sort",
                "sort-alpha-asc",
                "sort-alpha-desc",
                "sort-amount-asc",
                "sort-amount-desc",
                "sort-asc",
                "sort-desc",
                "sort-down",
                "sort-numeric-asc",
                "sort-numeric-desc",
                "sort-up",
                "space-shuttle",
                "spinner",
                "spoon",
                "square",
                "square",
                "star",
                "star-half",
                "star-half",
                "star",
                "sticky-note",
                "sticky-note",
                "street-view",
                "suitcase",
                "sun",
                "support",
                "tablet",
                "tachometer",
                "tag",
                "tags",
                "tasks",
                "taxi",
                "television",
                "terminal",
                "thermometer",
                "thermometer-0",
                "thermometer-1",
                "thermometer-2",
                "thermometer-3",
                "thermometer-4",
                "thermometer-empty",
                "thermometer-full",
                "thermometer-half",
                "thermometer-quarter",
                "thermometer-three-quarters",
                "thumb-tack",
                "thumbs-down",
                "thumbs-down",
                "thumbs-up",
                "thumbs-up",
                "ticket",
                "times",
                "times-circle",
                "times-circle",
                "times-rectangle",
                "times-rectangle",
                "tint",
                "trademark",
                "trash",
                "trash",
                "tree",
                "trophy",
                "truck",
                "tty",
                "tv",
                "umbrella",
                "universal-access",
                "university",
                "unlock",
                "unlock-alt",
                "unsorted",
                "upload",
                "user",
                "user-circle",
                "user-circle",
                "user",
                "user-plus",
                "user-secret",
                "user-times",
                "users",
                "vcard",
                "vcard",
                "video-camera",
                "volume-control-phone",
                "volume-down",
                "volume-up",
                "warning",
                "wheelchair",
                "wheelchair-alt",
                "wifi",
                "window-close",
                "window-close",
                "window-maximize",
                "window-minimize",
                "window-restore",
                "wrench"
            ]
        },
        {
            "name": "Accessibility Icons",
            "icons": [
                "american-sign-language-interpreting",
                "asl-interpreting",
                "assistive-listening-systems",
                "audio-description",
                "blind",
                "braille",
                "deaf",
                "deafness",
                "low-vision",
                "question-circle",
                "sign-language",
                "signing",
                "tty",
                "universal-access",
                "volume-control-phone",
                "wheelchair",
                "wheelchair-alt"
            ]
        },
        {
            "name": "Hand Icons",
            "icons": [
                "hand-lizard",
                "hand-paper",
                "hand-peace",
                "hand-pointer",
                "hand-rock",
                "hand-scissors",
                "hand-spock",
                "thumbs-down",
                "thumbs-down",
                "thumbs-up",
                "thumbs-up"
            ]
        },
        {
            "name": "Transportation Icons",
            "icons": [
                "ambulance",
                "automobile",
                "bicycle",
                "bus",
                "cab",
                "car",
                "fighter-jet",
                "motorcycle",
                "plane",
                "rocket",
                "ship",
                "space-shuttle",
                "subway",
                "taxi",
                "train",
                "truck",
                "wheelchair",
                "wheelchair-alt"
            ]
        },
        {
            "name": "Gender Icons",
            "icons": [
                "genderless",
                "mars",
                "mars-double",
                "mars-stroke",
                "mars-stroke-h",
                "mars-stroke-v",
                "mercury",
                "neuter",
                "transgender",
                "transgender-alt",
                "venus",
                "venus-double",
                "venus-mars"
            ]
        },
        {
            "name": "File Type Icons",
            "icons": [
                "file",
                "file-archive",
                "file-audio",
                "file-code",
                "file-excel",
                "file-image",
                "file",
                "file-pdf",
                "file-powerpoint",
                "file-text",
                "file-text",
                "file-video",
                "file-word"
            ]
        },
        {
            "name": "Spinner Icons",
            "icons": ["circle-notch", "cog", "gear", "refresh", "spinner"]
        },
        {
            "name": "Form Control Icons",
            "icons": [
                "check-square",
                "check-square",
                "circle",
                "circle",
                "dot-circle",
                "minus-square",
                "minus-square",
                "plus-square",
                "plus-square",
                "square",
                "square"
            ]
        },
        {
            "name": "Payment Icons",
            "icons": ["credit-card", "credit-card-alt"]
        },
        {
            "name": "Chart Icons",
            "icons": [
                "area-chart",
                "bar-chart",
                "bar-chart",
                "line-chart",
                "pie-chart"
            ]
        },
        {
            "name": "Currency Icons",
            "icons": [
                "bitcoin",
                "btc",
                "cny",
                "dollar",
                "eur",
                "euro",
                "gbp",
                "gg",
                "gg-circle",
                "ils",
                "inr",
                "jpy",
                "krw",
                "rmb",
                "rouble",
                "rub",
                "ruble",
                "rupee",
                "shekel",
                "sheqel",
                "try",
                "turkish-lira",
                "usd",
                "won",
                "yen"
            ]
        },
        {
            "name": "Text Editor Icons",
            "icons": [
                "align-center",
                "align-justify",
                "align-left",
                "align-right",
                "bold",
                "chain",
                "chain-broken",
                "clipboard",
                "columns",
                "copy",
                "cut",
                "dedent",
                "eraser",
                "file",
                "file",
                "file-text",
                "file-text",
                "files",
                "floppy",
                "font",
                "header",
                "indent",
                "italic",
                "link",
                "list",
                "list-alt",
                "listl",
                "list-ul",
                "outdent",
                "paperclip",
                "paragraph",
                "paste",
                "repeat",
                "rotate-left",
                "rotate-right",
                "save",
                "scissors",
                "strikethrough",
                "subscript",
                "superscript",
                "table",
                "text-height",
                "text-width",
                "th",
                "th-large",
                "th-list",
                "underline",
                "undo",
                "unlink"
            ]
        },
        {
            "name": "Directional Icons",
            "icons": [
                "angle-double-down",
                "angle-double-left",
                "angle-double-right",
                "angle-double-up",
                "angle-down",
                "angle-left",
                "angle-right",
                "angle-up",
                "arrow-circle-down",
                "arrow-circle-left",
                "arrow-circle-down",
                "arrow-circle-left",
                "arrow-circle-right",
                "arrow-circle-up",
                "arrow-circle-right",
                "arrow-circle-up",
                "arrow-down",
                "arrow-left",
                "arrow-right",
                "arrow-up",
                "arrows",
                "arrows-alt",
                "arrows-h",
                "arrows-v",
                "caret-down",
                "caret-left",
                "caret-right",
                "caret-square-down",
                "caret-square-left",
                "caret-square-right",
                "caret-square-up",
                "caret-up",
                "chevron-circle-down",
                "chevron-circle-left",
                "chevron-circle-right",
                "chevron-circle-up",
                "chevron-down",
                "chevron-left",
                "chevron-right",
                "chevron-up",
                "exchange",
                "long-arrow-down",
                "long-arrow-left",
                "long-arrow-right",
                "long-arrow-up"
            ]
        },
        {
            "name": "Video Player Icons",
            "icons": [
                "arrows-alt",
                "backward",
                "compress",
                "eject",
                "expand",
                "fast-backward",
                "fast-forward",
                "forward",
                "pause",
                "pause-circle",
                "pause-circle",
                "play",
                "play-circle",
                "play-circle",
                "random",
                "step-backward",
                "step-forward",
                "stop",
                "stop-circle",
                "stop-circle"
            ]
        },
        {
            "name": "Medical Icons",
            "icons": [
                "ambulance",
                "h-square",
                "heart",
                "heart",
                "heartbeat",
                "hospital",
                "medkit",
                "plus-square",
                "stethoscope",
                "user-md",
                "wheelchair",
                "wheelchair-alt"
            ]
        }
    ]
}
`;
        setStore({ fontAwesome: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return null;
};

export default FontAwesomeCode;
