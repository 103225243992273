import React, { useState } from "react";
import NavigationType from "../FormInputs/NavigationType/NavigationType";
import Tabs from "../../../../../../components/Tabs/Tabs";
import Colors from "../Colors/Colors";

const DashboardStyle = () => {
    const [activeTab, setActiveTab] = useState(1);
    const fileName = [
        {
            id: 1,
            name: "Navigation-Type",
        },
        {
            id: 2,
            name: "Style",
        },
    ];

    return (
        <div className="border-[1px] border-[#D8D8D8] rounded-[2px]">
            <Tabs
                tabs={activeTab}
                setTabs={setActiveTab}
                tabName={fileName}
                tabsItems={
                    <div className="px-5 pb-5 mt-10">
                        {activeTab === 1 && <NavigationType />}
                        {activeTab === 2 && <Colors />}
                    </div>
                }
            />
        </div>
    );
};

export default DashboardStyle;
