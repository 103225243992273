import { useEffect } from "react";
import { useFileStore } from "../../../../store/useFileStore";

const GridsScss = () => {
    const [_, setStore] = useFileStore((store) => store.gridsScss);

    useEffect(() => {
        let value = `.grids_contener {
    h5 {
        font-size: 0.875em;
        font-weight: 600;
        color: var(--hedging-text-color);
    }
    .card {
        box-shadow: 0 0 0 1px var(--border-color);
        background-color: var(--bg-content);
        .card_body {
            color: var(--hedging-text-color);
        }
    }
}
`;
        setStore({ gridsScss: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return null;
};

export default GridsScss;
