function TextControl({ label = "", value, onChange, id = "", type = "text", placeholder = "" }) {
    return (
        <div>
            <label htmlFor={id} className="block text-[#24263a] text-xs leading-[15px] font-bold">
                {label}
            </label>
            <input
                type={type}
                id={id}
                className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                placeholder={placeholder}
                value={value}
                onChange={onChange}
            />
        </div>
    );
}

export default TextControl;
