import { useEffect } from "react";
import { useFileStore } from "../../../../store/useFileStore";

const DeviceVisitorStatsScss = () => {
    const [_, setStore] = useFileStore((store) => store.deviceVisitorStatsScss);

    useEffect(() => {
        let value = `.device_visitor_stats {
    width: 100%;
    height: 100%;
    background-color: var(--bg-content);
    border: 1px solid transparent;
}
body[data-theme="dark"] {
    .device_visitor_stats {
        border-color: var(--border-color) !important;
    }
}
`;
        setStore({ deviceVisitorStatsScss: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return null;
};

export default DeviceVisitorStatsScss;
