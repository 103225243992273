import React from "react";
import General from "./General/General";
import Dependencies from "./Dependencies/Dependencies";
import { useDynamicDashboardData } from "../../context/dashboardDataContext";
import DashboardStyle from "../DashboardStyle/DashboardStyle";
// import DownloadFile from "../../downloadFile/downloadFile";
import GeneratedFiles from "../../GeneratedFiles/GeneratedFiles";
import { useModalContext } from "../../context/dashboardModalContext";
import SwitcherFields from "./SwitcherFields/SwitcherFields";
import SwitcherUitls from "./SwitcherUitls/SwitcherUitls";

const FormInputs = () => {
    const { projectInfo } = useDynamicDashboardData();

    //modal States
    const { setIsOpenModal, setFileInfo, setFileName } = useModalContext();

    return (
        <div className="grid grid-cols-12 gap-8">
            <div className="col-span-8">
                <div className="flex flex-col gap-6">
                    <General />
                    <Dependencies />
                    <DashboardStyle />
                    <SwitcherFields />
                    <SwitcherUitls />
                </div>
            </div>
            <div className="col-span-4">
                <div className="py-[30px] px-5 bg-[#f1f1f1] rounded-[3px] mb-2">
                    {projectInfo.projectName ? (
                        <GeneratedFiles
                            modalStates={{ setIsOpenModal, setFileInfo, setFileName }}
                        />
                    ) : (
                        // "Generated File"
                        <p className="text-[#24263a] text-[1rem] font-normal leading-[1.5rem] text-center italic">
                            No Preview Available
                        </p>
                    )}
                </div>
                {/* <DownloadFile /> */}
            </div>
        </div>
    );
};

export default FormInputs;
