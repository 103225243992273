import { createContext, useContext, useState } from "react";

export const ModalCreateContext = createContext();

export const ModalContextProvider = ({ children }) => {
    const [fileInfo, setFileInfo] = useState(null);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [fileName, setFileName] = useState("");

    return (
        <ModalCreateContext.Provider
            value={{
                isOpenModal,
                setIsOpenModal,
                fileInfo,
                setFileInfo,
                fileName,
                setFileName,
            }}>
            {children}
        </ModalCreateContext.Provider>
    );
};

export const useModalContext = () => {
    return useContext(ModalCreateContext);
};
