import { createContext, useContext, useState } from "react";

export const DashboardNavMenuContext = createContext();

export const DashboardNavMenuProvider = ({ children }) => {
    const [navMenu, setNavManu] = useState();
    const [isPublicOpen, setPublicOpen] = useState(false);
    const [src, setSrc] = useState(false);
    const [components, setComponents] = useState(false);
    const [alert, setAlert] = useState(false);
    const [card, setCard] = useState(false);
    const [chart, setChart] = useState(false);
    const [apexCharts, setApexCharts] = useState(false);
    const [chartjs, setChartjs] = useState(false);
    const [recharts, setRecharts] = useState(false);
    const [dashboard, setDashboard] = useState(false);
    const [stats, setStats] = useState(false);
    const [dataSwitch, setDataSwitch] = useState(false);
    const [dropdownMenu, setDropdownMenu] = useState(false);
    const [form, setForm] = useState(false);
    const [stepWizards, setStepWizards] = useState(false);
    const [page, setPage] = useState(false);
    const [icon, setIcon] = useState(false);
    const [modalSwitch, setModalSwitch] = useState(false);
    const [navbarsSwitch, setNavbarsSwitch] = useState(false);
    const [messageSwitch, setMessageSwitch] = useState(false);
    const [notificationSwitch, setNotificationSwitch] = useState(false);
    const [userProfileSwitch, setUserProfileSwitch] = useState(false);
    const [progressBarSwitch, setProgressBarSwitch] = useState(false);
    const [sidebarSwitch, setSidebarSwitch] = useState(false);
    const [tabSwitch, setTabSwitch] = useState(false);
    const [tabPageSwitch, setTabPageSwitch] = useState(false);
    const [tableSwitch, setTableSwitch] = useState(false);
    const [userInfoSwitch, setUserInfoSwitch] = useState(false);
    const [layoutsSwitch, setLayoutsSwitch] = useState(false);
    const [viewsSwitch, setViewssSwitch] = useState(false);
    const [assetsSwitch, setAssetsSwitch] = useState(false);
    const [imgSwitch, setImgSwitch] = useState(false);
    const [avatarSwitch, setAvatarSwitch] = useState(false);
    const [scssSwitch, setScssSwitch] = useState(false);
    const [promoBarSwitch, setPromoBarSwitch] = useState(false);
    const [contextSwitch, setContextSwitch] = useState(false);
    const [colorControlSwitch, setColorControlSwitch] = useState(false);
    const [switchControl, setSwitchControl] = useState(false);
    const [searchBarSwitch, setSearchBarSwitch] = useState(false);

    return (
        <DashboardNavMenuContext.Provider
            value={{
                navMenu,
                setNavManu,
                isPublicOpen,
                setPublicOpen,
                src,
                setSrc,
                components,
                setComponents,
                alert,
                setAlert,
                card,
                setCard,
                chart,
                setChart,
                apexCharts,
                setApexCharts,
                chartjs,
                setChartjs,
                recharts,
                setRecharts,
                dashboard,
                setDashboard,
                stats,
                setStats,
                dataSwitch,
                setDataSwitch,
                dropdownMenu,
                setDropdownMenu,
                form,
                setForm,
                stepWizards,
                setStepWizards,
                page,
                setPage,
                icon,
                setIcon,
                modalSwitch,
                setModalSwitch,
                navbarsSwitch,
                setNavbarsSwitch,
                messageSwitch,
                setMessageSwitch,
                notificationSwitch,
                setNotificationSwitch,
                userProfileSwitch,
                setUserProfileSwitch,
                progressBarSwitch,
                setProgressBarSwitch,
                sidebarSwitch,
                setSidebarSwitch,
                tabSwitch,
                setTabSwitch,
                tabPageSwitch,
                setTabPageSwitch,
                tableSwitch,
                setTableSwitch,
                userInfoSwitch,
                setUserInfoSwitch,
                layoutsSwitch,
                setLayoutsSwitch,
                viewsSwitch,
                setViewssSwitch,
                assetsSwitch,
                setAssetsSwitch,
                imgSwitch,
                setImgSwitch,
                avatarSwitch,
                setAvatarSwitch,
                scssSwitch,
                setScssSwitch,
                promoBarSwitch,
                setPromoBarSwitch,
                contextSwitch,
                setContextSwitch,
                colorControlSwitch,
                setColorControlSwitch,
                switchControl,
                setSwitchControl,
                searchBarSwitch,
                setSearchBarSwitch,
            }}>
            {children}
        </DashboardNavMenuContext.Provider>
    );
};

export const useDashboardNavManu = () => {
    return useContext(DashboardNavMenuContext);
};
