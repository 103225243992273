import FileView from "../FileView/FileView";

const FileStructureModel = ({ item, modalStates }) => {
    return (
        <ul>
            <li>
                <FileView
                    fileName={item?.name}
                    icon={item?.icon}
                    data={item?.data}
                    modalStates={modalStates}
                />
            </li>
        </ul>
    );
};

export default FileStructureModel;
