import { createContext, useContext, useState } from "react";

export const DashboardDataContext = createContext();

export const DashboardDataProvider = ({ children }) => {
    // choose product
    const [selectVariation, setSelectVariation] = useState();
    const [navigationType, setNavigationType] = useState("vertical");
    const [sidebarText, setSidebarText] = useState("#fff");
    const [sidebarBg, setSidebarBg] = useState("#292b35");
    const [topNavText, serTopNavText] = useState("#000");
    const [topNavBg, setTopNavBg] = useState("#fff");

    // dashboard data
    const [projectInfo, setProjectInfo] = useState({
        dashboardType: "free",
        projectName: "Basix Admin",
        version: "1.0.0",
        description: "",
        author: "React Admin",
        keywords: "",
        sheetId: "1VLpfKspHHNM6JIFOQtohqDRyHR85J3KR5RLF4jqlz0Q",
        tabId: "38890577",
        productSlug: "",
        downloadLabel: "Get Free Version",
        downloadURL: "",
        purchaseLabel: "Purchase Now",
        purchaseURL: "",
        docLabel: "Documentation",
        docURL: "",
        fbShareURL: "",
        tweetText: "",
        tweetURL: "",
        tweetHashTags: "",
    });

    // dependencies & dev
    const [addDependencies, setAddDependencies] = useState([]);
    const [addDevDependencies, setAddDevDependencies] = useState([]);

    return (
        <DashboardDataContext.Provider
            value={{
                selectVariation,
                setSelectVariation,
                navigationType,
                setNavigationType,
                sidebarBg,
                setSidebarBg,
                sidebarText,
                setSidebarText,
                topNavText,
                serTopNavText,
                topNavBg,
                setTopNavBg,

                /*dashboard data */
                projectInfo,
                setProjectInfo,

                /*dependencies & dev */
                addDependencies,
                setAddDependencies,
                addDevDependencies,
                setAddDevDependencies,
            }}>
            {children}
        </DashboardDataContext.Provider>
    );
};

export const useDynamicDashboardData = () => {
    return useContext(DashboardDataContext);
};
