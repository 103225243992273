import { useEffect } from "react";
import { useFileStore } from "../../../store/useFileStore";
import { useDynamicDashboardData } from "../../../context/dashboardDataContext";
import { getSlug } from "../../../../../../../utils/getSlug";

const BootstrapProductAdminDemoYML = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store);

    const { projectInfo } = useDynamicDashboardData();

    useEffect(() => {
        let value = `## Bootstrap ${projectInfo?.projectName} Demo Deployments
## Requirements:
## - GH_TOKEN with write access to the repo
## - Create a new repo for demo: {organization}/bootstrap-${getSlug(
            projectInfo?.projectName
        )}-demo
## - For vercel deployments, create a new project for demo: {organization}/bootstrap-${getSlug(
            projectInfo?.projectName
        )}-demo
## - Point the vercel project to the demo repo
## - Configure domain e.g. ${getSlug(
            projectInfo?.projectName
        )}-bootstrap.reactadmin.com
##
name: Bootstrap ${projectInfo?.projectName} Deployments

on:
  push:
    branches:
      - main  # Change this to match your main branch name
    paths:
      - 'bootstrap/${getSlug(projectInfo?.projectName)}/**'
      - 'rewriteLinks.mjs'

jobs:
  demo-deployment:
    runs-on: ubuntu-latest
    name: ${projectInfo?.projectName} Demo Deploy
    
    steps:
      - name: Checkout repository
        uses: actions/checkout@v2

      - name: Use Node.js
        uses: actions/setup-node@v2
        with:
          node-version: '18'  # Adjust the Node.js version if needed
        env:
          GITHUB_TOKEN: \${{ secrets.GH_TOKEN }}

      - name: Clean Components and Build
        run: |
            ls -a
            cd bootstrap/${getSlug(projectInfo?.projectName)}
            node --experimental-modules ../../rewriteLinks.mjs /bootstrap/${getSlug(
                projectInfo?.projectName
            )}/
            npm i
            npm run build -- --base=bootstrap/${getSlug(
                projectInfo?.projectName
            )}
            cd docs
            npm i
            npm run build


      #deploy to demo

      - name: Checkout to demo repo
        uses: actions/checkout@v3
        with:
          repository: litonarefin/demo.reactadmin.com
          path: 'demo'
          token: \${{ secrets.GH_TOKEN }}


      - name: Copy files for bootstrap/${getSlug(
          projectInfo?.projectName
      )} to new repo folder
        shell: bash
        run: |
          ls -a
          mkdir -p demo/static/bootstrap/${getSlug(projectInfo?.projectName)}
          cp -r bootstrap/${getSlug(
              projectInfo?.projectName
          )}/dist/* demo/static/bootstrap/${getSlug(projectInfo?.projectName)}
          mkdir -p demo/static/bootstrap/${getSlug(
              projectInfo?.projectName
          )}/docs
          cp -r bootstrap/${getSlug(
              projectInfo?.projectName
          )}/docs/build/* demo/static/bootstrap/${getSlug(
            projectInfo?.projectName
        )}/docs

      - name: Push changes for bootstrap/${getSlug(projectInfo?.projectName)}
        shell: bash
        working-directory: demo
        run: |
          git config --global user.email "actions@github.com"
          git config --global user.name "GitHub Actions"
          git add .
          if git diff-index --quiet HEAD --; then
            echo "No changes to the output on this push; exiting."
            exit 0
          fi
          git commit -m "\${{ github.event.head_commit.message }}"
          git pull origin main --no-rebase
          git push

      #deploy to demo end

  #deploy to public repo
  public-deployment:
    runs-on: ubuntu-latest
    name: ${projectInfo?.projectName} Public Deploy
    needs: demo-deployment
    steps:
      - name: Checkout repository
        uses: actions/checkout@v2

      - name: Use Node.js
        uses: actions/setup-node@v2
        with:
          node-version: '18'  # Adjust the Node.js version if needed
        env:
          GITHUB_TOKEN: \${{ secrets.GH_TOKEN }}

      - name: Clean Components
        run: |
          ls -a
          cd bootstrap/${getSlug(projectInfo?.projectName)}
          node --experimental-modules ../../clean.mjs Switcher
          rm -rf src/components/Switcher/


      - name: Checkout
        uses: actions/checkout@v3
        with:
          repository: reactadmins/bootstrap-${getSlug(projectInfo?.projectName)}
          path: 'dest/bootstrap/${getSlug(projectInfo?.projectName)}'
          token: \${{ secrets.GH_TOKEN }}


      - name: Copy files for bootstrap/${getSlug(
          projectInfo?.projectName
      )} to new repo folder
        shell: bash
        run: |
          ls -a
          rm -rf bootstrap/${getSlug(projectInfo?.projectName)}/docs/ 
          cp -r bootstrap/${getSlug(
              projectInfo?.projectName
          )}/* dest/bootstrap/${getSlug(projectInfo?.projectName)}


      - name: Push changes for bootstrap/${getSlug(projectInfo?.projectName)}
        shell: bash
        working-directory: dest/bootstrap/${getSlug(projectInfo?.projectName)}
        run: |
          git config --global user.email "actions@github.com"
          git config --global user.name "GitHub Actions"
          git add .
          if git diff-index --quiet HEAD --; then
            echo "No changes to the output on this push; exiting."
            exit 0
          fi
          git commit -m "\${{ github.event.head_commit.message }}"
          git push`;

        setStore({ bootstrapProductAdminDemoYML: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectInfo?.projectName]);
    return null;
};

export default BootstrapProductAdminDemoYML;
