import { useEffect } from "react";
import { useFileStore } from "../../../../store/useFileStore";

const NotificationStatsCartScss = () => {
    const [_, setStore] = useFileStore(
        (store) => store.notificationStatsCartScss
    );

    useEffect(() => {
        let value = `.notification_wrapper {
    box-shadow: 0 0 0 1px var(--border-color);
    .header {
        padding: 17px 20px;
        a {
            img {
                width: 85px;
                height: 85px;
            }
        }
        .media_body {
            h3 {
                font-size: 2rem;
                color: #fff;
                font-weight: 500;
            }
            p {
                font-size: 1rem;
                color: #878787;
                font-weight: 400;
            }
        }
    }
    .notifications {
        padding-top: 0;
        background-color: var(--bg-content);
        ul {
            li {
                a {
                    border-top: 1px solid var(--border-color);
                    background-color: var(--bg-content);
                    color: var(--content-text-color);
                }
                &:first-child {
                    a {
                        border-top: 0;
                    }
                }
            }
        }
    }
}
`;
        setStore({ notificationStatsCartScss: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return null;
};

export default NotificationStatsCartScss;
