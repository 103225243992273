import { useEffect } from "react";
import { useFileStore } from "../../../../store/useFileStore";

const SocialCounterScss = () => {
    const [_, setStore] = useFileStore((store) => store.socialCounterScss);

    useEffect(() => {
        let value = `.social_counter_wrapper {
    background-color: var(--bg-content) !important;
    border: 1px solid var(--border-color) !important;
    .social_counter {
        .counter {
            span {
                font-size: 1.1rem;
                font-weight: 500;
                color: var(--hedging-text-color);
            }
            p {
                font-size: 14px;
                color: var(--content-text-color);
            }
        }
        .icon {
            font-size: 2.8125rem;
        }
        .counter_group {
            color: var(--content-text-color);
            strong {
                font-size: 20px;
                font-weight: 500;
                span {
                    font-weight: 800;
                }
            }
            p {
                font-size: 14px;
                top: -3px;
            }
        }
    }
}
`;
        setStore({ socialCounterScss: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return null;
};

export default SocialCounterScss;
