import "rsuite/dist/rsuite.min.css";
import Layout from "./components/Layout/Layout";
import useGlobalDataStore from "./store/useGlobalDataStore";
import { GlobalStoreContext } from "./store/useGlobalStore";

function App() {
    return (
        <div>
            <GlobalStoreContext.Provider value={useGlobalDataStore()}>
                <Layout />
            </GlobalStoreContext.Provider>
        </div>
    );
}

export default App;
