import { Fragment } from "react";
import FolderStructureModel from "../FolderStructureModel/FolderStructureModel";
import FileStructureModel from "../FileStructureModel/FileStructureModel";

export const FileRenderer = ({ files, modalStates }) => {
    return files?.map((file) => {
        switch (file?.type) {
            case "folder": {
                return (
                    <FolderStructureModel key={Math.random()} item={file}>
                        {file?.folders.length > 0 ? (
                            <FileRenderer modalStates={modalStates} files={file?.folders} />
                        ) : null}

                        {file?.files.length > 0 ? (
                            <FileRenderer modalStates={modalStates} files={file?.files} />
                        ) : null}
                    </FolderStructureModel>
                );
            }
            case "file": {
                return (
                    <Fragment key={Math.random()}>
                        <FileStructureModel item={file} modalStates={modalStates} />
                    </Fragment>
                );
            }
            default:
                return null;
        }
    });
};
