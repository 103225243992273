import { useEffect } from "react";
import { useFileStore } from "../../../../store/useFileStore";

const TrafficScss = () => {
    const [_, setStore] = useFileStore((store) => store.trafficScss);

    useEffect(() => {
        let value = `@import "./variable.scss";

.visitor_wrapper {
    .title {
        display: block;
        font-size: 14px;
        color: var(--content-text-color);
    }
    .counter {
        font-size: 1rem;
        padding: 8px 0;
        font-family: inherit;
        font-weight: 500;
        line-height: 1.2;
        color: var(--hedging-text-color);
    }
    .progress {
        width: 100%;
        height: 5px;
        background-color: $pink;
        .progress_bar {
            width: 40%;
            height: 100%;
            background-color: $success;
        }
    }
}
.card_footer {
    background-color: var(--bg-content) !important;
}
`;
        setStore({ trafficScss: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return null;
};

export default TrafficScss;
