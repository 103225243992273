import { useEffect } from "react";
import { useFileStore } from "../../store/useFileStore";

const BabelConfig = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store);

    useEffect(() => {
        let value = `module.exports = {
    presets: [require.resolve('@docusaurus/core/lib/babel/preset')],
};`;

        setStore({ docBabelConfig: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default BabelConfig;
