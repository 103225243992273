import { useEffect } from "react";
import { useFileStore } from "../../../../../store/useFileStore";

const VerticalScss = () => {
    const [_, setStore] = useFileStore((store) => store.verticalScss);

    useEffect(() => {
        let value = `@import "../variable.scss";

.vertical_nav {
    padding: 24px;
    .navbar_nav {
        .header_left {
            display: flex;
            li {
                padding: 0 16px;
                button {
                    padding: 0;
                    position: relative;
                    border: none;
                    background-color: transparent;
                    i {
                        font-size: 16px;
                    }
                    .count {
                        display: block;
                        width: 15px;
                        height: 15px;
                        font-size: 11px;
                        color: $white;
                        line-height: 15px;
                        border-radius: 50%;
                    }
                    .sidebar_menu_icon {
                        &::before {
                            position: absolute;
                            content: "\\f0a4";
                            font-family: Fontawesome;
                            top: -23px;
                            right: -15px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 35px;
                            height: 35px;
                            border-radius: 50%;
                            z-index: 999;
                            color: #fff;
                            background-color: #dc3545;
                        }
                    }
                }
                .for_notification {
                    .count {
                        position: absolute;
                        top: -4px;
                        right: -12px;
                    }
                }
                .for_message {
                    .count {
                        position: absolute;
                        top: -4px;
                        right: -18px;
                    }
                }
                &.sidebar_mini {
                    display: none;
                }
                &.active_sidebar_mini {
                    display: block !important;
                }
            }
        }
        .user_area {
            position: relative;
            .user_dropdown {
                img {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                }
            }
        }
        &.navbar_nav_right {
            display: flex;
            align-items: center;
            gap: 20px;
        }
    }
    &[data-color="white"] {
        .navbar_nav {
            .header_left {
                li {
                    button {
                        color: #000;
                    }
                }
            }
        }
    }
    &[data-color="black"] {
        .navbar_nav {
            .header_left {
                li {
                    button {
                        color: #fff;
                    }
                }
            }
        }
    }
    &[data-color="red"] {
        .navbar_nav {
            .header_left {
                li {
                    button {
                        color: #fff;
                    }
                    .sidebar_menu_icon {
                        &::before {
                            background-color: #1d1d1d !important;
                        }
                    }
                }
            }
        }
    }
}

body[data-theme="dark"] {
    .vertical_nav {
        border-bottom: 1px solid var(--border-color);
        .navbar_nav {
            .header_left {
                li {
                    button {
                        color: var(--hedging-text-color);
                    }
                    .sidebar_menu_icon {
                        &::before {
                            background-color: #dc3545 !important;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 425px) {
    .vertical_nav {
        padding: 16px;
    }
}
`;
        setStore({ verticalScss: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return null;
};

export default VerticalScss;
