import { useEffect } from "react";
import { useFileStore } from "../../../../store/useFileStore";

const OverlayCardScss = () => {
    const [_, setStore] = useFileStore((store) => store.overlayCardScss);

    useEffect(() => {
        let value = `.overlay_wrapper {
    box-shadow: 0 0 0 1px var(--border-color);
    background-color: var(--bg-content);
    .overlay_content {
        background-color: var(--bg-content);
        .media_container {
            min-height: 152px;
            position: relative;
            background: #58c9f3;
            padding: 10px 10px 20px 10px;
            .overlay {
                &::before {
                    position: absolute;
                    content: "\\f099";
                    font: normal normal normal 130px/1 FontAwesome;
                    top: 0;
                    left: 25px;
                    right: 0;
                    color: hsla(0, 0%, 100%, 0.15);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .counter_content {
            .counter {
                color: var(--hedging-text-color);
                font-size: 13px;
            }
            .title {
                display: block;
                color: var(--content-text-color);
                font-size: 13px;
            }
        }
        .media_body {
            textarea,
            input {
                border: 1px solid var(--border-color);
                color: var(--hedging-text-color);
                padding: 0.375rem 0.75rem;
                background-color: var(--bg-content);
                &::placeholder {
                    color: var(--hedging-text-color);
                }
            }
            a {
                i {
                    color: var(--content-text-color);
                }
                span {
                    color: var(--hedging-text-color);
                }
            }
        }
        .weather_category {
            position: relative;
            &::after {
                position: absolute;
                content: " ";
                top: -3px;
                left: -25px;
                width: 1.2px;
                height: 40px;
                background-color: #e6e6e6;
            }
        }
    }
}
`;
        setStore({ overlayCardScss: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default OverlayCardScss;
