import { useEffect } from "react";
import { useFileStore } from "../../../../store/useFileStore";
import { useDynamicDashboardData } from "../../../../context/dashboardDataContext";

const TablesScss = () => {
    const [_, setStore] = useFileStore((store) => store.tablesScss);
    const {
        projectInfo: { dashboardType },
    } = useDynamicDashboardData();

    useEffect(() => {
        let value = `@import "../scss/variable.scss";

.table_wrapper {
    overflow-x: scroll;
    .search_wrapper {
        input {
            color: var(--hedging-text-color);
            border-color: var(--border-color);
            background-color: var(--bg-content);
            border-radius: 4px 0 0 4px;
            &:focus {
                box-shadow: none;
            }
            &::placeholder {
                color: var(--hedging-text-color);
            }
        }
        button {
            border: 1px solid #666666;
            border-left: 0px;
            background-color: #666666;
            border-radius: 0 4px 4px 0;
            color: #fff;
        }
        .show_entries {
            select {
                box-shadow: none !important;
                option {
                    color: var(--hedging-text-color);
                    border-bottom: 1px solid var(--border-color);
                    background-color: var(--bg-content);
                }
            }
        }
    }
    .table {
        .thead {
            tr {
                td {
                    font-size: 16px;
                    font-weight: 600;
                    padding: 0.75rem;
                    border-bottom: 0px;
                    &:last-child {
                        text-align: center;
                    }
                }
            }
        }
        .tbody {
            tr {
                td {
                    color: #000;
                    padding: 0.75rem;
                    border-top: 1px solid #dee2e6;
                    border-bottom: 0px;
                    &:last-child {
                        text-align: center;
                    }
                }
                &:nth-child(even) {
                    background-color: #fff;
                }
                &:nth-child(odd) {
                    background-color: #f2f2f2;
                }
            }
        }
    }
    ${
        dashboardType === "free"
            ? ""
            : `.pagination {
        display: flex;
        flex-wrap: wrap;
        row-gap: 20px;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border-top: 1px solid var(--border-color);
        padding: 25px 0 9px 0;
        .showing {
            font-size: 14px;
            line-height: 1.42857143;
            color: var(--hedging-text-color);
        }
        nav {
            ul {
                li {
                    padding: 0;
                    margin-top: 0;
                    a {
                        border-color: var(--border-color) !important;
                        background: var(--bg-content);
                        color: var(--hedging-text-color);
                        box-shadow: none;
                    }
                    &.active {
                        a {
                            z-index: 1;
                            color: #fff;
                            background-color: #007bff;
                            border-color: #007bff;
                        }
                    }
                }
            }
        }
    }`
    }
}

body {
    &[data-theme="dark"] {
        .table_wrapper {
            .table {
                .thead {
                    tr {
                        td {
                            font-size: 16px;
                            font-weight: 600;
                            padding: 0.75rem;
                            border-bottom: 0px;
                            color: var(--content-text-color) !important;
                            background-color: var(--bg-content) !important;
                            &:last-child {
                                text-align: center;
                            }
                        }
                    }
                }
                .tbody {
                    tr {
                        td {
                            color: var(--hedging-text-color) !important;
                            padding: 0.75rem;
                            border-top: 1px solid var(--border-color) !important;
                            border-bottom: 0px;
                            background-color: var(--bg-content) !important;
                            &:last-child {
                                text-align: center;
                            }
                        }
                        &:nth-child(even) {
                            background-color: #fff !important;
                        }
                        &:nth-child(odd) {
                            background-color: #f2f2f2 !important;
                        }
                    }
                }
            }
        }
    }
    &[data-theme-direction="rtl"] {
        .table_wrapper {
            overflow-x: scroll;
            .search_wrapper {
                input {
                    border-radius: 0 4px 4px 0 !important;
                }
                button {
                    border-radius: 4px 0 0 4px !important;
                }
            }
        }
    }
}
`;
        setStore({ tablesScss: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardType]);
    return null;
};

export default TablesScss;
