import { useEffect } from "react";
import { useFileStore } from "../../../../store/useFileStore";

const DocTabsMDX = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store);

    useEffect(() => {
        let value = `---
sidebar_position: 7
sidebar_label: Tabs
---

# Tabs

The Tabs component organizes content into multiple sections and allows the user to switch between them. The Tabs component can have different orientations, such as horizontal or vertical, and different alignments, such as left, center, or right. The Tabs component can also have indicators, icons, or labels for each tab.

You can find the Tabs component in the \`src/views/Tabs.jsx\` file.

import FeatureTable from "@site/src/components/FeatureTable/FeatureTable";
import CodePreview from "@site/src/components/CodePreview/CodePreview";
import { Tab, TabPage } from "@site/src/components/Tab/Tab";
import "@site/node_modules/@fortawesome/fontawesome-free/css/all.min.css";
import Tabs from '@theme/Tabs';
import TabItem from '@theme/TabItem';

## Examples

### Top Horizontal
A top-positioned horizontal tab menu component for intuitive navigation user experience. Tabs [Preview](https://demo.reactadmin.com/bootstrap/basix-admin/components/tabs)

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <Tab activeTab="home" tabActiveBg="#292b35" tabActiveTextColor="#fff">
                <TabPage eventKey="home" title="Home">
                    <div className="w-100 h-100" style={{ padding: "20px" }}>
                        <p
                            style={{
                                color: "var(--hedging-text-color)",
                            }}>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                </TabPage>
                <TabPage eventKey="profile" title="Profile">
                    <div className="w-100 h-100" style={{ padding: "20px" }}>
                        <p
                            style={{
                                color: "var(--hedging-text-color)",
                            }}>It is a long established fact that a reader will be distracted by the readable
                            content of a page when looking at its layout. The point of using Lorem Ipsum.</p>
                    </div>
                </TabPage>
                <TabPage eventKey="messages" title="Messages">
                    <div className="w-100 h-100" style={{ padding: "20px" }}>
                        <p
                            style={{
                                color: "var(--hedging-text-color)",
                            }}>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots
                            in a piece of classical Latin literature.</p>
                    </div>
                </TabPage>
            </Tab>
        </CodePreview>
    </TabItem>
    
    <TabItem value="code" label="Code">

        \`\`\`jsx title="Tabs"
        import { Tab, TabPage } from "@/components/Tab/Tab";

        function MyComponent() {
            return (
                <Tab activeTab="home" tabActiveBg="#292b35" tabActiveTextColor="#fff">
                    <TabPage eventKey="home" title="Home">
                        <div className="w-100 h-100" style={{ padding: "20px" }}>
                            <p
                                style={{
                                    color: "var(--hedging-text-color)",
                                }}>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                tempor incididunt ut labore et dolore magna aliqua.
                            </p>
                        </div>
                    </TabPage>
                    <TabPage eventKey="profile" title="Profile">
                        <div className="w-100 h-100" style={{ padding: "20px" }}>
                            <p
                                style={{
                                    color: "var(--hedging-text-color)",
                                }}>
                                It is a long established fact that a reader will be distracted by the
                                readable content of a page when looking at its layout. The point of using
                                Lorem Ipsum.
                            </p>
                        </div>
                    </TabPage>
                    <TabPage eventKey="messages" title="Messages">
                        <div className="w-100 h-100" style={{ padding: "20px" }}>
                            <p
                                style={{
                                    color: "var(--hedging-text-color)",
                                }}>
                                Contrary to popular belief, Lorem Ipsum is not simply random text. It has
                                roots in a piece of classical Latin literature.
                            </p>
                        </div>
                    </TabPage>
                </Tab>
            );
        }

        export default MyComponent;
        \`\`\`
    </TabItem>
</Tabs>

<br />
### Bottom Horizontal
A tab component with customizable bottom positioning for user-friendly tab menu placement and navigation. Tabs [Preview](https://demo.reactadmin.com/bootstrap/basix-admin/components/tabs)

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <Tab activeTab="home" tabActiveBg="#292b35" tabActiveTextColor="#fff" tabPosition="bottom">
                <TabPage eventKey="home" title="Home" icon="fa fa-home">
                    <div className="w-100 h-100" style={{ padding: "20px" }}>
                        <p
                            style={{
                                color: "var(--hedging-text-color)",
                            }}>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud</p>
                    </div>
                </TabPage>
                <TabPage eventKey="profile" title="Profile" icon="fa-sharp fa-solid fa-user">
                    <div className="w-100 h-100" style={{ padding: "20px" }}>
                        <p
                            style={{
                                color: "var(--hedging-text-color)",
                            }}>It is a long established fact that a reader will be distracted by the readable
                            content of a page when looking at its layout. The point of using Lorem Ipsum is
                            that it has a more-or-less normal distribution</p>
                    </div>
                </TabPage>
                <TabPage eventKey="messages" title="Messages" icon="fa fa-comments">
                    <div className="w-100 h-100" style={{ padding: "20px" }}>
                        <p
                            style={{
                                color: "var(--hedging-text-color)",
                            }}>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots
                            in a piece of classical Latin literature from 45 BC, making it over 2000 years
                            old. Richard McClintock, a Latin professor</p>
                    </div>
                </TabPage>
            </Tab>
        </CodePreview>
    </TabItem>
    
    <TabItem value="code" label="Code">

        \`\`\`jsx title="Tabs"
        import { Tab, TabPage } from "@/components/Tab/Tab";

        function MyComponent() {
            return (
                <Tab activeTab="home" tabActiveBg="#292b35" tabActiveTextColor="#fff" tabPosition="bottom">
                    <TabPage eventKey="home" title="Home" icon="fa fa-home">
                        <div className="w-100 h-100" style={{ padding: "20px" }}>
                            <p
                                style={{
                                    color: "var(--hedging-text-color)",
                                }}>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                quis nostrud
                            </p>
                        </div>
                    </TabPage>
                    <TabPage eventKey="profile" title="Profile" icon="fa-sharp fa-solid fa-user">
                        <div className="w-100 h-100" style={{ padding: "20px" }}>
                            <p
                                style={{
                                    color: "var(--hedging-text-color)",
                                }}>
                                It is a long established fact that a reader will be distracted by the
                                readable content of a page when looking at its layout. The point of using
                                Lorem Ipsum is that it has a more-or-less normal distribution
                            </p>
                        </div>
                    </TabPage>
                    <TabPage eventKey="messages" title="Messages" icon="fa fa-comments">
                        <div className="w-100 h-100" style={{ padding: "20px" }}>
                            <p
                                style={{
                                    color: "var(--hedging-text-color)",
                                }}>
                                Contrary to popular belief, Lorem Ipsum is not simply random text. It has
                                roots in a piece of classical Latin literature from 45 BC, making it over
                                2000 years old. Richard McClintock, a Latin professor
                            </p>
                        </div>
                    </TabPage>
                </Tab>
            );
        }

        export default MyComponent;
        \`\`\`
    </TabItem>
</Tabs>

<br/>
### Left Vertical
A tab component with customizable left vertical positioning for user-friendly tab menu placement and navigation. Tabs [Preview](https://demo.reactadmin.com/bootstrap/basix-admin/components/tabs)

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <Tab activeTab="home" tabActiveBg="#292b35" tabActiveTextColor="#fff" tabPosition="left">
                <TabPage eventKey="home" icon="fa fa-home">
                    <div className="w-100 h-100" style={{ padding: "20px" }}>
                        <p
                            style={{
                                color: "var(--hedging-text-color)",
                            }}>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,</p>
                    </div>
                </TabPage>
                <TabPage eventKey="profile" icon="fa-sharp fa-solid fa-user">
                    <div className="w-100 h-100" style={{ padding: "20px" }}>
                        <p
                            style={{
                                color: "var(--hedging-text-color)",
                            }}>It is a long established fact that a reader will be distracted by the readable
                            content of a page when looking at its layout. The point of using Lorem Ipsum is
                            that it has a more-or-less normal distribution</p>
                    </div>
                </TabPage>
                <TabPage eventKey="messages" icon="fa fa-comments">
                    <div className="w-100 h-100" style={{ padding: "20px" }}>
                        <p
                            style={{
                                color: "var(--hedging-text-color)",
                            }}>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots
                            in a piece of classical Latin literature from 45 BC, making it over 2000 years
                            old.</p>
                    </div>
                </TabPage>
            </Tab>
        </CodePreview>
    </TabItem>
    
    <TabItem value="code" label="Code">

        \`\`\`jsx title="Tabs"
        import { Tab, TabPage } from "@/components/Tab/Tab";

        function MyComponent() {
            return (
                <Tab activeTab="home" tabActiveBg="#292b35" tabActiveTextColor="#fff" tabPosition="left">
                    <TabPage eventKey="home" icon="fa fa-home">
                        <div className="w-100 h-100" style={{ padding: "20px" }}>
                            <p
                                style={{
                                    color: "var(--hedging-text-color)",
                                }}>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
                            </p>
                        </div>
                    </TabPage>
                    <TabPage eventKey="profile" icon="fa-sharp fa-solid fa-user">
                        <div className="w-100 h-100" style={{ padding: "20px" }}>
                            <p
                                style={{
                                    color: "var(--hedging-text-color)",
                                }}>
                                It is a long established fact that a reader will be distracted by the
                                readable content of a page when looking at its layout. The point of using
                                Lorem Ipsum is that it has a more-or-less normal distribution
                            </p>
                        </div>
                    </TabPage>
                    <TabPage eventKey="messages" icon="fa fa-comments">
                        <div className="w-100 h-100" style={{ padding: "20px" }}>
                            <p
                                style={{
                                    color: "var(--hedging-text-color)",
                                }}>
                                Contrary to popular belief, Lorem Ipsum is not simply random text. It has
                                roots in a piece of classical Latin literature from 45 BC, making it over
                                2000 years old.
                            </p>
                        </div>
                    </TabPage>
                </Tab>
            );
        }

        export default MyComponent;
        \`\`\`
    </TabItem>
</Tabs>

<br/>
### Right Vertical
A tab component with customizable right vertical positioning for user-friendly tab menu placement and navigation. Tabs [Preview](https://demo.reactadmin.com/bootstrap/basix-admin/components/tabs)

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <Tab activeTab="home" tabActiveBg="#292b35" tabActiveTextColor="#fff" tabPosition="right">
                <TabPage eventKey="home" title="Home" icon="fa fa-home">
                    <div className="w-100 h-100" style={{ padding: "20px" }}>
                        <p
                            style={{
                                color: "var(--hedging-text-color)",
                            }}>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    </div>
                </TabPage>
                <TabPage eventKey="profile" title="Profile" icon="fa-sharp fa-solid fa-user">
                    <div className="w-100 h-100" style={{ padding: "20px" }}>
                        <p
                            style={{
                                color: "var(--hedging-text-color)",
                            }}>It is a long established fact that a reader will be distracted by the readable
                            content of a page when looking at its layout. The point of using Lorem Ipsum is
                            that it has a more-or-less normal distribution</p>
                    </div>
                </TabPage>
                <TabPage eventKey="messages" title="Messages" icon="fa fa-comments">
                    <div className="w-100 h-100" style={{ padding: "20px" }}>
                        <p
                            style={{
                                color: "var(--hedging-text-color)",
                            }}>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots
                            in a piece of classical Latin literature from 45 BC, making it over 2000 years
                            old. Richard McClintock, a Latin professor at Hampden-Sydney College in
                            Virginia, looked up one of the more.</p>
                    </div>
                </TabPage>
            </Tab>
        </CodePreview>
    </TabItem>
    
    <TabItem value="code" label="Code">

        \`\`\`jsx title="Tabs"
        import { Tab, TabPage } from "@/components/Tab/Tab";

        function MyComponent() {
            return (
                <Tab activeTab="home" tabActiveBg="#292b35" tabActiveTextColor="#fff" tabPosition="right">
                    <TabPage eventKey="home" title="Home" icon="fa fa-home">
                        <div className="w-100 h-100" style={{ padding: "20px" }}>
                            <p
                                style={{
                                    color: "var(--hedging-text-color)",
                                }}>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                            </p>
                        </div>
                    </TabPage>
                    <TabPage eventKey="profile" title="Profile" icon="fa-sharp fa-solid fa-user">
                        <div className="w-100 h-100" style={{ padding: "20px" }}>
                            <p
                                style={{
                                    color: "var(--hedging-text-color)",
                                }}>
                                It is a long established fact that a reader will be distracted by the
                                readable content of a page when looking at its layout. The point of using
                                Lorem Ipsum is that it has a more-or-less normal distribution
                            </p>
                        </div>
                    </TabPage>
                    <TabPage eventKey="messages" title="Messages" icon="fa fa-comments">
                        <div className="w-100 h-100" style={{ padding: "20px" }}>
                            <p
                                style={{
                                    color: "var(--hedging-text-color)",
                                }}>
                                Contrary to popular belief, Lorem Ipsum is not simply random text. It has
                                roots in a piece of classical Latin literature from 45 BC, making it over
                                2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney
                                College in Virginia, looked up one of the more.
                            </p>
                        </div>
                    </TabPage>
                </Tab>
            );
        }

        export default MyComponent;
        \`\`\`
    </TabItem>
</Tabs>

## Props

### Tab

<FeatureTable>
    <tr>
        <td>activeTab</td>
        <td>string</td>
        <td></td>
        <td>A string that specifies the key of the \`activeTab\`. You can use this prop to control which tab is selected by default or by the user. For example, \`activeTab="home"\`.</td>
    </tr>
    <tr>
        <td>tabPosition</td>
        <td>\`top\`, \`bottom\`, \`left\`, \`right\`</td>
        <td>\`top\`</td>
        <td>A string that specifies the position of the tabs relative to the content. You can choose from four options: \`top\`, \`bottom\`, \`left\`, or \`right\`. The default value is \`top\`.</td>
    </tr>
    <tr>
        <td>iconPosition</td>
        <td>\`left\`, \`right\`</td>
        <td>\`left\`</td>
        <td>A string that specifies the position of the icons relative to the labels. You can choose from two options: \`left\` or \`right\`. The default value is \`left\`.</td>
    </tr>
    <tr>
        <td>tabActiveBg</td>
        <td>string</td>
        <td>\`#292b35\`</td>
        <td>A string that specifies the background color of the active tab. You can use any valid CSS color value as the value of \`tabActiveBg\`. The default value is \`tabActiveBg="#292b35"\`.</td>
    </tr>
    <tr>
        <td>tabActiveTextColor</td>
        <td>string</td>
        <td>\`#fff\`</td>
        <td>A string that specifies the text color of the active tab. You can use any valid CSS color value as the value of \`tabActiveTextColor\`. The default value is \`tabActiveTextColor="#fff"\`.</td>
    </tr>

</FeatureTable>

### TabPage

<FeatureTable>
    <tr>
        <td>eventKey</td>
        <td>string</td>
        <td></td>
        <td>A string that specifies the key of the tab page. You can use this prop to identify and control which tab page is active or selected. The value of \`eventKey\` should be unique among the tab pages. For example, \`eventKey="home"\`.</td>
    </tr>
    <tr>
        <td>title</td>
        <td>string</td>
        <td></td>
        <td>A string that specifies the \`title \`of the tab page. You can use this prop to give a brief and descriptive name to the tab page. The \`title\` will be displayed in the tab header. For example, \`title="Home"\`.</td>
    </tr>
    <tr>
        <td>icon</td>
        <td>string</td>
        <td></td>
        <td>A string that specifies the \`icon\` of the tab page. You can use this prop to add a visual cue to the tab page. You can use any \`icon\` from the Font \`Awesome icons\` library. The \`icon\` will be displayed in the tab header. For example, \`icon="fa-user"\`.</td>
    </tr>

</FeatureTable>

If you like use to bootsrtap tab please visit [Tabs](https://react-bootstrap.netlify.app/docs/components/tabs)
`;

        setStore({ docTabsMDX: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default DocTabsMDX;
