import { useEffect } from "react";
import { useFileStore } from "../../../../store/useFileStore";

const DocTableMDX = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store);

    useEffect(() => {
        let value = `---
sidebar_position: 11
sidebar_label: Table
---

# Table

The Table component is a grid of data that can be filtered, searched. The Table component can have different parts, such as a header, a body, and a footer. The Table component can also have different features, such as checkboxes.

You can find the Tables in the \`src/views/Tables.jsx\` file.

import FeatureTable from "@site/src/components/FeatureTable/FeatureTable";
import CodePreview from "@site/src/components/CodePreview/CodePreview";
import BasicTable from "@site/src/components/Table/BasicTable";
import VariantsTable from "@site/src/components/Table/VariantsTable";
import SearchTable from "@site/src/components/Table/SearchTable";
import Tabs from '@theme/Tabs';
import TabItem from '@theme/TabItem';


## Examples

### Basic Table
Create a simple responsive table with bootstrap classes, html & custom modular scss. Table [Preview](https://demo.reactadmin.com/bootstrap/basix-admin/components/tables)

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <BasicTable />
        </CodePreview>
    </TabItem>
    
    <TabItem value="code" label="Code">
        \`\`\`jsx title="Table"
            import React from "react"
            import styles from "@/assets/scss/Tables.module.scss";

            const data = [
            {
                id: 1,
                name: "Matthew McCormick",
                email: "matthew30@mail.ol",
                city: "Vancouver",
                score: 93,
            },
            {
                id: 2,
                name: "Nancy Bo",
                email: "nancy@boonweb.com",
                city: "Washington",
                score: 280,
            },
            ...
        ]

        const MyComponent = () => {
            return (
                <div className={\`table-responsive \${styles.table_wrapper}\`}>
                    <table className={\`table \${styles.table}\`}>
                        <thead className={\`text-primary thead \${styles.thead}\`}>
                            <tr>
                                <td>Name</td>
                                <td>Email</td>
                                <td>City</td>
                                <td className="text-center">Score</td>
                            </tr>
                        </thead>
                        <tbody className={\`tbody \${styles.tbody}\`}>
                            {data.map((item, index) => (
                                    <tr
                                        key={index}
                                    >
                                        <td>{item.name}</td>
                                        <td>{item.email}</td>
                                        <td>{item.city}</td>
                                        <td className="text-center">{item.score}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            )
        }

        export default MyComponent;
        \`\`\`
    </TabItem>
</Tabs>

<br/>
### Variant Table
Colorize table component maintains object data while providing visual differentiation through color, enhancing data interpretation and user experience. Table [Preview](https://demo.reactadmin.com/bootstrap/basix-admin/components/tables)

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <VariantsTable />
        </CodePreview>
    </TabItem>
    
    <TabItem value="code" label="Code">
        \`\`\`jsx title="Table"
            import React from "react"
            import styles from "@/assets/scss/Tables.module.scss";

            const data = [
            {
                id: 1,
                name: "Matthew McCormick",
                email: "matthew30@mail.ol",
                city: "Vancouver",
                score: 93,
            },
            {
                id: 2,
                name: "Nancy Bo",
                email: "nancy@boonweb.com",
                city: "Washington",
                score: 280,
            },
            ...
        ]

        const MyComponent = () => {
            return (
                <div className={\`table-responsive  \${styles.table_wrapper}\`}>
                    <table className={\`table \${styles.table}\`}>
                        <thead className={\`text-primary thead \${styles.thead}\`}>
                            <tr>
                                <td>Name</td>
                                <td>Email</td>
                                <td>City</td>
                                <td className="text-center">Score</td>
                                <td>Status</td>
                            </tr>
                        </thead>
                        <tbody className={\`tbody \${styles.tbody}\`}>
                            {data.map((item, index) => (
                                    <tr
                                        key={index}
                                        className={item.tableBg}
                                    >
                                        <td>{item.name}</td>
                                        <td>{item.email}</td>
                                        <td>{item.city}</td>
                                        <td className="text-center">{item.score}</td>
                                        <td>
                                            <i className={\`\${item?.statusIcon} text-\${item?.badge?.status}\`}/>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            )
        }

        export default MyComponent;
        \`\`\`
    </TabItem>
</Tabs>

<br/>
### Search & Pagination
Search and Pagination dynamically updates table content, ensuring efficient data retrieval and navigation for enhanced user interaction. Table [Preview](https://demo.reactadmin.com/bootstrap/basix-admin/components/tables)

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <SearchTable />
        </CodePreview>
    </TabItem>
    
    <TabItem value="code" label="Code">
        \`\`\`jsx title="Table"
            import React, { useState } from "react"
            import styles from "@/assets/scss/Tables.module.scss";

            const data = [
            {
                id: 1,
                name: "Matthew McCormick",
                email: "matthew30@mail.ol",
                city: "Vancouver",
                score: 93,
            },
            {
                id: 2,
                name: "Nancy Bo",
                email: "nancy@boonweb.com",
                city: "Washington",
                score: 280,
            },
            ...
        ]

        const MyComponent = () => {
            const [search, setSearch] = useState("");
            const [select, setSelect] = useState(10);

            return (
                <div className={\`table-responsive \${styles.table_wrapper}\`}>
                    <div className="d-flex justify-content-between align-items-center mt-2 mb-4">
                        <div
                            className={\`d-flex align-items-center \${styles.search_wrapper}\`}
                        >
                            <input
                                className="form-control"
                                type="search"
                                placeholder="Search"
                                aria-label="Search"
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            <button className="btn" type="submit">
                                <i className="fa fa fa-search"></i>
                            </button>
                        </div>
                        <div className={styles.show_entries}>
                            <select
                                value={select}
                                className="btn btn-primary"
                                onChange={(e) => setSelect(e.target.value)}
                            >
                                <option value={10}> Show 10 entries</option>
                                <option value={25}> Show 25 entries</option>
                                <option value={50}> Show 50 entries</option>
                                <option value={100}> Show 100 entries</option>
                            </select>
                        </div>
                    </div>

                    <table className={\`table \${styles.table}\`}>
                        <thead className={\`text-primary thead \${styles.thead}\`}>
                            <tr>
                                <td>Name</td>
                                <td>Email</td>
                                <td>City</td>
                                <td className="text-center">Score</td>
                            </tr>
                        </thead>
                        <tbody className={\`tbody \${styles.tbody}\`}>
                            {tableData
                                ?.slice(0, select)
                                .filter((item) => {
                                    return search === ""
                                        ? item
                                        : item.name.includes(search) ||
                                            item.email.includes(search) ||
                                            item.city.includes(search);
                                })
                                .map((item, index) => (
                                    <tr
                                        key={index}
                                    >
                                        <td>{item.name}</td>
                                        <td>{item.email}</td>
                                        <td>{item.city}</td>
                                        <td className="text-center">{item.score}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            )
        }

        export default MyComponent;
        \`\`\`
    </TabItem>
</Tabs>


For more information of table please visit to <a href="https://react-bootstrap.netlify.app/docs/components/table" target="_blank">react-bootstrap</a>
`;

        setStore({ docTableMDX: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default DocTableMDX;
