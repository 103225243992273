export function getKeywords(str = "") {
    const strArr = str.trim().split(",");
    const result = [];
    strArr.forEach((item) => {
        if (item) {
            result.push(`"${item}"`);
        }
    });

    return result.join(",");
}
