import { useEffect } from "react";
import { useFileStore } from "../../../../../store/useFileStore";

const DocSocialButton = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store);

    useEffect(() => {
        let value = `import React from "react";
import styled from "styled-components";
import styles from "./SocialButton.module.scss";

function SocialButton({
    href = "#",
    icon = "fa-brands fa-facebook-f",
    bgColor = "#3b5998",
    hoverBgColor = "",
    color = "#fff",
    hoverColor = "",
    effect = "",
    type = "square-rounded",
    border = "",
    hoverBorder = "",
}) {
    return (
        <Link
            href={href}
            $bgColor={bgColor}
            $hoverBgColor={hoverBgColor}
            $color={color}
            $hoverColor={hoverColor}
            $radius={type}
            className={\`\${styles.social_button} \${styles[type]} \${styles[effect]}\`}
            $border={border}
            $effect={effect}
            $hoverBorder={hoverBorder}
        >
            <i className={icon} />
        </Link>
    );
}

export default SocialButton;

const Link = styled.a.attrs((props) => ({
    href: props.href,
    target: "_blank",
}))\`
    background: \${(props) => props.$bgColor};
    \${(props) => \`\${props.$border ? \`border: \${props.$border}\` : ""}\`};
    > i {
        color: \${(props) => props.$color};
    }
    &:hover {
        \${(props) =>
            props.$effect != "bolb"
                ? \`background: \${props.$hoverBgColor}\`
                : ""};
        \${(props) =>
            \`\${props.$hoverBorder ? \`border: \${props.$hoverBorder}\` : ""}\`};
        > i {
            color: \${(props) => props.$hoverColor};
        }
    }
    &::after {
        \${(props) =>
            props.$effect == "bolb"
                ? \`background: \${props.$hoverBgColor}\`
                : ""};
    }
\`;
`;

        setStore({ docSocialButtonJSX: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default DocSocialButton;
