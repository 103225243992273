import { useEffect } from "react";
import { useFileStore } from "../../../../store/useFileStore";

const DocSocialsMDX = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store);

    useEffect(() => {
        let value = `---
sidebar_position: 6
sidebar_label: Socials
---

# Socials

The Socials component is a collection of buttons or icons that link to social media platforms or accounts. The Socials component can use the Font Awesome icons library to display the logos of the social media platforms. The Socials component can also have different sizes, colors, or styles.

You can find the SocialButtons component in the \`src/views/SocialButtons.jsx\` file.

import FeatureTable from "@site/src/components/FeatureTable/FeatureTable";
import CodePreview from "@site/src/components/CodePreview/CodePreview";
import SocialButton from "@site/src/components/SocialButton/SocialButton";
import "@site/node_modules/@fortawesome/fontawesome-free/css/all.min.css";
import Tabs from "@theme/Tabs";
import TabItem from "@theme/TabItem";

## Examples
A customizable social button featuring animated effects in various styles for engaging user interactions. Social Button [Preview](https://demo.reactadmin.com/bootstrap/basix-admin/components/socials)

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <div className="d-flex gap-2">
                <SocialButton
                    icon="fa fa-envelope"
                    effect="bolb"
                    color="#0072c6"
                    bgColor="#ddd"
                    hoverBgColor="#0072c6"
                    type="rounded"
                />
                <SocialButton
                    icon="fa-brands fa-facebook-f"
                    effect="bolb"
                    color="#3b5998"
                    bgColor="#ddd"
                    hoverBgColor="#3b5998"
                    type="rounded"
                />
                <SocialButton
                    icon="fa-brands fa-linkedin-in"
                    effect="bolb"
                    color="#0077b5"
                    bgColor="#ddd"
                    hoverBgColor="#0077b5"
                    type="rounded"
                />
                <SocialButton
                    icon="fa-brands fa-github"
                    effect="bolb"
                    color="#6e5494"
                    bgColor="#ddd"
                    hoverBgColor="#6e5494"
                    type="rounded"
                />
                <SocialButton
                    icon="fa-brands fa-codepen"
                    effect="bolb"
                    color="#212121"
                    bgColor="#ddd"
                    hoverBgColor="#212121"
                    type="rounded"
                />
            </div>

        </CodePreview>
    </TabItem>

    <TabItem value="code" label="Code">
        \`\`\`jsx title="Social Buttons"
        import SocialButton from "@/components/SocialButton/SocialButton";

        function MyComponent() {
            return (
                <>
                    <SocialButton
                        icon="fa fa-envelope"
                        effect="bolb"
                        color="#0072c6"
                        bgColor="#ddd"
                        hoverBgColor="#0072c6"
                        type="rounded"
                    />
                    <SocialButton
                        icon="fa-brands fa-facebook-f"
                        effect="bolb"
                        color="#3b5998"
                        bgColor="#ddd"
                        hoverBgColor="#3b5998"
                        type="rounded"
                    />
                    <SocialButton
                        icon="fa-brands fa-linkedin-in"
                        effect="bolb"
                        color="#0077b5"
                        bgColor="#ddd"
                        hoverBgColor="#0077b5"
                        type="rounded"
                    />
                    <SocialButton
                        icon="fa-brands fa-github"
                        effect="bolb"
                        color="#6e5494"
                        bgColor="#ddd"
                        hoverBgColor="#6e5494"
                        type="rounded"
                    />
                    <SocialButton
                        icon="fa-brands fa-codepen"
                        effect="bolb"
                        color="#212121"
                        bgColor="#ddd"
                        hoverBgColor="#212121"
                        type="rounded"
                    />
                </>
            );
        }

        export default MyComponent;
        \`\`\`
    </TabItem>

</Tabs>

<br/>
### Square
A square-shaped social button with captivating hover effects for interactive user experiences. Social Button [Preview](https://demo.reactadmin.com/bootstrap/basix-admin/components/socials)

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <div className="d-flex gap-2">
                <SocialButton
                    icon="fa-brands fa-facebook-f"
                    color="#837e78"
                    hoverColor="#212121"
                    bgColor="none"
                    type="square"
                    border="1px solid #837e78"
                    hoverBorder="1px solid #212121"
                />
                <SocialButton
                    icon="fa-brands fa-twitter"
                    color="#837e78"
                    hoverColor="#212121"
                    bgColor="none"
                    type="square"
                    border="1px solid #837e78"
                    hoverBorder="1px solid #212121"
                />
                <SocialButton
                    icon="fa-brands fa-instagram"
                    color="#837e78"
                    hoverColor="#212121"
                    bgColor="none"
                    type="square"
                    border="1px solid #837e78"
                    hoverBorder="1px solid #212121"
                />
                <SocialButton
                    icon="fa-brands fa-vimeo-v"
                    color="#837e78"
                    hoverColor="#212121"
                    bgColor="none"
                    type="square"
                    border="1px solid #837e78"
                    hoverBorder="1px solid #212121"
                />
                <SocialButton
                    icon="fa-brands fa-dribbble"
                    color="#837e78"
                    hoverColor="#212121"
                    bgColor="none"
                    type="square"
                    border="1px solid #837e78"
                    hoverBorder="1px solid #212121"
                />
                <SocialButton
                    icon="fa-brands fa-github"
                    color="#837e78"
                    hoverColor="#212121"
                    bgColor="none"
                    type="square"
                    border="1px solid #837e78"
                    hoverBorder="1px solid #212121"
                />
                <SocialButton
                    icon="fa-brands fa-pied-piper"
                    color="#837e78"
                    hoverColor="#212121"
                    bgColor="none"
                    type="square"
                    border="1px solid #837e78"
                    hoverBorder="1px solid #212121"
                />
            </div>
        </CodePreview>
    </TabItem>
    
    <TabItem value="code" label="Code">
        \`\`\`jsx title="Social Buttons"
        import SocialButton from "@/components/SocialButton/SocialButton";

        function MyComponent() {
            return (
                <>
                    <SocialButton
                        icon="fa-brands fa-facebook-f"
                        color="#837e78"
                        hoverColor="#212121"
                        bgColor="none"
                        type="square"
                        border="1px solid #837e78"
                        hoverBorder="1px solid #212121"
                    />
                    <SocialButton
                        icon="fa-brands fa-twitter"
                        color="#837e78"
                        hoverColor="#212121"
                        bgColor="none"
                        type="square"
                        border="1px solid #837e78"
                        hoverBorder="1px solid #212121"
                    />
                    <SocialButton
                        icon="fa-brands fa-instagram"
                        color="#837e78"
                        hoverColor="#212121"
                        bgColor="none"
                        type="square"
                        border="1px solid #837e78"
                        hoverBorder="1px solid #212121"
                    />
                    <SocialButton
                        icon="fa-brands fa-vimeo-v"
                        color="#837e78"
                        hoverColor="#212121"
                        bgColor="none"
                        type="square"
                        border="1px solid #837e78"
                        hoverBorder="1px solid #212121"
                    />
                    <SocialButton
                        icon="fa-brands fa-dribbble"
                        color="#837e78"
                        hoverColor="#212121"
                        bgColor="none"
                        type="square"
                        border="1px solid #837e78"
                        hoverBorder="1px solid #212121"
                    />
                    <SocialButton
                        icon="fa-brands fa-github"
                        color="#837e78"
                        hoverColor="#212121"
                        bgColor="none"
                        type="square"
                        border="1px solid #837e78"
                        hoverBorder="1px solid #212121"
                    />
                    <SocialButton
                        icon="fa-brands fa-pied-piper"
                        color="#837e78"
                        hoverColor="#212121"
                        bgColor="none"
                        type="square"
                        border="1px solid #837e78"
                        hoverBorder="1px solid #212121"
                    />
                </>
            );
        }

        export default MyComponent;
        \`\`\`
    </TabItem>

</Tabs>

<br/>
### Effect
A configurable social button component with animated effects and customizable properties for enhanced user engagement. Social Button [Preview](https://demo.reactadmin.com/bootstrap/basix-admin/components/socials)

<Tabs>
    <TabItem value="preview" label="Preview">
        <CodePreview>
            <div className="d-flex gap-2">
                <SocialButton
                    icon="fa-brands fa-facebook-f"
                    color="#fff"
                    effect="aeneas"
                    bgColor="#3b5998"
                    type="square-rounded"
                />
                <SocialButton
                    icon="fa-brands fa-twitter"
                    color="#fff"
                    effect="jaques"
                    bgColor="#00aced"
                    type="square-rounded"
                />
                <SocialButton
                    icon="fa-brands fa-google-plus-g"
                    color="#fff"
                    effect="egeon"
                    bgColor="#dd4b39"
                    type="square-rounded"
                />
                <SocialButton
                    icon="fa-brands fa-dribbble"
                    color="#fff"
                    effect="claudio"
                    bgColor="#ea4c89"
                    type="square-rounded"
                />
                <SocialButton
                    icon="fa-brands fa-vimeo-v"
                    color="#fff"
                    effect="laertes"
                    bgColor="#1ab7ea"
                    type="square-rounded"
                />
                <SocialButton
                    icon="fa-brands fa-pinterest-p"
                    color="#fff"
                    effect="jinae"
                    bgColor="#cb2027"
                    type="square-rounded"
                />
            </div>
        </CodePreview>
    </TabItem>
    
    <TabItem value="code" label="Code">
        \`\`\`jsx title="Social Buttons with Effect"
        import SocialButton from "@/components/SocialButton/SocialButton";

        function MyComponent() {
            return (
                <>
                    <SocialButton
                        icon="fa-brands fa-facebook-f"
                        color="#fff"
                        effect="aeneas"
                        bgColor="#3b5998"
                        type="square-rounded"
                    />
                    <SocialButton
                        icon="fa-brands fa-twitter"
                        color="#fff"
                        effect="jaques"
                        bgColor="#00aced"
                        type="square-rounded"
                    />
                    <SocialButton
                        icon="fa-brands fa-google-plus-g"
                        color="#fff"
                        effect="egeon"
                        bgColor="#dd4b39"
                        type="square-rounded"
                    />
                    <SocialButton
                        icon="fa-brands fa-dribbble"
                        color="#fff"
                        effect="claudio"
                        bgColor="#ea4c89"
                        type="square-rounded"
                    />
                    <SocialButton
                        icon="fa-brands fa-vimeo-v"
                        color="#fff"
                        effect="laertes"
                        bgColor="#1ab7ea"
                        type="square-rounded"
                    />
                    <SocialButton
                        icon="fa-brands fa-pinterest-p"
                        color="#fff"
                        effect="jinae"
                        bgColor="#cb2027"
                        type="square-rounded"
                    />
                </>
            );
        }

        export default MyComponent;
        \`\`\`
    </TabItem>

</Tabs>

## Props

<FeatureTable>
    <tr>
        <td>href</td>
        <td>string</td>
        <td>\`#\`</td>
        <td>
            A string that specifies the \`URL\` of the social media platform or
            the website. You can use any valid \`URL\` as the value of \`href\`. The
            default value is \`#\`.
        </td>
    </tr>
    <tr>
        <td>icon</td>
        <td>string</td>
        <td>\`fa-brands fa-facebook-f\`</td>
        <td>
            A string that specifies the \`icon\` of the social media platform or
            the website. You can use any \`icon\` from the Font \`Awesome icons\`
            library. The default value is \`fa-brands fa-facebook-f\`.
        </td>
    </tr>
    <tr>
        <td>bgColor</td>
        <td>string</td>
        <td>\`#3b5998\`</td>
        <td>
            A string that specifies the background color of the icon. You can
            use any valid CSS color value as the value of \`bgColor\`. The default
            value is \`#3b5998\`.
        </td>
    </tr>
    <tr>
        <td>hoverBgColor</td>
        <td>string</td>
        <td></td>
        <td>
            A string that specifies the background color of the icon when the
            \`mouse hovers over\` it. You can use any valid CSS color value as the
            value of \`hoverBgColor\`.{" "}
        </td>
    </tr>
    <tr>
        <td>color</td>
        <td>string</td>
        <td>\`#fff\`</td>
        <td>
            {" "}
            A string that specifies the \`color\` of the icon. You can use any valid
            CSS \`color\` value as the value of \`color\`. The default value is \`#fff\`.
        </td>
    </tr>
    <tr>
        <td>hoverColor</td>
        <td>string</td>
        <td></td>
        <td>A string that specifies the color of the icon when the \`mouse hovers over\` it. You can use any valid CSS color value as the value of \`hoverColor\`.</td>
    </tr>
    <tr>
        <td>effect</td>
        <td>
            \`aeneas\`, \`jaques\`, \`egeon\`, \`claudio\`, \`laertes\`, \`jinae\`, \`bolb\`
        </td>
        <td></td>
        <td>
            A string that specifies the effect of the icon. You can choose from
            seven options: \`aeneas\`, \`jaques\`, \`egeon\`, \`claudio\`, \`laertes\`,
            \`jinae\`, or \`bolb\`.
        </td>
    </tr>
    <tr>
        <td>type</td>
        <td>\`rounded\`, \`square-rounded\`, \`square\`</td>
        <td>\`square-rounded\`</td>
        <td>
            A string that specifies the shape of the icon. You can choose from
            three options: \`rounded\`, \`square-rounded\`, or \`square\`. The default
            value is \`square-rounded\`.
        </td>
    </tr>
    <tr>
        <td>border</td>
        <td>string</td>
        <td></td>
        <td>
            A string that specifies the \`border\` of the icon. You can use any
            valid CSS \`border\` value as the value of \`border\` \`1px solid
            #dddddd\`.
        </td>
    </tr>
    <tr>
        <td>hoverBorder</td>
        <td>string</td>
        <td></td>
        <td>
            A string that specifies the border of the icon when the \`mouse
            hovers over\` it. You can use any valid CSS border value as the value
            of \`hoverBorder\`.
        </td>
    </tr>
</FeatureTable>
`;

        setStore({ docSocialsMDX: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default DocSocialsMDX;
