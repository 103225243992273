import { useEffect } from "react";

import { useFileStore } from "../../../../store/useFileStore";

const DarkModeSwitchScss = () => {
    const [_, setStore] = useFileStore((store) => store.darkModeSwitchScss);

    useEffect(() => {
        let value = `@import "../scss/variable.scss";

.dark_mode_switch {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    opacity: 0.9;
    background-color: #0a0a0a;
    border: 1px solid var(--border-color);
    svg {
        width: 14px;
        height: 14px;
        path {
            fill: $white;
        }
    }
    &[data="true"] {
        background-color: $white;
        border: 1px solid var(--border-color);
        svg {
            path {
                fill: #0a0a0a;
            }
        }
    }
}
`;
        setStore({ darkModeSwitchScss: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return null;
};

export default DarkModeSwitchScss;
