import { useEffect } from "react";
import { useFileStore } from "../../../../store/useFileStore";

const MonthlyStatsScss = () => {
    const [_, setStore] = useFileStore((store) => store.monthlyStatsScss);
    useEffect(() => {
        let value = `.monthly_stats {
    .card_body {
        background-color: #5c6bc0;
    }
}
body[data-theme="dark"] {
    .monthly_stats {
        .card_body {
            background-color: var(--bg-content);
        }
    }
}
`;
        setStore({ monthlyStatsScss: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return null;
};

export default MonthlyStatsScss;
