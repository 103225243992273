const docsFiles = {
    //docs files
    docGitignore: "",
    docBabelConfig: "",
    docDocusaurusConfig: "",
    docPackageJson: "",
    docReadmeMd: "",
    docSidebars: "",
    // data
    docData: "",
    docTableData: "",
    // docs
    docIndexMd: "",
    docInstallationMDX: "",
    docConfigurationMDX: "",
    // docs > charts
    docChartsCategoryJson: "",
    docApexChartsMDX: "",
    docChartjsMDX: "",
    docRechartsMDX: "",
    // docs > components
    docComponentsCategoryJson: "",
    docAlertMDX: "",
    docBadgeMDX: "",
    docButtonMDX: "",
    docCardMDX: "",
    docModalMDX: "",
    docProgressMDX: "",
    docSocialsMDX: "",
    docTableMDX: "",
    docTabsMDX: "",
    docTypographyMDX: "",
    docWidgetsMDX: "",
    // docs > forms
    docFormsCategoryJson: "",
    docAdvancedFormMDX: "",
    docBasicFormMDX: "",
    docFormWizardMDX: "",
    // docs > maps
    docPapsCategoryJson: "",
    docGoogleMapMDX: "",
    docLeafletMapsMDX: "",
    // docs > utils
    docWaitForElm: "",
    // docs > static
    docNojekyll: "",
    docNotice: "",
    // docs > src > css
    docCustomCSS: "",
    docVariableSCSS: "",
    // docs > src > components > Card
    docCardJSX: "",
    docCardModuleSCSS: "",
    // docs > src > components > Charts > ApexPaiChart
    docApexPaiChartJSX: "",
    // docs > src > components > Charts > AreaChart
    docAreaChartJSX: "",
    // docs > src > components > Charts > AreaRechart
    docAreaRechartJSX: "",
    // docs > src > components > Charts > BarChart
    docBarChartJSX: "",
    // docs > src > components > Charts > BarRechart
    docBarRechartJSX: "",
    // docs > src > components > Charts > CandlestickChart
    docCandlestickChartJSX: "",
    // docs > src > components > Charts > ColumnChart
    docColumnChartJSX: "",
    // docs > src > components > Charts > LineChart
    docLineChartJSX: "",
    // docs > src > components > Charts > LineRechart
    docLineRechartJSX: "",
    // docs > src > components > Charts > PaiChart
    docPaiChartJSX: "",
    // docs > src > components > Charts > ScatterRechart
    docScatterRechartJSX: "",
    // docs > src > components > CodePreview
    docCodePreviewJSX: "",
    // docs > src > components > FeatureTable
    docFeatureTableJSX: "",
    // docs > src > components > Maps > Google
    docGoogleJSX: "",
    // docs > src > components > Maps > Leaflet
    docLeafletJSX: "",
    // docs > src > components > Modal
    docLargeModalJSX: "",
    docMediumModalJSX: "",
    docSmallModalJSX: "",
    docWithoutAnimatedModalJSX: "",
    // docs > src > components > Notice
    docAppJSX: "",
    // docs > src > components > Notice > FlipClock
    docFlipClockJSX: "",
    docFlipClockCSS: "",
    // docs > src > components > Notice > hooks
    useReactCounterDownJS: "",
    // docs > src > components > Notice > OfferNotice
    docOfferNoticeJSX: "",
    docOfferNoticeCSS: "",
    // docs > src > components > ProgressBar
    docProgressBarJSX: "",
    docProgressBarSCSS: "",

    // docs > src > components > SocialButton
    docSocialButtonJSX: "",
    docSocialButtonSCSS: "",
    // docs > src > components > Tab
    docTabJSX: "",
    docTabSCSS: "",
    // docs > src > components > Table
    docBasicTableJSX: "",
    docSearchTableJSX: "",
    docVariantsTableJSX: "",
    docTableSCSS: "",
    // docs > src > components > Widgets > SocialCounter
    docSocialCounterJSX: "",
    docSocialCounterSCSS: "",
    // docs > src > components > Widgets > StasCard
    docStatsCardJSX: "",
    docStatsCardSCSS: "",
    // docs > src > components > Widgets > UserStats
    docUserStatsJSX: "",
    // docs > src > components > WizardForm
    docStepCircleWizardJSX: "",
    docStepCircleWizardSCSS: "",
    docStepSquarWizardJSX: "",
    docStepSquarWizardSCSS: "",
    docStepTabWizardJSX: "",
    docStepTabWizardSCSS: "",
    // docs > src > components > WizardForm > Page
    docAdditionalInfoJSX: "",
    docLoggedInJSX: "",
    docPersonalDetails: "",
};

export const files = {
    // root files
    indexHtml: "",
    packageJson: "",
    gitignore: "",
    readmemd: "",
    viteConfig: "",

    // Docs Files
    ...docsFiles,
    // src
    app: "",
    rootIndex: "",
    indexScss: "",
    nav: "",
    routesCode: "",
    dashboardDataContext: "",
    layouts: "",

    // views
    advancedForm: "",
    alerts: "",
    apexChartsCode: "",
    badges: "",
    basicForm: "",
    buttons: "",
    cards: "",
    chartjsCode: "",
    dashboardCode: "",
    googleMaps: "",
    grids: "",
    iconsCode: "",
    leafletMaps: "",
    login: "",
    modalsCode: "",
    progressBars: "",
    rechartsCode: "",
    register: "",
    socialButtons: "",
    tables: "",
    tabs: "",
    typography: "",
    widgets: "",
    wizardForm: "",
    page404: "",
    page500: "",

    //data
    brandico: "",
    entypo: "",
    fontAwesome: "",
    fontelico: "",
    tableData: "",

    // scss
    comboScss: "",
    dualNavScss: "",
    horizontalScss: "",
    verticalScss: "",
    analyticsStatsScss: "",
    authentication: "",
    cardScss: "",
    darkModeSwitchScss: "",
    deviceVisitorStatsScss: "",
    earningScss: "",
    gridsScss: "",
    iconStyleScss: "",
    layoutsScss: "",
    messageScss: "",
    monthlyStatsScss: "",
    navbarBgWrapperScss: "",
    notificationScss: "",
    notificationStatsCartScss: "",
    overlayCardScss: "",
    profileCardScss: "",
    searchBarScss: "",
    sidebarScss: "",
    sidebarBgWrapperScss: "",
    socialButtonScss: "",
    socialCounterScss: "",
    socialStatsScss: "",
    statsScss: "",
    statsCardScss: "",
    stepCircleWizardScss: "",
    stepSquarWizardScss: "",
    stepTabWizardScss: "",
    tablesScss: "",
    tabsScss: "",
    timelineScss: "",
    trafficScss: "",
    typographyScss: "",
    uesrProfileScss: "",
    variableScss: "",
    weatherStatsScss: "",

    // components
    analyticsStats: "",
    card: "",
    darkModeSwitch: "",
    deviceVisitorStats: "",
    download: "",
    dropdownMenuCode: "",
    earning: "",
    brandicoCode: "",
    copyButton: "",
    entypoCode: "",
    fontAwesomeCode: "",
    fontelicoCode: "",
    iconviewer: "",
    darkMatter: "",
    streetMap: "",
    voyager: "",
    worldImagery: "",
    monthlyStats: "",
    navBarBgWrapper: "",
    Navbars: "",
    combo: "",
    dualTopNav: "",
    horizontalNav: "",
    verticalNav: "",
    notificationCode: "",
    userProfile: "",
    notificationStatsCart: "",
    overlayCard: "",
    profileCard: "",
    realTime: "",
    revenue: "",
    searchBar: "",
    sidebar: "",
    sidebarBgWrapper: "",
    sidebarMenu: "",
    socialButton: "",
    socialCounter: "",
    socialStats: "",
    day: "",
    month: "",
    statsCodeBase: "",
    year: "",
    statsCard: "",
    tab: "",
    timeline: "",
    traffic: "",
    userStats: "",
    weatherStats: "",

    // step Wizards
    additionalInfo: "",
    loggedIn: "",
    personalDetails: "",
    stepCircleWizard: "",
    stepSquarWizard: "",
    stepTabWizard: "",

    // switcher
    colorControl: "",
    colorControlScss: "",
    flipClock: "",
    flipClockScss: "",
    useReactCounterDown: "",
    navigationType: "",
    navigationTypeScss: "",
    offerNotice: "",
    offerNoticeScss: "",
    sidebarBgControl: "",
    sidebarBgControlScss: "",
    switchControl: "",
    switcher: "",
    switcherScss: "",

    // charts
    areaChart: "",
    barChartApex: "",
    bubbleChart: "",
    candlestickChart: "",
    columnChart: "",
    pieChart: "",
    barChart: "",
    doughnutChart: "",
    lineChartCJs: "",
    pieChartCJs: "",
    polarAreaChart: "",
    radarChart: "",
    areaRechart: "",
    barRechart: "",
    lineRechart: "",
    pieRechart: "",
    rechartRadialBar: "",
    rechartScatter: "",

    //github
    funding: "",
    bootstrapProductAdminDemoYML: "",
};
