import { useEffect } from "react";
import { useFileStore } from "../../../store/useFileStore";

const Grids = () => {
    const [_, setStore] = useFileStore((store) => store.grids);

    useEffect(() => {
        let value = `import gridsStyle from "@/assets/scss/Grids.module.scss";
const Grids = () => {
    return (
        <div className={gridsStyle.grids_contener}>
            <h5 className="heading-title mb-3">Fixed Grid</h5>
            <div className="row gy-4 gx-4 mb-4">
                <div className="col">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col
                        </div>
                    </section>
                </div>
                <div className="col">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col
                        </div>
                    </section>
                </div>
                <div className="col">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col
                        </div>
                    </section>
                </div>
            </div>
            <div className="row gy-4 gx-4 mb-4">
                <div className="col">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col
                        </div>
                    </section>
                </div>
                <div className="col">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col
                        </div>
                    </section>
                </div>
                <div className="col">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col
                        </div>
                    </section>
                </div>
                <div className="col">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col
                        </div>
                    </section>
                </div>
                <div className="col">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col
                        </div>
                    </section>
                </div>
                <div className="col">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col
                        </div>
                    </section>
                </div>
            </div>
            <div className="row gy-4 gx-4 mb-4">
                <div className="col">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col
                        </div>
                    </section>
                </div>
                <div className="col">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col
                        </div>
                    </section>
                </div>
                <div className="col">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col
                        </div>
                    </section>
                </div>
                <div className="col">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col
                        </div>
                    </section>
                </div>
                <div className="col">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col
                        </div>
                    </section>
                </div>
                <div className="col">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col
                        </div>
                    </section>
                </div>
                <div className="col">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col
                        </div>
                    </section>
                </div>
                <div className="col">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col
                        </div>
                    </section>
                </div>
                <div className="col">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col
                        </div>
                    </section>
                </div>
                <div className="col">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col
                        </div>
                    </section>
                </div>
                <div className="col">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col
                        </div>
                    </section>
                </div>
                <div className="col">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col
                        </div>
                    </section>
                </div>
            </div>
            <h5 className="heading-title mb-3 mt-5">Desktop Grid</h5>
            <div className="row gy-4 gx-4 mb-4">
                <div className="col col-lg-12">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col-lg-12
                        </div>
                    </section>
                </div>
            </div>
            <div className="row gy-4 gx-4 mb-4">
                <div className="col-lg-6">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col-lg-6
                        </div>
                    </section>
                </div>
                <div className="col-lg-6 ">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col-lg-6
                        </div>
                    </section>
                </div>
            </div>
            <div className="row gy-4 gx-4 mb-4">
                <div className="col-lg-4">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col-lg-4
                        </div>
                    </section>
                </div>
                <div className="col-lg-4">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col-lg-4
                        </div>
                    </section>
                </div>
                <div className="col-lg-4">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col-lg-4
                        </div>
                    </section>
                </div>
            </div>
            <div className="row gy-4 gx-4 mb-4">
                <div className="col-lg-3">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col-lg-3
                        </div>
                    </section>
                </div>
                <div className="col-lg-3">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col-lg-3
                        </div>
                    </section>
                </div>
                <div className="col-lg-3">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col-lg-3
                        </div>
                    </section>
                </div>
                <div className="col-lg-3">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col-lg-3
                        </div>
                    </section>
                </div>
            </div>
            <div className="row gy-4 gx-4 mb-4">
                <div className="col-lg-2">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col-lg-2
                        </div>
                    </section>
                </div>
                <div className="col-lg-2">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col-lg-2
                        </div>
                    </section>
                </div>
                <div className="col-lg-2">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col-lg-2
                        </div>
                    </section>
                </div>
                <div className="col-lg-2">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col-lg-2
                        </div>
                    </section>
                </div>
                <div className="col-lg-2">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col-lg-2
                        </div>
                    </section>
                </div>
                <div className="col-lg-2">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col-lg-2
                        </div>
                    </section>
                </div>
            </div>
            <h5 className="heading-title mt-5 mb-3">
                Mobile, Tablet, and Desktop
            </h5>
            <div className="row gy-4 gx-4 mb-4">
                <div className="col-2">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col-2
                        </div>
                    </section>
                </div>
                <div className="col-2">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col-2
                        </div>
                    </section>
                </div>
                <div className="col-lg-8">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col-lg-8
                        </div>
                    </section>
                </div>
            </div>
            <div className="row gy-4 gx-4 mb-4">
                <div className="col-sm-3">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col-sm-3
                        </div>
                    </section>
                </div>
                <div className="col-sm-3">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col-sm-3
                        </div>
                    </section>
                </div>
                <div className="col-lg-6">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col-lg-6
                        </div>
                    </section>
                </div>
            </div>
            <div className="row gy-4 gx-4 mb-4">
                <div className="col-md-4">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col-md-4
                        </div>
                    </section>
                </div>
                <div className="col-md-4">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col-md-4
                        </div>
                    </section>
                </div>
                <div className="col-lg-4">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col-lg-4
                        </div>
                    </section>
                </div>
            </div>
            <div className="row gy-4 gx-4 mb-4">
                <div className="col-sm-6">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col-sm-6
                        </div>
                    </section>
                </div>
                <div className="col-sm-6">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col-sm-6
                        </div>
                    </section>
                </div>
            </div>
            <h5 className="heading-title mb-3 mt-5">Offset Grid</h5>
            <div className="row gy-4 gx-4 mb-4">
                <div className="col-md-6 col-sm-6 ms-auto">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col-md-6 .col-sm-6 .ms-auto
                        </div>
                    </section>
                </div>
            </div>
            <div className="row gy-4 gx-4 mb-4">
                <div className="col-md-6 mx-auto">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col-md-6 .mx-auto
                        </div>
                    </section>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col-md-4
                        </div>
                    </section>
                </div>
                <div className="col-md-4 ms-auto">
                    <section className={\`\${gridsStyle.card} card border-0\`}>
                        <div className={\`\${gridsStyle.card_body} card-body\`}>
                            .col-md-4 .ms-auto
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default Grids;
`;
        setStore({ grids: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return null;
};

export default Grids;
